import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react'

import {
  Grid,
  InputAdornment,
  IconButton,
  Container,
  Button,
  Box,
} from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { GridAlt } from '@styled-icons/boxicons-regular/GridAlt'
import { Menu } from '@styled-icons/boxicons-regular/Menu'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import UserCard from 'components/Users/Card'
import StatisticsSkeleton from 'components/Users/Statistics/Skeleton'
import UserTable from 'components/Users/Table'
import useDebounce from 'hooks/useDebounce'
import { ArrowLeft, ArrowRight } from 'styled-icons/bootstrap'

import { StyledTextField, StyledToggleButtonGroup } from './styles'
const UsersStatistics = lazy(() => import('components/Users/Statistics'))

const UsersList = () => {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const searchQuery = useDebounce(search, 500)
  const [view, setView] = useState('card')
  const [loading, setLoading] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [sortBy, setSortBy] = useState({ name: 'name', direction: 'asc' })
  const [hasPrevPage, hasNextPage] = useMemo(() => {
    return [page > 0, page < Math.floor(totalRows / 10)]
  }, [page, totalRows])

  useEffect(() => {
    api
      .get(
        `/users?page=${page}&sort=${sortBy.name},${sortBy.direction}${
          searchQuery ? '&search=' + searchQuery : ''
        }`
      )
      .then(({ data }) => {
        setTotalRows(Number.isNaN(data.totalRows) ? 0 : Number(data.totalRows))
        setUsers(data.users)
        setLoading(false)
      })
  }, [searchQuery, page, sortBy])

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setPage(0)
      setSearch('')
      setView(newView)
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg">
      <Suspense fallback={<StatisticsSkeleton />}>
        <UsersStatistics />
      </Suspense>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item xs="auto" sm={8} md={6} style={{ flex: 1 }}>
          <StyledTextField
            placeholder="Procura pelo nome"
            variant="filled"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={27} />
                </InputAdornment>
              ),
              endAdornment: search.trim().length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch('')} label="clear-search">
                    <CloseOutline size={27} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto" sm={4} md={6} style={{ textAlign: 'right', marginLeft: 15 }}>
          <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="change-view"
          >
            <ToggleButton value="card" aria-label="card">
              <GridAlt size={24} />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table">
              <Menu size={28} />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      {view === 'card' ? (
        <>
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            {users
              .filter(
                (usr) =>
                  search.trim().length <= 0 ||
                  usr.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((user) => (
                <UserCard key={user.id} user={user} />
              ))}
          </Grid>
          {!loading && !!users?.length && (
            <Box display="flex" gridGap={8} justifyContent="center" mt={2} mb={8}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!hasPrevPage}
                onClick={() => hasPrevPage && setPage(page - 1)}
                startIcon={<ArrowLeft size={16} />}
              >
                Página anterior
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!hasNextPage}
                onClick={() => hasNextPage && setPage(page + 1)}
                endIcon={<ArrowRight size={16} />}
              >
                Próxima página
              </Button>
            </Box>
          )}
        </>
      ) : (
        <UserTable
          users={users}
          page={page}
          setPage={setPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
          loading={loading}
          totalRows={totalRows}
        />
      )}
    </Container>
  )
}

export default UsersList
