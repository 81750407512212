import { Chip } from '@material-ui/core'
import styled from 'styled-components'

const parseStatusBg = (status, theme) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.background
    case 'paid':
      return theme.palette.info.background
    case 'scheduled':
      return theme.palette.success.background
    case 'canceled':
      return theme.palette.error.background
    default:
      return theme.palette.primary.background
  }
}

const parseStatusText = (status, theme) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.main
    case 'paid':
      return theme.palette.info.main
    case 'scheduled':
      return theme.palette.success.main
    case 'canceled':
      return theme.palette.error.main
    default:
      return theme.palette.primary.main
  }
}

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${parseStatusBg(status, theme)};
    color: ${parseStatusText(status, theme)};
  `}
`
