import { CardMedia, TextField } from '@material-ui/core'
import styled from 'styled-components'

export const UploadLabel = styled('label')`
  position: relative;
  cursor: pointer;
`

export const UploadIconBackdrop = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledCardMedia = styled(CardMedia)`
  width: 100%;
  height: 0px;
  padding-top: 35%;
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`
