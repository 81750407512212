import { Button, Divider, Grid, TextField } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Autocomplete } from '@material-ui/lab'
import styled from 'styled-components'

export const Container = styled(Grid)`
  flex-direction: row;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`

export const ReplyForm = styled(Grid)`
  padding: 15px;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 3px;
`

export const UploadButton = styled(Button)`
  height: 50px;
`

export const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => fade(theme.palette.menu.icon, 0.6)};
  margin: 20px 0;
`

export const CoverWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1.5px dashed ${({ theme }) => theme.palette.text.secondary};
`

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & .MuiAutocomplete-tag {
    background-color: ${({ theme }) => theme.palette.primary.background};
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const SubmitButton = styled(Button)`
  height: 45px;
  margin-left: auto;
  ${({ theme }) => `
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    `};
`
