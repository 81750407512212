import React, { useContext, useState } from 'react'

import { DialogContent, FormControlLabel, Grid } from '@material-ui/core'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api } from 'api'
import Dialog from 'components/shared/Dialog'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import { CoverWrapper, StyledTextField, UploadButton, StyledSliderPicker } from './styles'

const CategoryNewDialog = ({ open, setOpen }) => {
  const { actions } = useContext(StateContext)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [color, setColor] = useState({ hex: '#89AB15' })
  const [file, setFile] = useState({ file: null, preview: '' })
  const [status, setStatus] = useState(true)

  const handleClose = () => {
    setName('')
    setDescription('')
    setColor({ hex: '#89AB15' })
    setFile({ file: null, preview: '' })
    setStatus(true)
    setOpen(false)
  }

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setFile({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const handleChangeColor = (c) => {
    const newColor = { hex: c.hex, rgb: c.rgb }
    setColor(newColor)
  }

  const onSubmit = () => {
    if (name.trim().length <= 0) return
    if (!file.file) return
    const formData = new FormData()
    if (color.rgb) {
      const rgbColor = `${color.rgb.r},${color.rgb.g},${color.rgb.b}`
      formData.append('color', rgbColor)
    }
    formData.append('name', name)
    formData.append('description', description)
    formData.append('status', status)
    formData.append('image', file.file)

    api
      .post('/blog-categories', formData)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Nova Categoria"
      onSave={name.trim().length > 0 ? onSubmit : null}
    >
      <DialogContent style={{ padding: '15px 24px', textAlign: 'right' }}>
        <CoverWrapper>
          {file.preview && <img src={file.preview} alt="Capa da categoria" />}
        </CoverWrapper>
        <label htmlFor="files">
          <input
            hidden
            id="files"
            name="files"
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleFileUpload}
          />
          <UploadButton
            variant="outlined"
            color="primary"
            component="span"
            fullWidth
            style={{ marginBottom: 20 }}
            startIcon={<Upload size={25} />}
          >
            Carregar capa da categoria
          </UploadButton>
        </label>
        <StyledTextField
          fullWidth
          variant="filled"
          name="name"
          label="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 20 }}
        />
        <StyledTextField
          fullWidth
          variant="filled"
          multiline
          rows={2}
          rowsMax={4}
          name="description"
          label="Descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ margin: '20px 0 10px 0' }}
        >
          <Grid item style={{ flex: 1, marginRight: 5 }}>
            <Grid container alignItems="center">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 3,
                  marginRight: 15,
                  backgroundColor: color.hex,
                }}
              />
              <StyledSliderPicker color={color.hex} onChange={handleChangeColor} />
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                name="status"
              />
            }
            labelPlacement="start"
            label="Ativar"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

CategoryNewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

CategoryNewDialog.defaultProps = {
  open: false,
}

export default CategoryNewDialog
