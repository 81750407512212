import { CardMedia, TextField } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCardMedia = styled(CardMedia)`
  height: 0px;
  padding-top: 35%;
  margin-top: -10px;
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`
