import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { ChevronLeft } from '@styled-icons/boxicons-regular/ChevronLeft'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import PropTypes from 'prop-types'

import {
  LabelGrid,
  StyledIconButton,
  StyledToggleButtonGroup,
  TodayButton,
  TogglesGrid,
} from './styles'

const CustomToolbar = ({ onNavigate, onView, date, label, views, view }) => {
  const goBack = () => {
    let newDate
    if (view === 'month') {
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())
    } else if (view === 'week') {
      newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 1)
    }
    onNavigate('prev', newDate)
  }

  const goForward = () => {
    let newDate
    if (view === 'month') {
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
    } else if (view === 'week') {
      newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7, 1)
    }
    onNavigate('next', newDate)
  }

  const goToToday = () => {
    const now = new Date()
    if (
      now.getFullYear() === date.getFullYear() &&
      now.getMonth() === date.getMonth() &&
      now.getDate() === date.getDate()
    )
      return
    date.setDate(now.getDate())
    date.setMonth(now.getMonth())
    date.setYear(now.getFullYear())
    onNavigate('current')
  }

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      style={{ marginBottom: 20 }}
    >
      <Grid item>
        <StyledIconButton onClick={goBack}>
          <ChevronLeft size={30} />
        </StyledIconButton>
        <StyledIconButton onClick={goForward}>
          <ChevronRight size={30} />
        </StyledIconButton>
        <TodayButton size="large" onClick={goToToday} variant="outlined" color="primary">
          <Typography color="primary" variant="subtitle1">
            Hoje
          </Typography>
        </TodayButton>
      </Grid>
      <LabelGrid item>
        <Typography variant="h6" style={{ fontWeight: 500, textTransform: 'capitalize' }}>
          {label}
        </Typography>
      </LabelGrid>
      <TogglesGrid item>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={(e, newView) => onView(newView)}
        >
          <ToggleButton value="week">
            <Typography variant="subtitle2">Semana</Typography>
          </ToggleButton>
          <ToggleButton value="month">
            <Typography variant="subtitle2">Mês</Typography>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </TogglesGrid>
    </Grid>
  )
}

CustomToolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  views: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
}

export default CustomToolbar
