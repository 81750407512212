import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import {
  Chip,
  Container as MuiContainer,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Save } from '@styled-icons/boxicons-regular/Save'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api } from 'api'
import Editor from 'components/Blog/New/Editor'
import LoadingPage from 'components/shared/LoadingPage'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { FloatingBtn } from 'pages/styles'
import { StateContext } from 'state'

import {
  Container,
  CoverWrapper,
  ReplyForm,
  StyledAutocomplete,
  StyledDivider,
  StyledTextField,
  SubmitButton,
  UploadButton,
} from '../New/styles'

const validations = {
  title: {
    required: 'Campo obrigatório',
  },
  description: {
    required: 'Campo obrigatório',
    maxLength: {
      value: 255,
      message: 'Número de caracteres máximo atingido',
    },
  },
  body: {
    required: 'Campo obrigatório',
  },
}

const EditBlogPost = () => {
  const history = useHistory()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { id } = useParams()
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, formState, watch, errors, setValue } = useForm({
    mode: 'onChange',
  })
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [post, setPost] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [cover, setCover] = useState({ file: null, preview: '' })
  const publishAt = watch('publish_at')
  const description = watch('description')

  useEffect(() => {
    if (id) {
      api
        .get(`/blog-posts/${id}/info`)
        .then(({ data }) => {
          setPost(data)

          const categoriesNames = data.categories.map((cat) => cat.name)
          const keywords = data.keywords.split(',')

          setValue('title', data.title)
          setValue('description', data.description)
          setValue('facebook_post_url', data.facebook_post_url)
          setValue('image_author_name', data.image_author_name)
          setValue('image_author_url', data.image_author_url)
          setValue('body', data.body)
          setValue('publish_at', moment(data.publish_at, 'DD-MM-YYYY HH:mm:ss'))
          setStatus(data.status)
          setSelectedKeywords(keywords)
          setSelectedCategories(categoriesNames)
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
      api
        .get('/blog-categories/list')
        .then(({ data }) => {
          setCategories(data)
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    register('body', validations.body)
    register({ name: 'publish_at' })
  }, [register])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setCover({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const onSubmit = (data) => {
    if (selectedCategories.length === 0) {
      actions.notification.setNotification('warning', 'Falta escolheres categorias')
      return
    }
    if (selectedKeywords.length === 0) {
      actions.notification.setNotification('warning', 'Falta escolheres palavras-chave')
      return
    }
    if (!status && moment(data.publish_at).isBefore(moment())) {
      actions.notification.setNotification(
        'warning',
        'Falta selecionar uma data no futuro para publicar ou selecionar "Publicado"'
      )
      return
    }
    setLoading(true)

    const categoriesIds = selectedCategories.map(
      (cat) => categories.find((c) => c.name === cat)?.id
    )
    if (categoriesIds.length <= 0) {
      actions.notification.setNotification(
        'error',
        'Não foi possível encontrar as categorias escolhidas'
      )
      return
    }
    const keywords = selectedKeywords.join(',')

    const formData = new FormData()
    if (cover.file) {
      formData.append('image', cover.file)
    }
    if (data.image_author_name !== post.image_author_name) {
      formData.append('image_author_name', data.image_author_name)
    }
    if (data.image_author_url !== post.image_author_url) {
      formData.append('image_author_url', data.image_author_url)
    }
    if (status !== post.status) {
      formData.append('status', status)
    }
    if (!status) {
      formData.append('publish_at', data.publish_at)
    }
    if (keywords !== post.keywords) {
      formData.append('keywords', keywords)
    }
    formData.append('title', data.title)
    formData.append('facebook_post_url', data.facebook_post_url)
    formData.append('description', data.description)
    formData.append('body', data.body)
    formData.append('categories', categoriesIds)

    api
      .patch(`/blog-posts/${post.id}`, formData)
      .then(() => {
        setLoading(false)
        actions.notification.setNotification('success', 'Publicação editada com sucesso')
        window.location.pathname = '/blog'
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  if (!post) {
    return <LoadingPage />
  }

  return (
    <MuiContainer maxWidth="lg" style={{ marginTop: 20 }}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <IconButton onClick={() => history.goBack()} aria-label="go-back">
            <LeftArrowAlt size={30} />
          </IconButton>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 600, marginLeft: 10 }}
          >
            Editar publicação
          </Typography>
          {!formState.isSubmitting && (
            <FloatingBtn
              color="primary"
              type="submit"
              disabled={formState.isSubmitting || loading}
            >
              <Save size={30} />
            </FloatingBtn>
          )}
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={formState.isSubmitting || loading}
            endIcon={<Save size={22} />}
          >
            {loading ? <CircularProgress size={22} /> : 'Guardar'}
          </SubmitButton>
        </Grid>
        <Container container direction="column" spacing={3}>
          <Grid item xs={12} md={8}>
            <CoverWrapper>
              {cover.preview.length > 0 && (
                <img src={cover.preview} alt="Capa da publicação" />
              )}
              {cover.preview.length === 0 && post.image_url && (
                <img src={post.image_url} alt="Capa da publicação" />
              )}
            </CoverWrapper>
            <label htmlFor="files">
              <input
                hidden
                id="files"
                name="files"
                type="file"
                accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                onChange={handleFileUpload}
              />
              <UploadButton
                variant="outlined"
                color="primary"
                component="span"
                fullWidth
                style={{ marginBottom: 20 }}
                startIcon={<Upload size={25} />}
              >
                Carregar nova capa
              </UploadButton>
            </label>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.image_author_name}
                >
                  <StyledTextField
                    fullWidth
                    variant="filled"
                    name="image_author_name"
                    label="Nome do autor da capa"
                    inputRef={register(validations.image_author_name)}
                    error={!!errors.image_author_name}
                    helpertext={
                      errors.image_author_name && errors.image_author_name.message
                    }
                  />
                  {errors.image_author_name && (
                    <FormHelperText>{errors.image_author_name.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.image_author_url}
                >
                  <StyledTextField
                    fullWidth
                    variant="filled"
                    name="image_author_url"
                    label="URL para perfil do autor"
                    inputRef={register(validations.image_author_url)}
                    error={!!errors.image_author_url}
                    helpertext={
                      errors.image_author_url && errors.image_author_url.message
                    }
                  />
                  {errors.image_author_url && (
                    <FormHelperText>{errors.image_author_url.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <StyledDivider variant="middle" />
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.title}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                name="title"
                label="Título"
                inputRef={register(validations.title)}
                error={!!errors.title}
                helpertext={errors.title && errors.title.message}
              />
              {errors.title && <FormHelperText>{errors.title.message}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.description}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                multiline
                rows={2}
                rowsMax={4}
                name="description"
                label="Descrição"
                placeholder="Pequeno resumo da publicação que aparecerá na homepage do blog e nas redes sociais"
                inputRef={register(validations.description)}
                error={!!errors.description}
                helperText={
                  description &&
                  `${
                    255 - description.length >= 0
                      ? `${255 - description.length} caracteres restantes`
                      : ''
                  }`
                }
              />
              {errors.description && (
                <FormHelperText>{errors.description.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="column">
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.publish_at}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Data para publicação
                  </Typography>
                </FormLabel>
                <DateTimePicker
                  autoOk
                  hideTabs
                  okLabel=""
                  disabled={status}
                  disablePast
                  label="Data"
                  ampm={false}
                  cancelLabel=""
                  name="publish_at"
                  inputVariant="filled"
                  format="DD/MM/YYYY HH:mm"
                  error={!!errors.publish_at}
                  DialogProps={{ fullWidth: fullScreen }}
                  value={publishAt}
                  onChange={(date) => setValue('publish_at', date)}
                  helperText={errors.publish_at && errors.publish_at.message}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                      name="status"
                    />
                  }
                  labelPlacement="end"
                  label="Publicado"
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }}>
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Categorias
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  filterSelectedOptions
                  value={selectedCategories}
                  onChange={(e, values) => setSelectedCategories(values)}
                  options={categories.map((option) => option.name)}
                  noOptionsText="Sem categorias"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" name="categories" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }}>
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Palavras-Chave
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  options={[]}
                  value={selectedKeywords}
                  onChange={(e, values) => setSelectedKeywords(values)}
                  freeSolo
                  noOptionsText="Sem palavras-chave"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Insere uma palavra e carrega em Enter"
                      variant="filled"
                      name="keywords"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.facebook_post_url}
              >
                <StyledTextField
                  fullWidth
                  variant="filled"
                  name="facebook_post_url"
                  label="URL publicação no facebook"
                  inputRef={register}
                  error={!!errors.facebook_post_url}
                  helpertext={
                    errors.facebook_post_url && errors.facebook_post_url.message
                  }
                />
                {errors.facebook_post_url && (
                  <FormHelperText>{errors.facebook_post_url.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Container>
        <Grid container style={{ marginTop: 20 }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            color="textSecondary"
            style={{ fontWeight: 600 }}
          >
            Conteúdo da publicação
          </Typography>
          <ReplyForm container direction="column">
            <Editor
              name="body"
              defaultText={post.body}
              error={!!errors.body}
              setValue={(name, value) => setValue(name, value, { shouldValidate: true })}
            />
          </ReplyForm>
        </Grid>
      </form>
    </MuiContainer>
  )
}

export default EditBlogPost
