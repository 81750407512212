import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import {
  Chip,
  Container as MuiContainer,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Save } from '@styled-icons/boxicons-regular/Save'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api } from 'api'
import Editor from 'components/Blog/New/Editor'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { FloatingBtn } from 'pages/styles'
import { StateContext } from 'state'

import {
  Container,
  ReplyForm,
  StyledTextField,
  UploadButton,
  CoverWrapper,
  StyledDivider,
  StyledAutocomplete,
  SubmitButton,
} from './styles'

const validations = {
  title: {
    required: 'Campo obrigatório',
  },
  description: {
    required: 'Campo obrigatório',
    maxLength: {
      value: 255,
      message: 'Número de caracteres máximo atingido',
    },
  },
  body: {
    required: 'Campo obrigatório',
  },
  categories: {
    required: 'Campo obrigatório',
    validate: (value) => value.length > 0,
  },
  keywords: {
    required: 'Campo obrigatório',
    validate: (value) => value.length > 0,
  },
}

const NewBlogPost = () => {
  const history = useHistory()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, formState, watch, errors, setValue } = useForm({
    mode: 'onChange',
  })
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [cover, setCover] = useState({ file: null, preview: '' })
  const status = watch('status')
  const publishAt = watch('publish_at')
  const description = watch('description')

  useEffect(() => {
    api
      .get('/blog-categories/list')
      .then(({ data }) => {
        setCategories(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    register('body', validations.body)
    register({ name: 'publish_at' })
    register('categories', validations.categories)
    register('keywords', validations.keywords)

    setValue('body', '')
    setValue('publish_at', new Date())
    setValue('categories', [])
    setValue('keywords', [])
  }, [register, setValue])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setCover({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const onSubmit = (data) => {
    if (!cover.file) {
      actions.notification.setNotification('warning', 'Falta carregar uma imagem de capa')
      return
    }
    if (!data.status && moment(data.publish_at).isBefore(moment())) {
      actions.notification.setNotification(
        'warning',
        'Falta selecionar uma data no futuro para publicar ou selecionar "Publicar já"'
      )
      return
    }
    setLoading(true)

    const categoriesIds = data.categories.map(
      (cat) => categories.find((c) => c.name === cat)?.id
    )
    if (categoriesIds.length <= 0) {
      actions.notification.setNotification(
        'error',
        'Não foi possível encontrar as categorias escolhidas'
      )
      return
    }
    const keywords = data.keywords.join(',')

    const formData = new FormData()
    formData.append('image', cover.file)
    formData.append('image_author_name', data.image_author_name)
    formData.append('image_author_url', data.image_author_url)
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('body', data.body)
    if (!data.status) {
      formData.append('publish_at', data.publish_at)
    }
    formData.append('status', data.status)
    formData.append('categories', categoriesIds)
    formData.append('keywords', keywords)

    api
      .post('/blog-posts', formData)
      .then(({ data }) => {
        setLoading(false)
        actions.notification.setNotification('success', 'Publicação criada com sucesso')
        window.location.pathname = '/blog'
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <MuiContainer maxWidth="lg" style={{ marginTop: 20 }}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <IconButton onClick={() => history.goBack()} aria-label="go-back">
            <LeftArrowAlt size={30} />
          </IconButton>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 600, marginLeft: 10 }}
          >
            Nova publicação
          </Typography>
          {!formState.isSubmitting && formState.isValid && (
            <FloatingBtn
              color="primary"
              type="submit"
              disabled={formState.isSubmitting || !formState.isValid || loading}
            >
              <Save size={30} />
            </FloatingBtn>
          )}
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={formState.isSubmitting || !formState.isValid || loading}
            endIcon={<Save size={22} />}
          >
            {loading ? <CircularProgress size={22} /> : 'Guardar'}
          </SubmitButton>
        </Grid>
        <Container container direction="column" spacing={3}>
          <Grid item xs={12} md={8}>
            <CoverWrapper>
              {cover.preview.length > 0 && (
                <img src={cover.preview} alt="Capa da publicação" />
              )}
            </CoverWrapper>
            <label htmlFor="files">
              <input
                hidden
                id="files"
                name="files"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              />
              <UploadButton
                variant="outlined"
                color="primary"
                component="span"
                fullWidth
                style={{ marginBottom: 20 }}
                startIcon={<Upload size={25} />}
              >
                Carregar capa da publicação
              </UploadButton>
            </label>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.image_author_name}
                >
                  <StyledTextField
                    fullWidth
                    variant="filled"
                    name="image_author_name"
                    label="Nome do autor da capa"
                    inputRef={register(validations.image_author_name)}
                    error={!!errors.image_author_name}
                    helpertext={
                      errors.image_author_name && errors.image_author_name.message
                    }
                  />
                  {errors.image_author_name && (
                    <FormHelperText>{errors.image_author_name.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.image_author_url}
                >
                  <StyledTextField
                    fullWidth
                    variant="filled"
                    name="image_author_url"
                    label="URL para perfil do autor"
                    inputRef={register(validations.image_author_url)}
                    error={!!errors.image_author_url}
                    helpertext={
                      errors.image_author_url && errors.image_author_url.message
                    }
                  />
                  {errors.image_author_url && (
                    <FormHelperText>{errors.image_author_url.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <StyledDivider variant="middle" />
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.title}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                name="title"
                label="Título"
                inputRef={register(validations.title)}
                error={!!errors.title}
                helpertext={errors.title && errors.title.message}
              />
              {errors.title && <FormHelperText>{errors.title.message}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.description}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                multiline
                rows={2}
                rowsMax={4}
                name="description"
                label="Descrição"
                placeholder="Pequeno resumo da publicação que aparecerá na homepage do blog e nas redes sociais"
                inputRef={register(validations.description)}
                error={!!errors.description}
                helperText={
                  description &&
                  `${
                    255 - description.length >= 0
                      ? `${255 - description.length} caracteres restantes`
                      : ''
                  }`
                }
              />
              {errors.description && (
                <FormHelperText>{errors.description.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="column">
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.publish_at}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Data para publicação
                  </Typography>
                </FormLabel>
                <DateTimePicker
                  autoOk
                  hideTabs
                  okLabel=""
                  disabled={status}
                  disablePast
                  label="Data"
                  ampm={false}
                  cancelLabel=""
                  name="publish_at"
                  inputVariant="filled"
                  format="DD/MM/YYYY HH:mm"
                  error={!!errors.publish_at}
                  DialogProps={{ fullWidth: fullScreen }}
                  value={publishAt}
                  onChange={(date) => setValue('publish_at', date)}
                  helperText={errors.publish_at && errors.publish_at.message}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <FormControlLabel
                  control={<IOSSwitch inputRef={register} name="status" />}
                  labelPlacement="end"
                  label="Publicar já"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.categories}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Categorias
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  options={categories.map((option) => option.name)}
                  filterSelectedOptions
                  noOptionsText="Sem categorias"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="filled"
                      name="categories"
                      error={!!errors.categories}
                      helpertext={errors.categories && errors.categories.message}
                    />
                  )}
                  onChange={(e, values) =>
                    setValue('categories', values, { shouldValidate: true })
                  }
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.keywords}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Palavras-Chave
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  options={[]}
                  freeSolo
                  noOptionsText="Sem palavras-chave"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="filled"
                      placeholder="Insere uma palavra e carrega em Enter"
                      name="keywords"
                      error={!!errors.keywords}
                      helpertext={errors.keywords && errors.keywords.message}
                    />
                  )}
                  onChange={(e, values) =>
                    setValue('keywords', values, { shouldValidate: true })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </form>
      <Grid container style={{ marginTop: 20 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          color="textSecondary"
          style={{ fontWeight: 600 }}
        >
          Conteúdo da publicação
        </Typography>
        <ReplyForm container direction="column">
          <Editor
            name="body"
            error={!!errors.body}
            setValue={(name, value) => setValue(name, value, { shouldValidate: true })}
          />
        </ReplyForm>
      </Grid>
    </MuiContainer>
  )
}

export default NewBlogPost
