export default {
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    body2: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  },
  palette: {
    type: 'light',
    menu: {
      text: '#505050',
      icon: '#D2D2D2',
      divider: '#F5F5F5',
      activeBg: 'rgba(0, 0, 0, 0.02)',
    },
    divider: '#F2F2F2',
    primary: {
      light: '#EDF2DC',
      main: '#89AB15',
      background: 'rgba(137, 171, 21, 0.2)',
      backgroundHover: 'rgba(137, 171, 21, 0.3)',
      shadow: 'rgba(137, 171, 21, 0.05)',
    },
    secondary: {
      main: '#314f37',
      dark: '#2F4633',
      darkHover: '#27372A',
      background: 'rgba(49, 79, 55, 0.2)',
      backgroundHover: 'rgba(49, 79, 55, 0.3)',
      shadow: 'rgba(49, 79, 55, 0.05)',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      background: 'rgba(76, 175, 80, 0.2)',
      backgroundHover: 'rgba(76, 175, 80, 0.3)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      background: 'rgba(33, 150, 243, 0.2)',
      backgroundHover: 'rgba(33, 150, 243, 0.3)',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      background: 'rgba(255, 152, 0, 0.2)',
      backgroundHover: 'rgba(255, 152, 0, 0.3)',
    },
    yellow: {
      light: '#ffea77',
      main: '#ffd700',
      dark: '#dfbe0a',
      background: 'rgba(255, 215, 0, 0.2)',
      backgroundHover: 'rgba(255, 215, 0, 0.3)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      background: 'rgba(244, 67, 54, 0.2)',
      backgroundHover: 'rgba(244, 67, 54, 0.3)',
    },
    pink: {
      light: '#f06292',
      main: '#e91e63',
      dark: '#c2185b',
      background: 'rgba(233,30,99, 0.2)',
      backgroundHover: 'rgba(233,30,99, 0.3)',
    },
    grey: {
      100: '#F5F5F5',
      200: '#E5E5E5',
      400: '#C4C4C4',
      800: '#505050',
    },
    background: {
      default: '#f6f8fb',
      input: '#f6f8fb',
    },
    action: {
      disabledBackground: 'rgba(0, 0, 0, 0.06)',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 3,
      },
      outlined: {
        border: 'none',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 3,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.03)',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 3,
      },
    },
    MuiFilledInput: {
      underline: {
        borderRadius: 3,
        backgroundColor: 'white',
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none',
        fontWeight: 600,
      },
      containedPrimary: {
        color: 'white',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#83A510',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      sizeLarge: {
        fontSize: '0.85rem',
      },
      outlinedPrimary: {
        border: '2px solid #89AB15',
        transition: 'color 400ms ease, background-color 400ms ease;',
        '&:hover': {
          backgroundColor: '#89AB15',
          color: 'white',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiPaginationItem: {
      textPrimary: {
        '&.Mui-selected': {
          color: 'white',
        },
      },
    },
    MUIDataTable: {
      paper: {
        marginTop: 15,
        borderRadius: 3,
        boxShadow: '0px 2px 10px rgba(0,0,0,0.03)',
      },
    },
    MuiTableBody: {
      root: {
        '& .MuiTableRow-root': {
          cursor: 'pointer',
        },
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        borderRadius: 3,
        border: '1px solid rgba(137, 171, 21, 0.2)',
        '-webkit-box-shadow': '0px 10px 15px -5px rgba(137, 171, 21, 0.1)',
        '-moz-box-shadow': '0px 10px 15px -5px rgba(137, 171, 21, 0.1)',
        'box-shadow': '0px 10px 15px -5px rgba(137, 171, 21, 0.1)',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        height: 350,
        maxWidth: '100%',
      },
      containerLandscape: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      pickerViewLandscape: {
        maxWidth: '100%',
        minHeight: 380,
        paddingTop: 15,
        paddingBottom: 10,
        justifyContent: 'flex-start',
        flex: 1,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        fontSize: '0.9rem',
        fontWeight: 600,
      },
      daysHeader: {
        justifyContent: 'space-evenly',
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-evenly',
        padding: '5px 0',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: 'white',
      },
    },
  },
}
