import { SET_NOTIFICATION, REMOVE_NOTIFICATION } from 'state/constants'

export default (props) => {
  return {
    setNotification: async (status, message) => {
      props.dispatch({ type: SET_NOTIFICATION, notification: { status, message } })
    },
    removeNotification: async () => {
      props.dispatch({ type: REMOVE_NOTIFICATION })
    },
  }
}
