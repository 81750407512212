import React from 'react'

import { Grid } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import styled from 'styled-components'

export const LoadingTimesGrid = styled(Grid)`
  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const RadioButton = styled(Grid)`
  background-color: ${({ theme }) => fade(theme.palette.background.paper, 0.7)};
  border: ${({ theme }) => `1.5px solid ${theme.palette.background.paper}`};
  font-weight: 500;
  font-size: 1rem;
  padding: 15px 0;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  transition: background-color 200ms ease-in-out, color 200ms ease;

  &.checked {
    border-color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.background};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const HasHoursWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`

const HasHoursCircle = styled.div`
  width: 8px;
  height: 8px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const HasHours = () => (
  <HasHoursWrapper>
    <HasHoursCircle />
  </HasHoursWrapper>
)
