import React, { useContext, useState } from 'react'

import { DialogContent, FormControlLabel } from '@material-ui/core'
import { api } from 'api'
import Dialog from 'components/shared/Dialog'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import { StyledCardMedia, StyledTextField } from './styles'

const TemplatesNewDialog = ({ open, file, setFile }) => {
  const { actions } = useContext(StateContext)
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState(true)

  const handleClose = () => {
    setTitle('')
    setStatus(true)
    setFile(null)
  }

  const onSubmit = () => {
    if (title.trim().length <= 0) return

    const formData = new FormData()
    formData.append('title', title)
    formData.append('status', status)
    formData.append('image', file.file)

    api
      .post('/voucher-templates', formData)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  if (!file) return null

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Novo template"
      onSave={title.trim().length > 0 ? onSubmit : null}
    >
      <StyledCardMedia image={file.preview} title={file.file.name} />
      <DialogContent style={{ padding: '15px 24px', textAlign: 'right' }}>
        <StyledTextField
          fullWidth
          variant="filled"
          autoFocus
          name="title"
          label="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <FormControlLabel
          style={{ marginTop: 15 }}
          control={
            <IOSSwitch
              checked={status}
              onChange={(e) => setStatus(e.target.checked)}
              name="status"
            />
          }
          labelPlacement="start"
          label="Ativar"
        />
      </DialogContent>
    </Dialog>
  )
}

TemplatesNewDialog.propTypes = {
  open: PropTypes.bool,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.object,
}

TemplatesNewDialog.defaultProps = {
  open: false,
  file: null,
}

export default TemplatesNewDialog
