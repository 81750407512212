import React, { useContext, useEffect, useState } from 'react'
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd'
import { useHistory } from 'react-router-dom'

import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alarm } from '@styled-icons/boxicons-regular/Alarm'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import parseDuration from 'helpers/parse-duration'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

import {
  PlusIconWrapper,
  StyledCard,
  StyledCardContent,
  StyledCardMedia,
  StyledChip,
} from './styles'

const ServicesList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [services, setServices] = useState(null)
  const [isSorted, setIsSorted] = useState(false)
  const { actions } = useContext(StateContext)
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isPhone = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isExtraSm = useMediaQuery('(max-width:480px) and (min-width:390px)')
  const isSmaller = useMediaQuery('(max-width:390px)')

  useEffect(() => {
    api.get('/services/list').then(({ data }) => {
      setServices(data)
    })
  }, [])

  useEffect(() => {
    if (isSorted && services.length > 0) {
      const newServiceOrder = []
      services.forEach((service, i) => {
        newServiceOrder.push({ id: service.id, order: i + 1 })
      })

      api.patch('/services/order', { services: newServiceOrder }).catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, isSorted])

  const onSort = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(services, sourceIndex, targetIndex)
    setServices(nextState)
    setIsSorted(true)
  }

  if (!services) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <StyledCard variant="outlined" style={{ textAlign: 'center' }}>
            <CardActionArea
              onClick={() => history.push(`/services/new`)}
              style={{ height: '100%' }}
            >
              <StyledCardContent style={{ flexDirection: 'row' }}>
                <PlusIconWrapper container justify="center" alignItems="center">
                  <Plus size={25} />
                </PlusIconWrapper>
                <Typography variant="h6" style={{ fontWeight: 500 }}>
                  Inserir Serviço
                </Typography>
              </StyledCardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      </Grid>
      <GridContextProvider onChange={onSort}>
        <GridDropZone
          id="services"
          boxesPerRow={isLg ? 3 : isTablet ? 2 : isXs ? 1 : 2}
          rowHeight={
            isLg
              ? 336
              : isTablet
              ? 442
              : isPhone
              ? 360
              : isExtraSm
              ? 360
              : isSmaller
              ? 312
              : 420
          }
          style={{ margin: -12, height: '100vh' }}
        >
          {services.map((service, i) => (
            <GridItem key={service.id} style={{ padding: 12 }}>
              <Card variant="outlined" style={{ position: 'relative', cursor: 'move' }}>
                <StyledChip
                  status={service.status.toString()}
                  label={service.status ? 'Ativo' : 'Inativo'}
                />
                <StyledCardMedia image={service.image_url} title={service.name} />
                <CardActionArea
                  onClick={() => history.push(`/services/${service.id}/info`)}
                  style={{ height: '100%' }}
                >
                  <CardContent style={{ paddingBottom: 12 }}>
                    <Typography variant="h6" color="secondary">
                      {service.name}
                    </Typography>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Alarm size={20} style={{ opacity: 0.5, marginRight: 10 }} />
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          style={{ lineHeight: 0 }}
                        >
                          {parseDuration(service.duration)}
                        </Typography>
                      </div>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        style={{ fontWeight: 600 }}
                      >
                        {service.price.toFixed(2)} €
                      </Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </Container>
  )
}

export default ServicesList
