import React, { forwardRef } from 'react'

import {
  useTheme,
  useMediaQuery,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Slide,
  Fade,
} from '@material-ui/core'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import PropTypes from 'prop-types'

import { StyledAppBar, StyledMuiDialog } from './styles'

const SlideTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const FadeTransition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

const Dialog = ({ open, handleClose, title, onSave, children }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledMuiDialog
      fullScreen={fullScreen}
      open={open}
      fullWidth
      onClose={handleClose}
      TransitionComponent={fullScreen ? SlideTransition : FadeTransition}
    >
      <StyledAppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseOutline size={25} />
          </IconButton>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ flex: 1, marginLeft: 10, fontWeight: 500 }}
          >
            {title}
          </Typography>
          {onSave && (
            <Button
              color="primary"
              style={{ textTransform: 'none' }}
              size="large"
              onClick={onSave}
            >
              Guardar
            </Button>
          )}
        </Toolbar>
      </StyledAppBar>
      {children}
    </StyledMuiDialog>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  children: PropTypes.node.isRequired,
}
Dialog.defaultProps = {
  onSave: null,
}

export default Dialog
