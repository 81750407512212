import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { api } from 'api'
import Dialog from 'components/shared/Dialog'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { DateWrapper, DeleteBtn } from 'pages/Schedule/styles'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import { StyledCard, UserName } from './styles'

const EventDetailsDialog = ({ open, setOpen, event, setEvent, refreshEvents }) => {
  const { actions } = useContext(StateContext)
  const [loading, setLoading] = useState(false)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    if (event) {
      setLoading(true)
      api
        .get(`/schedule/${event.id}/appointments`)
        .then(({ data }) => {
          setLoading(false)
          console.log(data)
          setAppointments(data)
        })
        .catch((e) => {
          setLoading(false)
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setEvent(null)
      setAppointments([])
    }, 200)
  }

  const handleDelete = () => {
    if (window.confirm('Tens a certeza que queres eliminar este horário?')) {
      api
        .delete(`/schedule/${event.id}`)
        .then(() => {
          refreshEvents()
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
      handleClose()
    }
  }

  if (!event) return null

  return (
    <Dialog open={open} handleClose={handleClose} title="Detalhes do horário">
      <DialogContent style={{ padding: '15px 24px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateWrapper container direction="column">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: 500, textTransform: 'capitalize' }}
              >
                {moment(event.start).format('dddd, DD MMM YYYY')}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ letterSpacing: '0.1rem', lineHeight: '1.8rem' }}
              >
                {moment(event.start).format('HH:mm')}
              </Typography>
            </DateWrapper>
          </Grid>
          <Grid item xs={6}>
            <DateWrapper container direction="column">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: 500, textTransform: 'capitalize' }}
              >
                {moment(event.end).format('dddd, DD MMM YYYY')}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ letterSpacing: '0.1rem', lineHeight: '1.8rem' }}
              >
                {moment(event.end).format('HH:mm')}
              </Typography>
            </DateWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ fontWeight: 600, margin: '15px 0 10px 0' }}
            >
              Marcações para este horário
            </Typography>
            {!loading &&
              appointments.map((appointment) => (
                <Link
                  key={appointment.id}
                  to={`/appointments/${appointment.id}/info`}
                  style={{ textDecoration: 'none' }}
                >
                  <StyledCard>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs="auto" style={{ marginRight: 20 }}>
                        <Grid container alignItems="flex-start">
                          <Grid item style={{ marginRight: 3 }}>
                            <Typography variant="h4" color="secondary">
                              {+appointment.start_time.split(':')[0]}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" color="secondary">
                              {appointment.start_time.split(':')[1]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <Grid container direction="column">
                          <Typography variant="subtitle2" color="primary" gutterBottom>
                            {appointment.service.name}
                          </Typography>
                          <UserName variant="subtitle2" color="secondary">
                            {appointment.user?.name || appointment.user_name}
                          </UserName>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledCard>
                </Link>
              ))}
            {!loading && !appointments.length && (
              <Typography variant="subtitle1">
                Não existem marcações neste horário
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: 12 }}>
        <DeleteBtn
          size="large"
          color="primary"
          variant="contained"
          onClick={handleDelete}
          disabled={loading || appointments.length > 0}
        >
          Eliminar horário
        </DeleteBtn>
      </DialogActions>
    </Dialog>
  )
}

EventDetailsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  event: PropTypes.object,
  setEvent: PropTypes.func.isRequired,
  refreshEvents: PropTypes.func.isRequired,
}

EventDetailsDialog.defaultProps = {
  open: false,
  event: null,
}

export default EventDetailsDialog
