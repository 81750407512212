import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import {
  Chip,
  Container as MuiContainer,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Save } from '@styled-icons/boxicons-regular/Save'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api } from 'api'
import Editor from 'components/Services/Editor'
import LoadingPage from 'components/shared/LoadingPage'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { FloatingBtn } from 'pages/styles'
import { StateContext } from 'state'

import {
  Container,
  ReplyForm,
  StyledTextField,
  UploadButton,
  CoverWrapper,
  StyledAutocomplete,
  SubmitButton,
} from '../New/styles'

const validations = {
  name: {
    required: 'Campo obrigatório',
  },
  description: {
    required: 'Campo obrigatório',
    maxLength: {
      value: 255,
      message: 'Número de caracteres máximo atingido',
    },
  },
  content: {
    required: 'Campo obrigatório',
  },
  duration: {
    required: 'Campo obrigatório',
  },
  price: {
    required: 'Campo obrigatório',
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Preço tem de ser maior que zero',
    },
  },
}

const EditService = () => {
  const { id } = useParams()
  const history = useHistory()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, formState, watch, errors, setValue } = useForm({
    mode: 'onChange',
  })
  const [service, setService] = useState(null)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [cover, setCover] = useState({ file: null, preview: '' })
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const duration = watch('duration')
  const description = watch('description')

  useEffect(() => {
    if (id) {
      api
        .get(`/services/${id}/info`)
        .then(({ data }) => {
          setService(data)

          const keywords = data.keywords.split(',')
          setValue('name', data.name)
          setValue('description', data.description)
          setValue('price', data.price)
          setValue('content', data.content)
          setValue('duration', moment(data.duration, 'HH:mm:ss'))
          setStatus(data.status)
          setSelectedKeywords(keywords)
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    register('duration', validations.duration)
    register('content', validations.content)
  }, [register, setValue])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setCover({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const onSubmit = (data) => {
    if (selectedKeywords.length === 0) {
      actions.notification.setNotification('warning', 'Falta escolheres palavras-chave')
      return
    }
    if (moment(data.duration).hours() === 0 && moment(data.duration).minutes() === 0) {
      actions.notification.setNotification(
        'warning',
        'A duração tem de ser maior que zero'
      )
      return
    }
    setLoading(true)

    const keywords = selectedKeywords.join(',')

    const formData = new FormData()
    if (cover.file) {
      formData.append('image', cover.file)
    }
    formData.append('name', data.name)
    formData.append('price', data.price)
    formData.append('content', data.content)
    formData.append('description', data.description)
    formData.append('duration', moment(data.duration).format('HH:mm:00'))
    formData.append('status', status)
    formData.append('keywords', keywords)

    api
      .patch(`/services/${service.id}`, formData)
      .then(() => {
        setLoading(false)
        actions.notification.setNotification('success', 'Serviço editado com sucesso')
        window.location.pathname = '/services'
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  if (!service) {
    return <LoadingPage />
  }

  return (
    <MuiContainer maxWidth="lg" style={{ marginTop: 20 }}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <IconButton onClick={() => history.goBack()} aria-label="go-back">
            <LeftArrowAlt size={30} />
          </IconButton>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 600, marginLeft: 10 }}
          >
            Editar serviço
          </Typography>
          {!formState.isSubmitting && (
            <FloatingBtn
              color="primary"
              type="submit"
              disabled={formState.isSubmitting || loading}
            >
              <Save size={30} />
            </FloatingBtn>
          )}
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={formState.isSubmitting || loading}
            endIcon={<Save size={22} />}
          >
            {loading ? <CircularProgress size={22} /> : 'Guardar'}
          </SubmitButton>
        </Grid>
        <Container container direction="column" spacing={3}>
          <Grid item xs={12} md={8}>
            <CoverWrapper>
              {cover.preview.length > 0 && (
                <img src={cover.preview} alt="Capa do serviço" />
              )}
              {cover.preview.length === 0 && service.image_url && (
                <img src={service.image_url} alt="Capa do serviço" />
              )}
            </CoverWrapper>
            <label htmlFor="files">
              <input
                hidden
                id="files"
                name="files"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              />
              <UploadButton
                variant="outlined"
                color="primary"
                component="span"
                fullWidth
                style={{ marginBottom: 20 }}
                startIcon={<Upload size={25} />}
              >
                Carregar capa do serviço
              </UploadButton>
            </label>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.name}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                name="name"
                label="Nome"
                inputRef={register(validations.name)}
                error={!!errors.name}
                helpertext={errors.name && errors.name.message}
              />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.description}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                multiline
                rows={2}
                rowsMax={4}
                name="description"
                label="Descrição"
                placeholder="Pequeno resumo do serviço que aparecerá na homepage do site, página de detalhes e nas redes sociais"
                inputRef={register(validations.description)}
                error={!!errors.description}
                helperText={
                  description &&
                  `${
                    255 - description.length >= 0
                      ? `${255 - description.length} caracteres restantes`
                      : ''
                  }`
                }
              />
              {errors.description && (
                <FormHelperText>{errors.description.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="column">
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.duration}
              >
                <TimePicker
                  autoOk
                  okLabel=""
                  label="Duração"
                  ampm={false}
                  cancelLabel=""
                  name="duration"
                  inputVariant="filled"
                  format="HH:mm"
                  error={!!errors.duration}
                  DialogProps={{ fullWidth: fullScreen }}
                  value={duration}
                  onChange={(date) =>
                    setValue('duration', date, { shouldValidate: true })
                  }
                  helperText={errors.duration && errors.duration.message}
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.price}
              >
                <StyledTextField
                  fullWidth
                  variant="filled"
                  name="price"
                  type="number"
                  inputProps={{ min: 1, step: 'any' }}
                  label="Preço (€)"
                  inputRef={register(validations.price)}
                  error={!!errors.price}
                  helpertext={errors.price && errors.price.message}
                />
                {errors.price && <FormHelperText>{errors.price.message}</FormHelperText>}
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.keywords}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Palavras-Chave
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  options={[]}
                  value={selectedKeywords}
                  onChange={(e, values) => setSelectedKeywords(values)}
                  freeSolo
                  noOptionsText="Sem palavras-chave"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="filled"
                      placeholder="Insere uma palavra e carrega em Enter"
                      name="keywords"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                      name="status"
                    />
                  }
                  labelPlacement="end"
                  label="Ativar"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </form>
      <Grid container style={{ marginTop: 20 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          color="textSecondary"
          style={{ fontWeight: 600 }}
        >
          Conteúdo para página de detalhes do serviço
        </Typography>
        <ReplyForm container direction="column">
          <Editor
            name="content"
            defaultText={service.content}
            error={!!errors.content}
            setValue={(name, value) => setValue(name, value, { shouldValidate: true })}
          />
        </ReplyForm>
      </Grid>
    </MuiContainer>
  )
}

export default EditService
