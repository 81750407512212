/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Typography,
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
  CircularProgress,
  Container,
} from '@material-ui/core'
import { Visibility } from '@styled-icons/material/Visibility'
import { VisibilityOff } from '@styled-icons/material/VisibilityOff'
import { api } from 'api'
import aptLogo from 'assets/logo.png'
import parseErrorMessage from 'helpers/parse-error-message'
import { ContainerCard } from 'pages/styles'
import { StateContext } from 'state'

import { Logo, StyledButton } from './styles'

const validations = {
  password: {
    required: 'Campo obrigatório',
    minLength: {
      value: 8,
      message: 'Deve conter pelo menos 8 caracteres',
    },
  },
}

const Login = () => {
  const location = useLocation()
  const history = useHistory()
  const { state, actions } = useContext(StateContext)
  const { handleSubmit, register, errors } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    let { from } = location.state || { from: { pathname: '/' } }
    if (state.auth.token?.length > 0) {
      history.replace(from)
    } else {
      actions.auth
        .init()
        .then(() => history.replace(from))
        .catch(() => setLoading(false))
    }
  }, [state])

  const postLogin = (data) => {
    actions.auth
      .login(data.password)
      .then(() => {
        actions.notification.setNotification('success', 'Login efetuado com sucesso')
      })
      .catch((error) => {
        const message = parseErrorMessage(error)
        actions.notification.setNotification('error', message)
      })
  }

  const postResetPw = () => {
    setForgotPassword(false)
    api
      .post('/forgot-admin')
      .then(({ data }) => {
        if (data.success) {
          actions.notification.setNotification(
            'success',
            'Email para reposição da senha enviado com sucesso'
          )
        }
      })
      .catch((error) => {
        actions.notification.setNotification('error', error.response.data?.message)
      })
  }

  if (loading) {
    return (
      <Grid style={{ height: '100vh' }} container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Container maxWidth="lg">
      <Grid container style={{ height: '100vh' }} justify="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container justify="center" alignItems="center">
            <Logo src={aptLogo} alt="Logo Ana Pinto Terapeuta" />
            <div>
              <Typography
                variant="h4"
                style={{ fontWeight: 600, lineHeight: 0.9 }}
                color="primary"
              >
                Ana Pinto
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 600 }} color="secondary">
                Terapeuta
              </Typography>
            </div>
          </Grid>
          <form onSubmit={handleSubmit(postLogin)}>
            <ContainerCard
              style={{ padding: '40px 30px', marginTop: 40 }}
              container
              justify="center"
              alignItems="center"
            >
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 30 }}
                error={!!errors.password}
              >
                <InputLabel htmlFor="password">Senha</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  inputRef={register(validations.password)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <Visibility size={25} />
                        ) : (
                          <VisibilityOff size={25} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={45}
                />
                {errors.password && (
                  <FormHelperText>{errors.password.message}</FormHelperText>
                )}
              </FormControl>

              <Grid container spacing={2} justify="space-between" alignItems="center">
                <Grid item style={{ flex: 1 }}>
                  <StyledButton
                    disableRipple
                    disableFocusRipple
                    variant="text"
                    color="primary"
                    onClick={() => setForgotPassword(true)}
                  >
                    Esqueceste a senha?
                  </StyledButton>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ padding: '12px 30px', textTransform: 'none' }}
                    fullWidth
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </ContainerCard>
          </form>
        </Grid>
        <Dialog
          open={forgotPassword}
          onClose={() => setForgotPassword(false)}
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Queres repor a senha?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao aceitar receberás um email na tua caixa de correio com a senha temporária
              para que possas entrar aqui e escolher uma nova senha.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: 16 }}>
            <Button onClick={() => setForgotPassword(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={postResetPw} color="primary" variant="contained" autoFocus>
              Aceitar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Container>
  )
}

export default Login
