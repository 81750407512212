import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import UserInfo from './Info'
import UsersList from './List'

const Users = () => (
  <Switch>
    <Route path="/users/:id/info">
      <UserInfo />
    </Route>
    <Route exact path="/users">
      <UsersList />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
)

export default Users
