import { AppBar, Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const StyledAppBar = styled(AppBar)`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-bottom: 10px;
  padding-right: 0 !important;
`

export const StyledMuiDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`
