import { useContext, useEffect, useState } from 'react'

import { publicApi } from 'api'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

const useServices = () => {
  const { actions } = useContext(StateContext)
  const [services, setServices] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    publicApi
      .get('/services')
      .then(({ data }) => {
        setLoading(false)
        setServices(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { services, loading }
}

export default useServices
