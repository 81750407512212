import { Avatar, CardMedia, Chip, Typography } from '@material-ui/core'
import { StyledTextField } from 'pages/Users/List/styles'
import styled from 'styled-components'

export const StyledCardMedia = styled(CardMedia)`
  margin-left: 20px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(7)}px`};
  height: ${({ theme }) => `${theme.spacing(7)}px`};
  color: ${({ theme }) => theme.palette.background.default};
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const AvatarName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${
      status === 'true'
        ? theme.palette.success.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.success.main : theme.palette.warning.main};
  `}
  .MuiChip-label {
    font-weight: 500;
  }
`

export const AnonymousTextField = styled(StyledTextField)`
  & .MuiFilledInput-input {
    padding: 10px 12px;
  }
`
