import { Button, Paper, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const StyledTextArea = styled(TextField)`
  margin: 15px 0;

  & .MuiFilledInput-root {
    min-height: 70px;
    background-color: ${({ theme }) => theme.palette.background.input};
  }
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.input};
  }
`

export const UserRecordTitle = styled(Typography)`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.default};
  margin: 20px 0;
`

export const UploadButton = styled(Button)`
  height: 50px;
  margin-bottom: 25px;
`

export const DeleteFileBtn = styled(Button)`
  margin-top: 10px;
  background-color: ${({ theme }) => theme.palette.error.background};
  color: ${({ theme }) => theme.palette.error.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    color: white;
  }
`

export const StyledPaper = styled(Paper)`
  overflow: hidden;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: pre;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & img {
    max-width: 100%;
    height: 100%;
  }
`

export const ImgLabel = styled.div`
  width: 100%;
  text-align: center;
  white-space: no-wrap;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
`
