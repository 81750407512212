import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Link as MuiLink,
  Card,
  CardActionArea,
  Button,
  Container,
} from '@material-ui/core'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import parseErrorMessage from 'helpers/parse-error-message'
import { ContainerCard, FloatingBtn } from 'pages/styles'
import { StateContext } from 'state'

import {
  BlogCategoriesLink,
  StatusDot,
  StyledContainerCard,
  StyledTextField,
  StyledChip,
  Likes,
  StyledCardContent,
  ButtonGrid,
} from './styles'

const BlogList = () => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [posts, setPosts] = useState(null)
  const [categories, setCategories] = useState(null)
  const [mostLiked, setMostLiked] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(-1)
  const [filteredPosts, setFilteredPosts] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    api
      .get('/blog-posts/list')
      .then(({ data }) => {
        setPosts(data.posts)
        setMostLiked(data.mostLiked)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    api
      .get('/blog-categories/list')
      .then(({ data }) => {
        setCategories(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!posts) return
    const filtered = posts.filter(
      (p) =>
        selectedCategory === -1 || p.categories.find((c) => c.id === selectedCategory)
    )
    setFilteredPosts(filtered)
  }, [selectedCategory, posts])

  if (!filteredPosts || !categories) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <FloatingBtn color="primary" onClick={() => history.push('/blog/new')}>
        <Plus size={30} />
      </FloatingBtn>
      <Grid
        container
        justify="space-evenly"
        style={{ margin: '30px 0', padding: '0 20px' }}
      >
        <StyledContainerCard container justify="space-between" item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Estado das publicações
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <StatusDot status="success" />
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {posts.filter((post) => post.status).length} publicadas
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <StatusDot status="pending" />
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {posts.filter((post) => !post.status).length} não publicadas
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledContainerCard>
        <StyledContainerCard container alignItems="stretch" item xs={12} sm={5} md={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Publicação com mais gostos
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            <MuiLink
              style={{ overflow: 'hidden' }}
              href={`${process.env.REACT_APP_SITE_URL}/blogue/${mostLiked?.slug}`}
              target="_blank"
            >
              <Typography
                variant="subtitle1"
                noWrap
                style={{ fontWeight: 500, textOverflow: 'ellipsis' }}
              >
                {mostLiked?.title}
              </Typography>
            </MuiLink>
            <Typography variant="subtitle2" color="textSecondary">
              {mostLiked?.__meta__.likes_count} gostos
            </Typography>
          </Grid>
        </StyledContainerCard>
        <ContainerCard
          container
          direction="column"
          justify="space-around"
          item
          xs={12}
          sm={6}
          md={3}
          style={{
            borderRadius: 3,
            height: 130,
            alignItems: 'flex-start',
            padding: 0,
          }}
        >
          <BlogCategoriesLink component={Link} to={`/blog/categories`}>
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                Categorias do
              </Typography>
              <Typography variant="h5">Blogue</Typography>
            </div>
            <ChevronRight size={25} />
          </BlogCategoriesLink>
        </ContainerCard>
      </Grid>
      <Grid container style={{ marginTop: 15 }}>
        <StyledChip
          clickable
          ccolor="137, 171, 21"
          label="Todas as categorias"
          onClick={() => setSelectedCategory(-1)}
          active={(selectedCategory === -1).toString()}
        />
        {categories?.map((category) => (
          <StyledChip
            key={category.id}
            clickable
            ccolor={category.color}
            label={category.name}
            onClick={() => setSelectedCategory(category.id)}
            active={(selectedCategory === category.id).toString()}
          />
        ))}
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledTextField
            placeholder="Procura pelo título da publicação"
            variant="filled"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={27} />
                </InputAdornment>
              ),
              endAdornment: search.trim().length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch('')} label="clear-search">
                    <CloseOutline size={27} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <ButtonGrid item xs={12} md={6}>
          <Button
            variant="contained"
            onClick={() => history.push('/blog/new')}
            color="primary"
            size="large"
            startIcon={<Plus size={25} />}
            style={{ height: 54 }}
          >
            Nova publicação
          </Button>
        </ButtonGrid>
      </Grid>
      <Grid container style={{ marginTop: 15 }} spacing={3}>
        {filteredPosts
          .filter(
            (p) =>
              search.trim().length <= 0 ||
              p.title.toLowerCase().includes(search.toLowerCase())
          )
          .map((post) => (
            <Grid key={post.id} item xs={12} sm={6} lg={4}>
              <Card style={{ height: '100%' }} variant="outlined">
                <CardActionArea
                  onClick={() => history.push(`/blog/${post.id}/edit`)}
                  style={{ height: '100%' }}
                >
                  <StyledCardContent>
                    <Grid item>
                      {post.categories.map((category) => (
                        <StyledChip
                          key={category.id}
                          ccolor={category.color}
                          label={category.name}
                        />
                      ))}
                    </Grid>
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      style={{ fontWeight: 600, margin: '15px 0' }}
                    >
                      {post.title}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      Publicado a: {post.publish_at}
                    </Typography>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledChip
                          status={post.status.toString()}
                          label={post.status ? 'Ativo' : 'Inativo'}
                        />
                        {post.status && (
                          <MuiLink
                            href={`${process.env.REACT_APP_SITE_URL}/blogue/${post.slug}`}
                            target="_blank"
                            color="primary"
                            style={{ marginLeft: 5 }}
                          >
                            Ver no site
                          </MuiLink>
                        )}
                      </div>
                      <Grid item>
                        <Likes count={post.likes} />
                      </Grid>
                    </Grid>
                  </StyledCardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  )
}

export default BlogList
