import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { api } from 'api'
import CategoryEditDialog from 'components/Blog/Categories/EditDialog'
import CategoryNewDialog from 'components/Blog/Categories/NewDialog'
import LoadingPage from 'components/shared/LoadingPage'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

import {
  StyledCardMedia,
  StyledCard,
  StyledCardContent,
  PlusIconWrapper,
  StyledChip,
  CategoryCard,
} from './styles'

const BlogCategories = () => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [categories, setCategories] = useState(null)
  const [editCategory, setEditCategory] = useState(null)

  useEffect(() => {
    api
      .get('/blog-categories/list')
      .then(({ data }) => {
        setCategories(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOpenEdit(!!editCategory)
  }, [editCategory])

  if (!categories) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container alignItems="center" style={{ marginBottom: 15 }}>
        <IconButton onClick={() => history.goBack()} aria-label="go-back">
          <LeftArrowAlt size={30} />
        </IconButton>
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: 600, marginLeft: 10 }}
        >
          Categorias do Blogue
        </Typography>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6} lg={4}>
            <StyledCard variant="outlined" style={{ textAlign: 'center' }}>
              <CardActionArea onClick={() => setOpenNew(true)} style={{ height: '100%' }}>
                <StyledCardContent>
                  <PlusIconWrapper container justify="center" alignItems="center">
                    <Plus size={45} />
                  </PlusIconWrapper>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 500 }}>
                    Inserir Categoria
                  </Typography>
                </StyledCardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          {categories.map((category, i) => (
            <Grid key={category.id} item xs={12} sm={6} lg={4}>
              <CategoryCard color={category.color} variant="outlined">
                <CardActionArea
                  onClick={() => setEditCategory(category)}
                  style={{ height: '100%' }}
                >
                  <StyledCardMedia image={category.image_url} title={category.name} />
                  <CardContent style={{ paddingBottom: 12 }}>
                    <Typography variant="h6">{category.name}</Typography>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <StyledChip
                        status={category.status.toString()}
                        label={category.status ? 'Ativo' : 'Inativo'}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          color="secondary"
                          style={{ marginRight: 5 }}
                        >
                          <b>{category.posts.length}</b>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          publicações
                        </Typography>
                      </div>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </CategoryCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CategoryNewDialog open={openNew} setOpen={setOpenNew} />
      <CategoryEditDialog
        open={openEdit}
        setCategory={setEditCategory}
        category={editCategory}
      />
    </Container>
  )
}

export default BlogCategories
