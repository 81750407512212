import authActions from './auth'
import globalActions from './global'
import notificationActions from './notification'

export default (state, dispatch) => {
  return {
    global: globalActions({ state, dispatch }),
    auth: authActions({ state, dispatch }),
    notification: notificationActions({ state, dispatch }),
  }
}
