import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.03);
  height: 100%;
  z-index: 100;
`
