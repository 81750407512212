import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import {
  CardMedia,
  Chip,
  Container as MuiContainer,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { Alarm } from '@styled-icons/boxicons-regular/Alarm'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Save } from '@styled-icons/boxicons-regular/Save'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api, publicApi } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import RadioCard from 'components/shared/RadioCard'
import IOSSwitch from 'components/shared/Switch'
import parseDuration from 'helpers/parse-duration'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { FloatingBtn } from 'pages/styles'
import { StateContext } from 'state'

import { DeleteBtn } from '../List/styles'
import {
  Container,
  StyledTextField,
  UploadButton,
  CoverWrapper,
  StyledDivider,
  StyledAutocomplete,
  SubmitButton,
  StyledCardContent,
} from '../New/styles'

const validations = {
  name: {
    required: 'Campo obrigatório',
  },
  description: {
    required: 'Campo obrigatório',
    maxLength: {
      value: 255,
      message: 'Número de caracteres máximo atingido',
    },
  },
  price: {
    required: 'Campo obrigatório',
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Preço tem de ser maior que zero',
    },
  },
}

const EditServicePack = () => {
  const { id } = useParams()
  const history = useHistory()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, formState, watch, errors, setValue } = useForm({
    mode: 'onChange',
  })
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [servicePack, setServicePack] = useState(null)
  const [totalPrice, setTotalPrice] = useState(0.0)
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [servicesSessions, setServicesSessions] = useState(null)
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [cover, setCover] = useState({ file: null, preview: '' })
  const expiresAt = watch('expires_at')
  const description = watch('description')

  useEffect(() => {
    if (id) {
      api
        .get(`/service-packs/${id}/info`)
        .then(({ data }) => {
          setServicePack(data)

          const servicesIds = data.services.map((service) => service.id)
          const keywords = data.keywords.split(',')
          let price = 0
          data.services.map((service) => (price += Number(service.price.toFixed(2))))
          setTotalPrice(price.toFixed(2))
          setValue('name', data.name)
          setValue('description', data.description)
          setValue('price', data.price)
          setValue('expires_at', moment(data.expires_at, 'DD-MM-YYYY HH:mm:ss'))
          setStatus(data.status)
          setSelectedKeywords(keywords)
          setSelectedServices(servicesIds)
          let sessions = {}
          servicesIds.forEach((serviceId) => {
            const service = data.services.find((s) => s.id === serviceId)
            if (service) {
              sessions[serviceId] = service.sessions
            }
          })
          setServicesSessions(sessions)
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    publicApi
      .get('/services')
      .then(({ data }) => {
        setServices(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (services.length === 0 || selectedServices.length === 0 || !servicesSessions) {
      setTotalPrice(0)
      return
    }
    let total = 0
    selectedServices.forEach((serviceId) => {
      if (servicesSessions[serviceId]) {
        const service = services.find((s) => s.id === serviceId)
        if (service) {
          total =
            Number(total) + Number(servicesSessions[serviceId] * service.price.toFixed(2))
        }
      }
    })
    setTotalPrice(total)
  }, [services, selectedServices, servicesSessions])

  useEffect(() => {
    register({ name: 'expires_at' })
    register({ name: 'keywords' })
  }, [register, setValue])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setCover({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSelectService = (service) => {
    const clone = [...selectedServices]
    let sessionsClone = { ...servicesSessions }
    const index = clone.indexOf(service.id)
    if (index > -1) {
      clone.splice(index, 1)
      delete sessionsClone[service.id]
    } else {
      clone.push(service.id)
      sessionsClone[service.id] = 1
    }
    sessionsClone = Object.keys(sessionsClone).length > 0 ? sessionsClone : null
    setSelectedServices(clone)
    setServicesSessions(sessionsClone)
  }

  const handleDelete = () => {
    if (window.confirm('Tens a certeza que queres eliminar este pacote de serviços?')) {
      setLoading(true)
      api
        .delete(`/service-packs/${servicePack.id}`)
        .then(() => {
          setLoading(false)
          actions.notification.setNotification(
            'success',
            'Pacote de serviços removido com sucesso'
          )
          window.location.pathname = '/service-packs'
        })
        .catch((e) => {
          setLoading(false)
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
  }

  const onSubmit = (data) => {
    if (status && moment(data.expires_at).isBefore(moment())) {
      actions.notification.setNotification(
        'warning',
        'Escolhe uma data de expiração superior à data atual'
      )
      return
    }
    if (selectedServices.length < 2) {
      actions.notification.setNotification(
        'warning',
        'Escolhe pelo menos 2 serviços para este pacote'
      )
      return
    }

    setLoading(true)

    const keywords = selectedKeywords.join(',')
    const formData = new FormData()
    if (cover.file) {
      formData.append('image', cover.file)
    }
    formData.append('name', data.name)
    formData.append('price', data.price)
    formData.append('description', data.description)
    formData.append('expires_at', data.expires_at)
    formData.append('status', status)
    formData.append('services', selectedServices)
    formData.append('sessions', JSON.stringify(servicesSessions))
    formData.append('keywords', keywords)

    api
      .patch(`/service-packs/${servicePack.id}`, formData)
      .then(() => {
        setLoading(false)
        actions.notification.setNotification(
          'success',
          'Pacote de serviços editado com sucesso'
        )
        window.location.pathname = '/service-packs'
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const updateSessionNumber = (event, service) => {
    if (event.charCode === 46 || event.target.value < 1) return // no floats & no number below 1

    const sessionsClone = { ...servicesSessions }
    sessionsClone[service.id] = +event.target.value.replace('.', '')
    setServicesSessions(sessionsClone)
  }

  const renderServiceCard = (service) => (
    <Grid item xs={12} sm={6} lg={4} xl={3} key={service.id} style={{ height: '100%' }}>
      <RadioCard
        direction="row"
        selected={selectedServices.includes(service.id)}
        onClick={() => handleSelectService(service)}
        custom={{ height: '100%' }}
        actionArea={
          <>
            <CardMedia
              style={{ width: 130, minHeight: 145, height: '100%' }}
              component="img"
              alt={service.name}
              image={service.image_url}
            />
            <StyledCardContent>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {service.name}
              </Typography>
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  item
                  style={{ flex: 1, marginRight: 5 }}
                  container
                  alignItems="center"
                >
                  <Alarm size={20} style={{ opacity: 0.5, marginRight: 10 }} />
                  <Typography variant="subtitle2" color="textSecondary">
                    {parseDuration(service.duration)}
                  </Typography>
                </Grid>
                <Typography variant="h6" color="primary">
                  {service.price.toFixed(2)} €
                </Typography>
              </Grid>
            </StyledCardContent>
          </>
        }
      />
      {servicesSessions && servicesSessions[service.id] && (
        <StyledTextField
          fullWidth
          type="number"
          min="0"
          step="0"
          pattern="[0-9]"
          onChange={(event) => updateSessionNumber(event, service)}
          variant="filled"
          name="sessions[]"
          value={servicesSessions[service.id] || 1}
          label="Nº sessões"
        />
      )}
    </Grid>
  )

  if (!servicePack) {
    return <LoadingPage />
  }

  return (
    <MuiContainer maxWidth="lg" style={{ marginTop: 20 }}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container alignItems="center" style={{ marginBottom: 15 }}>
          <IconButton onClick={() => history.goBack()} aria-label="go-back">
            <LeftArrowAlt size={30} />
          </IconButton>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 600, marginLeft: 10 }}
          >
            Editar pacote de serviços
          </Typography>
          {!formState.isSubmitting && (
            <FloatingBtn
              color="primary"
              type="submit"
              disabled={formState.isSubmitting || loading}
            >
              <Save size={30} />
            </FloatingBtn>
          )}
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={formState.isSubmitting || loading}
            endIcon={<Save size={22} />}
          >
            {loading ? <CircularProgress size={22} /> : 'Guardar'}
          </SubmitButton>
        </Grid>
        <Container container direction="column" spacing={3}>
          <Grid item xs={12} md={8}>
            <CoverWrapper>
              {cover.preview.length > 0 && (
                <img src={cover.preview} alt="Capa do pacote de serviços" />
              )}
              {cover.preview.length === 0 && servicePack.image_url && (
                <img src={servicePack.image_url} alt="Capa do pacote de serviços" />
              )}
            </CoverWrapper>
            <label htmlFor="files">
              <input
                hidden
                id="files"
                name="files"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              />
              <UploadButton
                variant="outlined"
                color="primary"
                component="span"
                fullWidth
                style={{ marginBottom: 20 }}
                startIcon={<Upload size={25} />}
              >
                Carregar nova capa
              </UploadButton>
            </label>
            <StyledDivider variant="middle" />
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.name}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                name="name"
                label="Nome"
                inputRef={register(validations.name)}
                error={!!errors.name}
                helpertext={errors.name && errors.name.message}
              />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.description}
            >
              <StyledTextField
                fullWidth
                variant="filled"
                multiline
                rows={2}
                rowsMax={4}
                name="description"
                label="Descrição"
                placeholder="Pequeno resumo do pacote de serviços que aparecerá na página de detalhes do pacote e nas redes sociais"
                inputRef={register(validations.description)}
                error={!!errors.description}
                helperText={
                  description &&
                  `${
                    255 - description.length >= 0
                      ? `${255 - description.length} caracteres restantes`
                      : ''
                  }`
                }
              />
              {errors.description && (
                <FormHelperText>{errors.description.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="column">
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.price}
              >
                <StyledTextField
                  fullWidth
                  variant="filled"
                  name="price"
                  type="number"
                  inputProps={{ min: 1, step: 'any' }}
                  label="Preço do pacote (€)"
                  inputRef={register(validations.price)}
                  error={!!errors.price}
                  helpertext={errors.price && errors.price.message}
                />
                {errors.price && <FormHelperText>{errors.price.message}</FormHelperText>}
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.keywords}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Palavras-Chave
                  </Typography>
                </FormLabel>
                <StyledAutocomplete
                  multiple
                  options={[]}
                  value={selectedKeywords}
                  onChange={(e, values) => setSelectedKeywords(values)}
                  freeSolo
                  noOptionsText="Sem palavras-chave"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Insere uma palavra e carrega em Enter"
                      variant="filled"
                      name="keywords"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                error={!!errors.expires_at}
              >
                <FormLabel>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: 600 }}
                  >
                    Data de expiração
                  </Typography>
                </FormLabel>
                <DateTimePicker
                  autoOk
                  hideTabs
                  okLabel=""
                  disablePast
                  label="Data de expiração"
                  ampm={false}
                  cancelLabel=""
                  name="expires_at"
                  inputVariant="filled"
                  format="DD/MM/YYYY HH:mm"
                  error={!!errors.expires_at}
                  DialogProps={{ fullWidth: fullScreen }}
                  value={expiresAt}
                  onChange={(date) => setValue('expires_at', date)}
                  helperText={errors.expires_at && errors.expires_at.message}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                      name="status"
                    />
                  }
                  labelPlacement="end"
                  label="Ativar"
                />
              </FormControl>
              <DeleteBtn
                onClick={handleDelete}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
              >
                Eliminar pacote
              </DeleteBtn>
            </Grid>
          </Grid>
        </Container>
      </form>
      <Grid container direction="column" style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6" color="textSecondary">
              Escolhe os serviços
            </Typography>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginRight: 10 }}
              >
                Preço total:
              </Typography>
              <Typography variant="h6" color="primary">
                {Number(totalPrice).toFixed(2)} €
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            {services && services.map((service, i) => renderServiceCard(service, i))}
            {services.length === 0 && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h6">
                  A carregar os serviços disponíveis...
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MuiContainer>
  )
}

export default EditServicePack
