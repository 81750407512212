import React, { useEffect, useState } from 'react'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { api } from 'api'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Tooltip, ResponsiveContainer, YAxis, AreaChart, XAxis, Area } from 'recharts'

import { StyledCard } from './styles'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0]
    const columnName = data?.payload?.name

    return (
      <StyledCard
        style={{ height: 'auto', flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <Typography
          variant="subtitle2"
          style={{
            textTransform: 'capitalize',
            borderBottom: '1px solid',
            marginBottom: 5,
          }}
        >
          {moment(columnName, 'MMM').format('MMMM')}
        </Typography>
        <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>
          {(data?.value || 0).toFixed(2)} €
        </Typography>
      </StyledCard>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const Dot = ({ cx, cy, stroke, r, strokeWidth }) => {
  return (
    <svg>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={stroke}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

Dot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  r: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
}

const EarnedByMonth = ({ year }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get(`/appointments/earned?year=${year}`).then(({ data: resp }) => {
      setLoading(false)
      setData(resp)
    })
  }, [year])

  return (
    <StyledCard container direction="row">
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography variant="subtitle1" gutterBottom>
          Ganhos por mês
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '100%', paddingBottom: 10 }}>
        {loading && <CircularProgress style={{ marginTop: 20 }} />}
        {!loading && (
          <ResponsiveContainer width="100%" height={450}>
            <AreaChart data={data}>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} width={25} />
              <Tooltip content={<CustomTooltip />} cursor={{ opacity: 0.1 }} />
              <Area
                key="name"
                dot={<Dot />}
                type="monotone"
                fill="#89AB1580"
                stroke="#89AB15"
                dataKey="earned"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </StyledCard>
  )
}

EarnedByMonth.propTypes = {
  year: PropTypes.number,
}

export default EarnedByMonth
