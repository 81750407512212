import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button, Grid } from '@material-ui/core'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { DocumentText } from '@styled-icons/ionicons-solid/DocumentText'
import { api } from 'api'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import {
  StyledTextField,
  UserRecordTitle,
  StyledTextArea,
  UploadButton,
  StyledPaper,
  DeleteFileBtn,
  ImgLabel,
} from './styles'

const UserRecord = ({ appointment, setAppointment }) => {
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
  })
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (appointment && appointment.user_record_id) {
      reset({
        weight: `${appointment.userRecord?.weight || ''}`,
        height: `${appointment.userRecord?.height || ''}`,
        hip_width: `${appointment.userRecord?.hip_width || ''}`,
        belly_width: `${appointment.userRecord?.belly_width || ''}`,
        thigh_width: `${appointment.userRecord?.thigh_width || ''}`,
        observations: `${appointment.userRecord?.observations || ''}`,
      })
    }
  }, [appointment, reset])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let uploads = Array.from(event.target.files)
      event.target.value = ''
      let newArray = Array.from(files)

      uploads.forEach((file) => {
        let reader = new FileReader()
        reader.onloadend = () => {
          const exists = files.filter((item) => item.file.name === file.name)
          if (exists.length <= 0) {
            newArray = [...newArray, { file, preview: reader.result }]
            setFiles(newArray)
          }
        }
        reader.readAsDataURL(file)
      })
    }
  }

  const removeFile = (index) => {
    const newArray = Array.from(files)
    newArray.splice(index, 1)
    setFiles(newArray)
  }

  const removeRemoteFile = (fileId) => {
    api
      .delete(
        `/user/${appointment.user.id}/records/${appointment.user_record_id}/files/${fileId}`
      )
      .then(({ data }) => {
        const clonedAppt = { ...appointment }
        clonedAppt.userRecord.files = data.files
        setAppointment(clonedAppt)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const onSubmit = (data) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (value.trim().length > 0) {
        formData.append(key, value)
      } else if (appointment.userRecord && appointment.userRecord[key]) {
        formData.append(key, appointment.userRecord[key])
      }
    }

    if (Array.from(formData.entries()).length > 0 || files.length > 0) {
      files.forEach((file) => formData.append('files[]', file.file))
      formData.append('appointment_id', appointment.id)

      if (appointment.user_record_id) {
        // update
        api
          .patch(
            `/user/${appointment.user.id}/records/${appointment.user_record_id}`,
            formData
          )
          .then(() => {
            window.location.reload()
          })
          .catch((e) => {
            const message = parseErrorMessage(e)
            actions.notification.setNotification('error', message)
          })
      } else {
        // create new user record
        api
          .post(`/user/${appointment.user.id}/records`, formData)
          .then(() => {
            window.location.reload()
          })
          .catch((e) => {
            const message = parseErrorMessage(e)
            actions.notification.setNotification('error', message)
          })
      }
    }
  }

  return (
    <>
      <UserRecordTitle variant="h5" color="secondary">
        Registo do cliente
      </UserRecordTitle>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledTextField
              fullWidth
              type="number"
              inputProps={{ min: 1, step: 'any' }}
              variant="filled"
              inputRef={register}
              name="weight"
              label="Peso (kg)"
              placeholder={`${appointment.userRecord?.weight || ''}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledTextField
              fullWidth
              type="number"
              inputProps={{ min: 1, step: 'any' }}
              variant="filled"
              inputRef={register}
              name="height"
              label="Altura (cm)"
              placeholder={`${appointment.userRecord?.height || ''}`}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledTextField
              fullWidth
              type="number"
              inputProps={{ min: 1, step: 'any' }}
              variant="filled"
              inputRef={register}
              name="hip_width"
              label="Anca (cm)"
              placeholder={`${appointment.userRecord?.hip_width || ''}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledTextField
              fullWidth
              type="number"
              inputProps={{ min: 1, step: 'any' }}
              variant="filled"
              inputRef={register}
              name="belly_width"
              label="Barriga (cm)"
              placeholder={`${appointment.userRecord?.belly_width || ''}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledTextField
              fullWidth
              type="number"
              inputProps={{ min: 1, step: 'any' }}
              variant="filled"
              inputRef={register}
              name="thigh_width"
              label="Coxa (cm)"
              placeholder={`${appointment.userRecord?.thigh_width || ''}`}
            />
          </Grid>
        </Grid>
        <StyledTextArea
          variant="filled"
          multiline
          inputRef={register}
          name="observations"
          label="Observações"
          placeholder={`${appointment.userRecord?.observations || ''}`}
        />
        <label htmlFor="files">
          <input
            hidden
            id="files"
            name="files[]"
            type="file"
            accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.doc,.docx"
            multiple
            onChange={handleFileUpload}
          />
          <UploadButton
            variant="outlined"
            color="primary"
            component="span"
            fullWidth
            startIcon={<Upload size={25} />}
          >
            Carregar ficheiros
          </UploadButton>
        </label>
        <Grid container spacing={2} alignItems="stretch">
          {appointment.userRecord?.files.map((file, i) => (
            <Grid key={file.file} item xs={6} sm={4} lg={3}>
              <StyledPaper variant="outlined">
                {['jpeg', 'jpg', 'png'].some((term) => file.file.includes(term)) ? (
                  <img src={file.file_url} alt={file.file} />
                ) : (
                  <DocumentText size={60} />
                )}
              </StyledPaper>
              <DeleteFileBtn
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => removeRemoteFile(file.id)}
              >
                Remover
              </DeleteFileBtn>
            </Grid>
          ))}
          {files.map((file, i) => (
            <Grid key={file.file.name} item xs={6} sm={4} lg={3}>
              <StyledPaper variant="outlined">
                {file.file.type.includes('image') ? (
                  <img src={file.preview} alt={file.file.name} />
                ) : (
                  <DocumentText size={60} />
                )}
                <ImgLabel>
                  <Box component="div" mt={1} overflow="hidden" textOverflow="ellipsis">
                    {file.file.name}
                  </Box>
                </ImgLabel>
              </StyledPaper>
              <DeleteFileBtn
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => removeFile(i)}
              >
                Remover
              </DeleteFileBtn>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={
            formState.isSubmitting ||
            (Object.entries(formState.dirtyFields).length <= 0 && files.length <= 0)
          }
          style={{ height: 40, marginLeft: 'auto', marginTop: 15 }}
        >
          Guardar
        </Button>
      </form>
    </>
  )
}

UserRecord.propTypes = {
  appointment: PropTypes.object.isRequired,
  setAppointment: PropTypes.func.isRequired,
}

export default UserRecord
