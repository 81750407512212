import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Container, Grid, useTheme } from '@material-ui/core'
import { ReactComponent as NotFoundDark } from 'assets/notfound-dark.svg'
import { ReactComponent as NotFound } from 'assets/notfound.svg'

const NoMatch = () => {
  const theme = useTheme()

  return (
    <Container maxWidth="lg" style={{ height: '100vh' }}>
      <Grid container justify="center">
        <Grid item xs={10} style={{ flex: 1 }}>
          {theme.palette.type === 'dark' ? (
            <NotFoundDark style={{ width: '100%', height: '100%', marginBottom: 150 }} />
          ) : (
            <NotFound style={{ width: '100%', height: '100%', marginBottom: 150 }} />
          )}
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={6} md={4} lg={3} style={{ flex: 1 }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              style={{ height: 54 }}
            >
              Início
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NoMatch
