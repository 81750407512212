import React from 'react'

import { CardActionArea } from '@material-ui/core'
import PropTypes from 'prop-types'

import { StyledCard, Wrapper, CheckedIcon, Direction } from './styles'

const RadioCard = ({ selected, onClick, direction, actionArea, actions, custom }) => (
  <Wrapper style={custom}>
    <StyledCard selected={selected}>
      {selected && <CheckedIcon />}
      <CardActionArea style={{ height: '100%' }} disableRipple onClick={onClick}>
        <Direction direction={direction}>{actionArea}</Direction>
      </CardActionArea>
      {actions}
    </StyledCard>
  </Wrapper>
)

RadioCard.propTypes = {
  selected: PropTypes.bool,
  direction: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  moreInfo: PropTypes.func,
  actionArea: PropTypes.node.isRequired,
  actions: PropTypes.node,
  custom: PropTypes.object,
}

RadioCard.defaultProps = {
  selected: false,
  direction: 'column',
  actions: null,
  moreInfo: null,
  custom: null,
}

export default RadioCard
