import { Grid, Typography } from '@material-ui/core'
import { ReactComponent as NoAppts } from 'assets/no-appts.svg'
import styled from 'styled-components'

export const DayName = styled(Typography)`
  opacity: 0.7;
  line-height: 1;
  color: ${({ theme }) => theme.palette.menu.text};
`

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  text-align: left;
  display: flex;
  min-height: 80px;
  border-radius: 10px;

  ${({ appointment, theme }) =>
    appointment === 'true' &&
    `
      border: 1.5px solid ${theme.palette.primary.main};
      transition: background-color 200ms ease;
      cursor: pointer;
      margin-bottom: 20px;
      
      &:hover {
        background-color: ${theme.palette.primary.background};
      }
    `}
`

export const NextApptsGrid = styled(Grid)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  padding-top: 20px;
  max-height: 320px;
  overflow-y: auto;
`

export const NoApptsImg = styled(NoAppts)`
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
`
