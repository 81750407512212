import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme, useMediaQuery } from '@material-ui/core'

import Header from './Header'
import List from './List'
import MakeAppointment from './MakeAppointment'
import { Container } from './styles'

const Menu = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()

  useEffect(() => {
    if (matches && open) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Container>
      <Header mobile={matches} onClick={() => setOpen((prev) => !prev)} />
      {!matches && <MakeAppointment />}
      <List mobile={matches} open={open} setOpen={setOpen} />
    </Container>
  )
}
export default Menu
