import React from 'react'
import { useHistory } from 'react-router-dom'

import { Typography, useTheme } from '@material-ui/core'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import moment from 'moment'
import PropTypes from 'prop-types'

import { TimelineWrapper } from './styles'

const Timeline = ({ appointments }) => {
  const history = useHistory()
  const theme = useTheme()

  return (
    <TimelineWrapper>
      {appointments.map((appointment, index) => (
        <li
          key={index}
          onClick={() => history.push(`/appointments/${appointment.id}/info`)}
        >
          <div
            className="bullet"
            style={{ backgroundColor: parseApptStatusColor(appointment.status, theme) }}
          />
          <div className="time">
            <p style={{ textTransform: 'capitalize' }}>
              {moment(appointment.date).format('D MMM YY')}
            </p>
            <span>{moment(appointment.start_time, 'HH:mm:ss').format('HH:mm')}</span>
          </div>
          <div className="desc">
            <Typography variant="subtitle2">{appointment.service.name}</Typography>
          </div>
        </li>
      ))}
    </TimelineWrapper>
  )
}

Timeline.propTypes = {
  appointments: PropTypes.array,
}
Timeline.defaultProps = {
  appointments: [],
}

export default Timeline
