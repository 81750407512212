import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Typography, Grid, Container } from '@material-ui/core'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import Appointments from 'components/Users/Details/Appointments'
import User from 'components/Users/Details/User'
import Vouchers from 'components/Users/Details/Vouchers'

import {
  UserContainer,
  ListingsGrid,
  UserInfoGrid,
  GoBackDesktop,
  GoBackMobile,
} from './styles'

const UserInfo = () => {
  const { id } = useParams()
  const history = useHistory()
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (id) {
      api
        .get(`/users/${id}`)
        .then(({ data }) => setUser(data))
        .catch((e) => history.push(`/${e.response.status}`))
    }
  }, [id, history])

  if (!user) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container spacing={4}>
        <ListingsGrid item xs={12} md={9}>
          <Grid container direction="column">
            <GoBackDesktop
              edge="start"
              onClick={() => history.goBack()}
              aria-label="go-back"
            >
              <LeftArrowAlt size={30} />
            </GoBackDesktop>
            <Typography variant="h5" color="primary" style={{ marginBottom: 20 }}>
              Marcações de {user.name === 'Anapconvidado' ? 'Convidado' : user.name}
            </Typography>
            <Appointments userId={user.id} appointments={user.appointments} />
            <Typography variant="h5" color="primary" style={{ margin: '30px 0 20px 0' }}>
              Vouchers de {user.name === 'Anapconvidado' ? 'Convidado' : user.name}
            </Typography>
            <Vouchers userId={user.id} vouchers={user.vouchers} />
          </Grid>
        </ListingsGrid>
        <UserInfoGrid item xs={12} md={3}>
          <UserContainer container direction="column" justify="center">
            <GoBackMobile
              edge="start"
              onClick={() => history.goBack()}
              aria-label="go-back"
            >
              <LeftArrowAlt size={30} />
            </GoBackMobile>
            <User user={user} />
          </UserContainer>
        </UserInfoGrid>
      </Grid>
    </Container>
  )
}

export default UserInfo
