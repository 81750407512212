import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import EditServicePack from './Info'
import ServicePacksList from './List'
import NewServicePack from './New'

const ServicePacks = () => (
  <Switch>
    <Route path="/service-packs/new">
      <NewServicePack />
    </Route>
    <Route path="/service-packs/:id/info">
      <EditServicePack />
    </Route>
    <Route exact path="/service-packs">
      <ServicePacksList />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
)

export default ServicePacks
