import {
  Grid,
  CardActions,
  Chip,
  Typography,
  Avatar,
  Card,
  Button,
} from '@material-ui/core'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  width: 100%;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
`

export const AvatarGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding-right: 0px;
`

export const StyledAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(6)}px`};
  height: ${({ theme }) => `${theme.spacing(6)}px`};
  color: ${({ theme }) => theme.palette.background.default};
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const AvatarName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StatusAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(5)}px`};
  height: ${({ theme }) => `${theme.spacing(5)}px`};

  &.success {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.background};
  }

  &.pending {
    color: ${({ theme }) => theme.palette.warning.main};
    background-color: ${({ theme }) => theme.palette.warning.background};
  }
`

export const CreatedAtLabel = styled(Typography)`
  opacity: 0.7;
  margin-right: 5px;
  font-size: 0.9rem;
`

export const CreatedAtDate = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  text-transform: capitalize;
`

export const UserName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  font-size: 1.15rem;
`

export const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.background.default};
  transition: none;
  padding: 20px 15px;
  border-radius: 20px;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & span {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.menu.text};
  }
`

export const StyledCardActions = styled(CardActions)`
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
`

export const TimelineGrid = styled(Grid)`
  padding: 5px 15px;
  height: 160px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`

export const SeeMoreButton = styled(Button)`
  flex: 1;
  padding: 15px 0;
  border-radius: 0 0 0 3px;
`
