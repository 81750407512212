import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, Typography } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import parseName from 'helpers/parse-name'
import parseVoucherStatus from 'helpers/parse-voucher-status'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import { StyledChip, StyledToggleButtonGroup } from './styles'

const Vouchers = ({ userId, vouchers }) => {
  const history = useHistory()
  const [view, setView] = useState('bought')

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  const columns = [
    {
      name: 'created_at',
      label: 'Criado a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1">
              {moment(vouchers[view][dataIndex].created_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'DD/MM/YYYY'
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(vouchers[view][dataIndex].created_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'HH:mm'
              )}
            </Typography>
          </>
        ),
      },
    },
    {
      name: 'used_at',
      label: 'Usado a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          vouchers[view][dataIndex].used_at ? (
            <>
              <Typography variant="body1">
                {moment(vouchers[view][dataIndex].used_at, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD/MM/YYYY'
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {moment(vouchers[view][dataIndex].used_at, 'YYYY-MM-DDTHH:mm:ss').format(
                  'HH:mm'
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">Não usado</Typography>
          ),
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <StyledChip
            status={vouchers[view][dataIndex].status}
            label={parseVoucherStatus(vouchers[view][dataIndex].status)}
          />
        ),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return obj1.data > obj2.data
              ? order === 'asc'
                ? 1
                : -1
              : order === 'asc'
              ? -1
              : 1
          }
        },
      },
    },
    {
      name: view === 'bought' ? 'receiver_name' : 'buyer_name',
      label: view === 'bought' ? 'Para' : 'De',
      options: {
        sort: false,
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          parseName(
            vouchers[view][dataIndex][view === 'bought' ? 'receiver_name' : 'buyer_name']
          ),
      },
    },
    {
      name: 'expires_at',
      label: 'Expira a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1">
              {moment(vouchers[view][dataIndex].expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'DD/MM/YYYY'
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(vouchers[view][dataIndex].expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'HH:mm'
              )}
            </Typography>
          </>
        ),
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    customSearchRender: () => null,
    customSearch: (searchQuery, currentRow, columns) =>
      currentRow[0].name.toLowerCase().includes(searchQuery.toLowerCase()),
    viewColumns: false,
    onRowClick: (rowData, rowMeta) =>
      history.push(`/vouchers/${vouchers[view][rowMeta.dataIndex].id}/info`),
    sortOrder: { name: 'created_at', direction: 'desc' },
    rowsPerPageOptions: [10, 20, 50],
    selectableRows: 'none',
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: 'Não foram encontrados resultados',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Descarregar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Ver Colunas',
        titleAria: 'Ver/Esconder Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar linhas selecionadas',
      },
    },
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="flex-end" alignItems="center">
        <Grid item>
          <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="change-view"
          >
            <ToggleButton value="bought" aria-label="bought">
              <Typography variant="subtitle2">Oferecidos</Typography>
            </ToggleButton>
            <ToggleButton value="received" aria-label="received">
              <Typography variant="subtitle2">Recebidos</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      <MUIDataTable data={vouchers[view]} columns={columns} options={options} />
    </div>
  )
}

Vouchers.propTypes = {
  userId: PropTypes.number.isRequired,
  vouchers: PropTypes.object,
}

Vouchers.defaultProps = {
  vouchers: { bought: [], received: [] },
}

export default Vouchers
