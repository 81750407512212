export default {
  global: {
    theme: 'light',
    inbox_count: 0,
    testimonials_count: 0,
    vouchers_count: 0,
  },
  auth: {
    token: null,
  },
  notification: null,
}
