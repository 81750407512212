import React from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import { Spa } from '@styled-icons/material-twotone/Spa'
import styled from 'styled-components'

const StyledIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.background};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledIcon = () => (
  <StyledIconWrapper>
    <Spa size={45} />
  </StyledIconWrapper>
)

export const Well = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 0;
  border-radius: 3px;
`

export const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StyledButton = styled(Button)`
  height: 50px;
  padding: 8px 30px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: 20px;
    }
  `};
`

export const RecurrDate = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 3px;
  padding: 15px 20px;
  text-align: center;
  text-transform: capitalize;
`
