export default (status) => {
  switch (status) {
    case 'confirm-guest':
      return 'Pendente convidado'

    case 'pending':
      return 'Pendente'

    case 'confirmed':
      return 'Confirmado'

    case 'paid':
      return 'Pago'

    case 'finished':
      return 'Terminado'

    case 'canceled':
      return 'Cancelado'

    case 'expired':
      return 'Expirado'

    case 'absent':
      return 'Falta'

    default:
      return 'Inválido'
  }
}
