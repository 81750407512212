import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const SubmitButtonWrapper = styled(Grid)`
  ${({ theme }) => `
    flex: 0;
    ${theme.breakpoints.down('xs')} {
        padding: 0 24px;
        width: 100%;
        left: 0;
        background-color: ${theme.palette.background.paper};
        height: 75px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: fixed;
        top: auto;
        bottom: 0;
        z-index: 10;
        box-shadow: 0px -10px 20px rgba(137, 171, 21, 0.05);
      }
    `};
`
