import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .ql-snow': {
      border: 'none !important',
      fontSize: '1rem',
    },
    '& .ql-snow .ql-stroke': {
      stroke: theme.palette.text.secondary,
    },
    '& .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill': {
      fill: theme.palette.text.secondary,
    },
    '& .ql-editor.ql-blank::before': {
      color: theme.palette.text.secondary,
    },
  },
}))

const Editor = ({ defaultText, name, setValue }) => {
  const classes = useStyles()
  const [text, setText] = useState(defaultText)

  const modules = {
    toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }], ['link', 'image']],
  }

  const formats = ['bold', 'italic', 'underline', 'list', 'link', 'image']

  return (
    <ReactQuill
      theme="snow"
      placeholder="Responde a este contacto por aqui"
      modules={modules}
      formats={formats}
      value={text}
      className={classes.editor}
      onChange={(val) => {
        setValue(name, val)
        setText(val)
      }}
    />
  )
}

Editor.propTypes = {
  defaultText: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

Editor.defaultProps = {
  defaultText: '',
}

export default Editor
