import React from 'react'

import { Grid } from '@material-ui/core'

import { StyledContainerCard } from './styles'

const LoadingStatistics = () => {
  return (
    <Grid
      container
      justify="space-evenly"
      style={{ margin: '30px 0', padding: '0 20px' }}
    >
      <StyledContainerCard container justify="space-between" item xs={12} sm={6} md={4} />
      <StyledContainerCard container alignItems="stretch" item xs={12} sm={5} md={3} />
      <StyledContainerCard
        container
        direction="column"
        justify="space-around"
        item
        xs={12}
        sm={6}
        md={4}
        style={{ borderRadius: 3, height: 130, alignItems: 'flex-start' }}
      />
    </Grid>
  )
}

export default LoadingStatistics
