// State constants
export const LOGIN_OK = 'LOGIN_OK'
export const LOGOUT = 'LOGOUT'
export const SET_THEME = 'SET_THEME'

export const SET_INBOX_COUNT = 'SET_INBOX_COUNT'
export const SET_TESTIMONIALS_COUNT = 'SET_TESTIMONIALS_COUNT'
export const SET_VOUCHERS_COUNT = 'SET_VOUCHERS_COUNT'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

// Storage constants
export const STORAGE_THEME = '@app_theme'
export const AUTH_TOKEN = '@auth_token'
