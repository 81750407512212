import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'

import {
  Button,
  Container,
  DialogContent,
  Grid,
  Link as MuiLink,
  Typography,
} from '@material-ui/core'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { Wallet } from '@styled-icons/boxicons-regular/Wallet'
import { Tools } from '@styled-icons/entypo/Tools'
import { Gift } from '@styled-icons/evaicons-solid/Gift'
import { Service } from '@styled-icons/remix-line/Service'
import { api } from 'api'
import CanceledInfo from 'components/Appointments/Details/CanceledInfo'
import EditDate from 'components/Appointments/Details/EditDate'
import EditStatus from 'components/Appointments/Details/EditStatus'
import UserRecord from 'components/Appointments/Details/UserRecord'
import Dialog from 'components/shared/Dialog'
import LoadingPage from 'components/shared/LoadingPage'
import parseApptStatus from 'helpers/parse-appt-status'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { StateContext } from 'state'

import {
  CalendarIcon,
  RightColumn,
  StyledCard,
  StyledCardContent,
  StyledChip,
  StyledIcon,
  StyledLink,
  UserAvatar,
  LabelGrid,
  LabelWrapper,
} from './styles'

const parsePaymentMethod = (method) => {
  switch (method) {
    case 'cash':
      return 'Dinheiro'
    case 'card':
      return 'Cartão'
    case 'transfer':
      return 'Transferência'
    case 'revolut':
      return 'Revolut'
    case 'mbway':
      return 'MBWay'
    default:
      return ''
  }
}

const AppointmentInfo = () => {
  const { id } = useParams()
  const { actions } = useContext(StateContext)
  const [appointment, setAppointment] = useState(null)
  const [dialog, setDialog] = useState('')
  const [date, setDate] = useState('')
  const [status, setStatus] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [canceledReason, setCanceledReason] = useState('')
  const [canceledSource, setCanceledSource] = useState('')

  useEffect(() => {
    if (id) {
      api.get(`/appointments/${id}/show-admin`).then(({ data }) => {
        setAppointment(data)
        setStatus(data.status)
        if (data.status === 'paid') {
          setPaymentMethod(data.payment_method)
        }
      })
    }
  }, [id])

  const handleCloseDialog = () => {
    setStatus(appointment.status)
    setPaymentMethod('')
    setCanceledReason('')
    setCanceledSource('')
    setDate('')
    setDialog('')
  }

  const handleSaveDate = () => {
    const dateSplit = date.split(' ')
    if (dateSplit.length !== 2) return

    api
      .patch(`/appointments/${id}`, {
        status: appointment.status,
        date: dateSplit[0],
        start_time: dateSplit[1],
      })
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const handleSaveStatus = () => {
    if (status.trim().length <= 0) return
    let body = { status }
    if (status === 'paid') {
      if (paymentMethod.trim().length <= 0) return
      body = {
        ...body,
        payment_method: paymentMethod,
      }
    }
    if (status === 'canceled') {
      if (canceledSource.trim().length <= 0) return
      body = {
        ...body,
        cancel_reason: canceledReason,
        cancel_source: canceledSource,
      }
    }
    api
      .patch(`/appointments/${id}`, body)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  if (!appointment) {
    return <LoadingPage />
  }

  const isGuest = (appointment.user?.name || appointment.user_name) === 'Anapconvidado'
  const userName = isGuest
    ? appointment.user_name || 'Convidado'
    : appointment.user?.name || appointment.user_name

  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} md={4}>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ padding: '20px 25px', height: '100%' }}
          >
            <StyledCard variant="outlined">
              <StyledCardContent>
                <UserAvatar alt={userName} src={appointment.user?.photo_url}>
                  <Typography variant="h4">{`${userName[0]}${
                    userName.split(' ').length > 1 ? userName.split(' ')[1][0] : ''
                  }`}</Typography>
                </UserAvatar>
                <Typography variant="h5">{userName}</Typography>
                {isGuest && (
                  <Typography variant="subtitle1" color="textSecondary">
                    Convidado
                  </Typography>
                )}
                <Grid container justify="center" style={{ margin: '30px 0' }}>
                  {appointment.user?.phone && (
                    <Grid item xs={6}>
                      <Grid container direction="column" alignItems="center">
                        <StyledIcon type="phone" />
                        <MuiLink
                          style={{ wordBreak: 'break-word' }}
                          href={`tel:${appointment.user.phone}`}
                        >
                          <Typography variant="subtitle1">
                            {appointment.user.phone}
                          </Typography>
                        </MuiLink>
                      </Grid>
                    </Grid>
                  )}
                  {appointment.user?.email && (
                    <Grid item xs={12}>
                      <Grid container direction="column" alignItems="center">
                        <StyledIcon type="email" />
                        <MuiLink
                          style={{ wordBreak: 'break-word' }}
                          href={`mailto:${appointment.user?.email}`}
                        >
                          <Typography variant="subtitle1">
                            {appointment.user?.email}
                          </Typography>
                        </MuiLink>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {appointment.user && (
                  <StyledLink to={`/users/${appointment.user?.id}/info`}>
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      style={{ fontWeight: 500 }}
                    >
                      Ver perfil <ArrowRight style={{ marginLeft: 5 }} size={23} />
                    </Typography>
                  </StyledLink>
                )}
              </StyledCardContent>
            </StyledCard>
            <Grid style={{ textAlign: 'center' }}>
              <CalendarIcon />
              <Typography
                variant="h6"
                style={{ letterSpacing: '0.05rem', textTransform: 'capitalize' }}
                gutterBottom
              >
                {moment(appointment.date, 'YYYY-MM-DD HH:mm:ssZ').format('DD MMM YYYY')}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">{`${moment(
                appointment.start_time,
                'HH:mm:ss'
              ).format('HH:mm')} - ${moment(appointment.end_time, 'HH:mm:ss').format(
                'HH:mm'
              )}`}</Typography>
              {['pending', 'confirmed', 'paid'].includes(appointment.status) && (
                <Button
                  variant="text"
                  size="large"
                  color="primary"
                  startIcon={<Tools style={{ marginRight: 5 }} size={23} />}
                  onClick={() => setDialog('date')}
                  style={{ marginTop: 15 }}
                >
                  Alterar data
                </Button>
              )}
            </Grid>
            <StyledChip
              status={appointment.status}
              label={parseApptStatus(appointment.status)}
            />
            {['pending', 'confirmed', 'paid'].includes(appointment.status) && (
              <Button
                variant="text"
                size="large"
                color="primary"
                startIcon={<Tools style={{ marginRight: 5 }} size={23} />}
                onClick={() => setDialog('status')}
              >
                Alterar estado
              </Button>
            )}
          </Grid>
        </Grid>
        <RightColumn item xs={12} md={8}>
          <Grid container direction="column" style={{ padding: '20px 25px' }}>
            <Grid container justify="space-between">
              <LabelGrid item xs={12} sm={4} container alignItems="center">
                <LabelWrapper container justify="center" alignItems="center">
                  <Wallet size={28} />
                </LabelWrapper>
                <Grid>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500, lineHeight: 1, marginTop: 4 }}
                  >
                    {appointment.price} €
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {parsePaymentMethod(appointment.payment_method)}
                  </Typography>
                </Grid>
              </LabelGrid>
              {appointment.voucher && (
                <LabelGrid item xs={12} sm={7} container alignItems="center">
                  <LabelWrapper container justify="center" alignItems="center">
                    <Gift size={28} />
                  </LabelWrapper>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ marginRight: 8 }}
                  >
                    Oferecido por:
                  </Typography>
                  {appointment.voucher.buyer ? (
                    <MuiLink
                      component={Link}
                      to={`/users/${appointment.voucher.buyer.id}/info`}
                    >
                      <Typography variant="h6" style={{ fontWeight: 500 }}>
                        {appointment.voucher.buyer_name}
                      </Typography>
                    </MuiLink>
                  ) : (
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                      {appointment.voucher.buyer_name}
                    </Typography>
                  )}
                </LabelGrid>
              )}
            </Grid>
            <LabelGrid container justify="space-between" alignItems="center">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LabelWrapper container justify="center" alignItems="center">
                  <Service size={28} />
                </LabelWrapper>
                <Typography variant="h6" style={{ fontWeight: 500 }}>
                  {appointment.service.name}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  style={{ fontWeight: 500 }}
                >
                  Preço atual:
                </Typography>
                <Typography variant="h6" style={{ marginLeft: 5, fontWeight: 500 }}>
                  {appointment.service.price} €
                </Typography>
              </div>
            </LabelGrid>
            {(appointment.status === 'paid' || appointment.status === 'finished') &&
              appointment.user && (
                <UserRecord appointment={appointment} setAppointment={setAppointment} />
              )}
            {appointment.status === 'canceled' && appointment.canceled_at && (
              <CanceledInfo appointment={appointment} />
            )}
          </Grid>
        </RightColumn>
        <Dialog
          open={dialog.length > 0}
          handleClose={handleCloseDialog}
          title={
            dialog === 'date'
              ? 'Alterar data'
              : dialog === 'status'
              ? 'Alterar estado'
              : ''
          }
          onSave={dialog === 'date' ? handleSaveDate : handleSaveStatus}
        >
          <DialogContent>
            {dialog === 'date' ? (
              <EditDate
                selectedTime={date}
                setSelectedTime={setDate}
                serviceSlug={appointment.service.slug}
              />
            ) : (
              <EditStatus
                currentStatus={appointment.status}
                status={status}
                setStatus={setStatus}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                canceledReason={canceledReason}
                setCanceledReason={setCanceledReason}
                canceledSource={canceledSource}
                setCanceledSource={setCanceledSource}
              />
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </Container>
  )
}

export default AppointmentInfo
