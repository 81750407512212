import { Grid, Typography } from '@material-ui/core'
import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: white;
`

export const MaxWidth = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  text-align: center;
`

export const CoverBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33.3vh;
  overflow: hidden;

  & img {
    width: 100%;
  }
`

export const Area = styled.div`
  position: relative;
  width: 100%;
  height: 33.3vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CoverTitle = styled.img`
  width: 85%;
  margin-bottom: 10px;
`

export const CoverUsers = styled(Grid)`
  background-color: white;
  padding: 5px 10px;
  min-height: 50px;
  width: 85%;
  border: 1.5px solid #314f37;

  & > .MuiGrid-root {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`

export const UserLabel = styled(Typography)`
  color: #505050;
  margin-right: 5px;
  font-family: 'Quicksand';
  font-weight: 500;
`

export const Title = styled(Typography)`
  color: #000;
  font-family: 'Quicksand';
  font-weight 600;
  letter-spacing: 0.03rem;

  ${({ secondary }) =>
    secondary === 'true' &&
    `
    color: #314f37;
  `}
`

export const UserName = styled(Typography)`
  color: #000000;
  font-family: 'Quicksand';
  text-align: left;
`

export const Hr = styled.hr`
  width: 90px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  margin: 25px 0 40px 0;
`

export const Code = styled(Typography)`
  color: #000000;
  font-family: monospace;
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
`

export const InfoIcon = styled(InfoOutline)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 10px;
`

export const WarningTitle = styled(Typography)`
  font-weight: 600;
  font-family: 'Quicksand';
  color: #314f37;
`

export const WarningText = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 0.83rem;
`
