import React, { useContext, useEffect, useState } from 'react'
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd'
import { useHistory } from 'react-router-dom'

import {
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import TemplatesEditDialog from 'components/Vouchers/Templates/EditDialog'
import TemplatesNewDialog from 'components/Vouchers/Templates/NewDialog'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

import {
  UploadLabel,
  StyledTextField,
  StyledCardMedia,
  StyledCard,
  StyledCardContent,
  PlusIconWrapper,
  StyledChip,
} from './styles'

const VoucherTemplates = () => {
  const theme = useTheme()
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [templates, setTemplates] = useState(null)
  const [isSorted, setIsSorted] = useState(false)
  const [newFile, setNewFile] = useState(null)
  const [openNew, setOpenNew] = useState(false)
  const [editTemplate, setEditTemplate] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isPhone = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isExtraSm = useMediaQuery('(max-width:480px) and (min-width:390px)')
  const isSmaller = useMediaQuery('(max-width:390px)')

  useEffect(() => {
    api
      .get('/voucher-templates/list')
      .then(({ data }) => {
        setTemplates(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSorted && templates.length > 0) {
      const newTemplatesOrder = []
      templates.forEach((template, i) => {
        newTemplatesOrder.push({ id: template.id, order: i + 1 })
      })

      api
        .patch('/voucher-templates/order', { templates: newTemplatesOrder })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, isSorted])

  useEffect(() => {
    setOpenNew(!!newFile)
  }, [newFile])

  useEffect(() => {
    setOpenEdit(!!editTemplate)
  }, [editTemplate])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setNewFile({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const onSort = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(templates, sourceIndex, targetIndex)
    setTemplates(nextState)
    setIsSorted(true)
  }

  if (!templates) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ marginBottom: 15, gap: 10 }}
      >
        <Grid container alignItems="center" style={{ width: 'auto' }}>
          <IconButton onClick={() => history.goBack()} aria-label="go-back">
            <LeftArrowAlt size={30} />
          </IconButton>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 600, marginLeft: 10, flex: 1 }}
          >
            Templates de Vouchers
          </Typography>
        </Grid>
        <UploadLabel htmlFor="file">
          <input
            hidden
            id="file"
            name="file"
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleFileUpload}
          />
          <ButtonBase component="span" style={{ width: '100%', height: '100%' }}>
            <StyledCard variant="outlined" style={{ textAlign: 'center' }}>
              <StyledCardContent>
                <PlusIconWrapper container justify="center" alignItems="center">
                  <Plus size={25} />
                </PlusIconWrapper>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 500 }}>
                  Inserir Template
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </ButtonBase>
        </UploadLabel>
      </Grid>
      <GridContextProvider onChange={onSort}>
        <GridDropZone
          id="templates"
          boxesPerRow={isLg ? 3 : isTablet ? 2 : isXs ? 1 : 2}
          rowHeight={
            isLg
              ? 366
              : isTablet
              ? 486
              : isPhone
              ? 390
              : isExtraSm
              ? 390
              : isSmaller
              ? 340
              : 460
          }
          style={{ margin: -12, height: '100vh' }}
        >
          {templates.map((template, i) => (
            <GridItem key={template.id} style={{ padding: 12 }}>
              <Card variant="outlined" style={{ position: 'relative', cursor: 'move' }}>
                <CardActionArea
                  onClick={() => setEditTemplate(template)}
                  style={{ height: '100%' }}
                >
                  <StyledCardMedia image={template.image_url} title={template.title} />
                  <CardContent style={{ paddingBottom: 12 }}>
                    <StyledTextField
                      disabled
                      fullWidth
                      variant="filled"
                      name="title"
                      label="Título"
                      value={template.title}
                    />
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <StyledChip
                        status={template.status.toString()}
                        label={template.status ? 'Ativo' : 'Inativo'}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          color="secondary"
                          style={{ marginRight: 5 }}
                        >
                          <b>{template.vouchers}</b>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          vouchers
                        </Typography>
                      </div>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
      <TemplatesNewDialog open={openNew} setFile={setNewFile} file={newFile} />
      <TemplatesEditDialog
        open={openEdit}
        setTemplate={setEditTemplate}
        template={editTemplate}
      />
    </Container>
  )
}

export default VoucherTemplates
