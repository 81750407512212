import { Button, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  padding: 30px;
`

export const StyledButton = styled(Button)`
  padding: 13px 22px;

  & svg {
    opacity: 0.8;
  }
`
