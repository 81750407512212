import { SET_NOTIFICATION, REMOVE_NOTIFICATION } from 'state/constants'

export default (state, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return action.notification

    case REMOVE_NOTIFICATION:
      return null

    default:
      return state
  }
}
