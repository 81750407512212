import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { api } from 'api'

import { NewUsersGrid, StyledCard, NewUser } from './styles'

const NewUsers = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get('/users/latest').then(({ data }) => {
      setLoading(false)
      setUsers(data)
    })
  }, [])

  return (
    <StyledCard style={{ height: '100%' }}>
      <Grid container direction="column" alignItems="flex-start">
        <Typography variant="subtitle1">Últimos registos</Typography>
        {loading && <CircularProgress style={{ marginTop: 20 }} />}
        {!loading && (
          <NewUsersGrid>
            {users.map((user) => (
              <Link
                key={user.id}
                to={`/users/${user.id}/info`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <NewUser user={user} />
              </Link>
            ))}
          </NewUsersGrid>
        )}
      </Grid>
    </StyledCard>
  )
}

export default NewUsers
