import axios from 'axios'
import Cookies from 'js-cookie'
import { AUTH_TOKEN } from 'state/constants'

const baseURL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'pt',
  },
})

api.interceptors.request.use(
  (config) => {
    const { origin } = new URL(`${baseURL}${config.url}`)
    const allowedOrigins = [baseURL]
    const token = Cookies.get(AUTH_TOKEN)
    if (allowedOrigins.includes(origin) && config.url !== '/login-admin') {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const publicApi = axios.create({
  baseURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'pt',
  },
})

export { api, publicApi }
