import React, { useState } from 'react'

import 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/mobile'
import 'tinymce/themes/silver'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/skins/ui/oxide/skin.min.css'
import { useTheme } from '@material-ui/core'
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'

const Editor = ({ defaultText, error, name, setValue }) => {
  const theme = useTheme()
  const [text, setText] = useState(defaultText)

  const handleEditorChange = (content, editor) => {
    setText(content)
    setValue(name, content)
  }

  return (
    <Wrapper error={error.toString()}>
      <TinyMceEditor
        initialValue={defaultText}
        init={{
          skin: false,
          width: '100%',
          toolbar_sticky: true,
          max_height: 500,
          formats: {
            cite: { block: 'cite' },
          },
          style_formats: [
            { title: 'Paragraph', format: 'p' },
            { title: 'Title', format: 'h2' },
            { title: 'Cabeçalho', format: 'h4' },
            { title: 'Subtítulo', format: 'h5' },
            { title: 'Citação', format: 'blockquote' },
            { title: 'Autor da citação', format: 'cite' },
            { title: 'Code', format: 'code' },
          ],
          preview_styles: false,
          content_css: false,
          content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

            body {
              font-family: 'Roboto', sans-serif;
              max-width: 1280px;
              margin: .5rem auto;
              padding: .5rem;
              padding-left: 1rem !important;
              padding-right: 1rem !important;
              font-size: 1.15rem;
              color: #505050;
              line-height: 2rem;
            }

            h2 {
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              line-height: 2rem;
              font-size: 3.75rem;
              color: #000000;
            }
            h4 {
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              line-height: 2rem;
              font-size: 2.125rem;
              color: #000000;
            }
            h5 {
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              line-height: 2rem;
              font-size: 1.5rem;
              color: #000000;
            }

            blockquote {
              font-family: 'Quicksand', sans-serif;
              font-size: 1.8rem;
              font-weight: 600;
              letter-spacing: .03rem;
              position: relative;
              border: 0;
              padding: 0 0 0 1rem;
              margin: 2.5em 0;
              max-width: 100%;
              text-align: left;
              border-left: 4px solid ${theme.palette.primary.main};
            }

            blockquote cite {
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 1.1rem;
              color: ${theme.palette.grey[500]};
            }

            blockquote cite::before {
              display: inline-block;
              content: "";
              border-top: .15rem solid ${theme.palette.grey[500]};
              width: 1rem;
              margin-right: 0.5rem;
              transform: translateY(-0.2rem);
            }
            
            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
              border-radius: 5px;
            }
            
            p:has(+ iframe) {
              text-align: center;
            }

            iframe {
              border: none;
              width: 100%;
              height: 400px;
              object-fit: cover;
              border-radius: 5px;
            }

            a {
              text-decoration: none;
              color: ${theme.palette.primary.main};
              font-weight: 700;
            }

            hr {
              background-color: ${theme.palette.grey[600]};
              border: 3px solid ${theme.palette.grey[600]};
              width: 150px;
              margin: 40px auto;
            }
          `,
          language: 'pt_PT',
          statusbar: false,
          menubar: false,
          language_url: '/assets/tinymce/pt_PT.js',
          paste_block_drop: false,
          paste_as_text: true,
          mobile: true,
          images_upload_handler: function (blobInfo, success) {
            success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64())
          },
          plugins: ['autoresize', 'link', 'lists', 'image', 'media', 'hr', 'fullscreen'],
          toolbar:
            'undo redo | styleselect | bold italic underline strikethrough | forecolor | bullist numlist | alignleft aligncenter alignright | link image media | hr | removeformat | fullscreen',
        }}
        value={text}
        onEditorChange={handleEditorChange}
      />
    </Wrapper>
  )
}

Editor.propTypes = {
  defaultText: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

Editor.defaultProps = {
  error: false,
  defaultText: '',
}

export default Editor
