import { Typography } from '@material-ui/core'
import { ArrowDownShort } from '@styled-icons/bootstrap/ArrowDownShort'
import { ArrowUpShort } from '@styled-icons/bootstrap/ArrowUpShort'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import { ContainerCard } from 'pages/styles'
import styled from 'styled-components'

const parsePercentageColor = (theme, invert = 'false', percentage) => {
  if (percentage > 0 && percentage < 100) {
    return invert === 'true' ? theme.palette.success.main : theme.palette.error.main
  }
  if (percentage === 100) {
    return theme.palette.info.main
  }
  if (percentage > 100) {
    return invert === 'true' ? theme.palette.error.main : theme.palette.success.main
  }
  return theme.palette.text.primary
}

export const StyledContainerCard = styled(ContainerCard)`
  border-radius: 3px;
  height: 130px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 20px;
    }
  `};
`

export const DownStat = styled(ArrowDownShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.success.main : theme.palette.error.main};
  margin-left: 5px;
`

export const UpStat = styled(ArrowUpShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.error.main : theme.palette.success.main};
  margin-left: 5px;
`

export const Percentage = styled(Typography)`
  color: ${({ percentage, invert, theme }) =>
    parsePercentageColor(theme, invert, percentage)};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StatusDot = styled.div`
  background-color: ${({ status, theme }) => parseApptStatusColor(status, theme)};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
`
