export default (status) => {
  switch (status) {
    case 'pending':
      return 'Pendente'

    case 'paid':
      return 'Pago'

    case 'scheduled':
      return 'Agendado'

    case 'canceled':
      return 'Cancelado'

    default:
      return ''
  }
}
