import React from 'react'

import { Avatar, Grid, Typography } from '@material-ui/core'
import { History } from '@styled-icons/boxicons-regular/History'
import { Wallet } from '@styled-icons/boxicons-regular/Wallet'
import { Warning } from '@styled-icons/ionicons-outline/Warning'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const UserAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(15)}px`};
  height: ${({ theme }) => `${theme.spacing(15)}px`};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: ${({ theme }) => `4px solid ${theme.palette.primary.main}`};
  margin-bottom: 15px;
`

export const IconWrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.background.default};

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledIcon = ({ type }) => (
  <IconWrapper>
    {type === 'visits' && <History size={30} />}
    {type === 'earnings' && <Wallet size={30} />}
  </IconWrapper>
)
StyledIcon.propTypes = {
  type: PropTypes.string.isRequired,
}

const LabelWrapper = styled(Grid)``

const LabelText = styled(Typography)`
  ${({ warning, theme }) => `
    color: ${
      warning === 'true' ? theme.palette.warning.main : theme.palette.text.secondary
    };
  `}
`

const WarningIcon = styled(Warning)`
  color: ${({ theme }) => theme.palette.warning.main};
  margin-right: 5px;
`

export const LabelWithWarning = ({ label, warning }) => (
  <LabelWrapper container alignItems="center">
    {warning === 'true' && <WarningIcon size={18} />}
    <LabelText variant="subtitle2" warning={warning}>
      {label}
    </LabelText>
  </LabelWrapper>
)

LabelWithWarning.propTypes = {
  label: PropTypes.string.isRequired,
  warning: PropTypes.string,
}
LabelWithWarning.defaultProps = {
  warning: 'false',
}
