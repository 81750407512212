import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import EditService from './Info'
import ServicesList from './List'
import NewService from './New'

const Services = () => (
  <Switch>
    <Route path="/services/:id/info">
      <EditService />
    </Route>
    <Route path="/services/new">
      <NewService />
    </Route>
    <Route exact path="/services">
      <ServicesList />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
)

export default Services
