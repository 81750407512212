import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { Grid, IconButton, Typography, DialogContent } from '@material-ui/core'
import { api } from 'api'
import EditStatus from 'components/Appointments/Details/EditStatus'
import Dialog from 'components/shared/Dialog'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'
import { Flaky } from 'styled-icons/material'

import { StyledCard } from '../styles'
import { Dot, UserName, Time } from './styles'

const NextAppointment = ({ appointment }) => {
  const { actions } = useContext(StateContext)
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false)
  const [status, setStatus] = useState(appointment.status || '')
  const [paymentMethod, setPaymentMethod] = useState(appointment.payment_method || '')
  const [canceledReason, setCanceledReason] = useState(appointment.cancel_reason || '')
  const [canceledSource, setCanceledSource] = useState(appointment.cancel_source || '')

  const handleStatusClick = (e) => {
    e.preventDefault()
    setOpenStatusUpdate(true)
  }

  const handleCloseDialog = () => {
    setOpenStatusUpdate(false)
    setStatus(appointment.status)
    setPaymentMethod('')
    setCanceledReason('')
    setCanceledSource('')
  }

  const handleSaveStatus = () => {
    if (status.trim().length <= 0) return
    let body = { status }
    if (status === 'paid') {
      if (paymentMethod.trim().length <= 0) return
      body = {
        ...body,
        payment_method: paymentMethod,
      }
    }
    if (status === 'canceled') {
      if (canceledSource.trim().length <= 0) return
      body = {
        ...body,
        cancel_reason: canceledReason,
        cancel_source: canceledSource,
      }
    }
    api
      .patch(`/appointments/${appointment.id}`, body)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <>
      <Link
        to={`/appointments/${appointment.id}/info`}
        style={{ textDecoration: 'none' }}
      >
        <StyledCard appointment="true">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs="auto" style={{ marginRight: 20 }}>
              <Grid container alignItems="flex-start">
                <Grid item style={{ marginRight: 3 }}>
                  <Typography variant="h4" color="secondary">
                    {+appointment.start_time.split(':')[0]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="secondary">
                    {appointment.start_time.split(':')[1]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid container direction="column">
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  {appointment.service.name}
                </Typography>
                <UserName variant="subtitle2" color="secondary" gutterBottom>
                  {appointment.user?.name || appointment.user_name}
                </UserName>
                <Grid item>
                  <Grid container alignItems="center">
                    <Time variant="body2" color="secondary">
                      {`${appointment.start_time.slice(
                        0,
                        appointment.start_time.length - 3
                      )} - ${appointment.end_time.slice(
                        0,
                        appointment.end_time.length - 3
                      )}`}
                    </Time>
                    <Dot />
                    <Typography variant="body2" color="secondary">
                      {appointment.price} €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto" style={{ marginLeft: 10 }}>
              <IconButton size="small" onClick={handleStatusClick}>
                <Flaky size={30} />
              </IconButton>
            </Grid>
          </Grid>
        </StyledCard>
      </Link>
      <Dialog
        open={openStatusUpdate}
        handleClose={handleCloseDialog}
        title="Alterar estado"
        onSave={handleSaveStatus}
      >
        <DialogContent>
          <EditStatus
            currentStatus={appointment.status}
            status={status}
            setStatus={setStatus}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            canceledReason={canceledReason}
            setCanceledReason={setCanceledReason}
            canceledSource={canceledSource}
            setCanceledSource={setCanceledSource}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

NextAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
}

export default NextAppointment
