import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import { MenuAlt3 } from '@styled-icons/heroicons-solid/MenuAlt3'
import aptLogo from 'assets/logo.png'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import {
  LogoIcon,
  CompanyTitle,
  CompanySubtitle,
  SwitchDark,
  SwitchLight,
  Container,
  SwitchWrapper,
} from './styles'

const Header = ({ onClick, mobile }) => {
  const {
    state: {
      global: { theme: globalTheme },
    },
    actions,
  } = useContext(StateContext)

  return (
    <Container container direction="row" alignItems="center">
      <Link
        to="/"
        style={{ flex: 1, display: 'flex', alignItems: 'center', textDecoration: 'none' }}
      >
        <LogoIcon src={aptLogo} />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <CompanyTitle variant="h6" color="primary">
            Ana Pinto
          </CompanyTitle>
          <CompanySubtitle variant="subtitle2" color="secondary">
            Terapeuta
          </CompanySubtitle>
        </div>
      </Link>
      {globalTheme === 'light' && (
        <SwitchWrapper
          edge="end"
          onClick={() => actions.global.setTheme('dark')}
          aria-label="turn-dark-theme"
        >
          <SwitchLight size={23} />
        </SwitchWrapper>
      )}
      {globalTheme === 'dark' && (
        <SwitchWrapper
          edge="end"
          onClick={() => actions.global.setTheme('light')}
          aria-label="turn-light-theme"
        >
          <SwitchDark size={23} />
        </SwitchWrapper>
      )}
      {mobile && (
        <IconButton
          edge="end"
          onClick={onClick}
          aria-label="open-menu"
          style={{ marginLeft: 10 }}
        >
          <MenuAlt3 size={35} />
        </IconButton>
      )}
    </Container>
  )
}
Header.propTypes = {
  onClick: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
}

export default Header
