import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import { CreditCardFill } from '@styled-icons/bootstrap/CreditCardFill'
import { Bank } from '@styled-icons/remix-fill/Bank'
import { MoneyEuroCircle } from '@styled-icons/remix-fill/MoneyEuroCircle'
import MBWay from 'assets/icons/mbway.png'
import Revolut from 'assets/icons/revolut.png'
import parseApptStatus from 'helpers/parse-appt-status'
import PropTypes from 'prop-types'

import { StatusGrid, MethodGrid, LogoIcon, StyledTextField } from './styles'

const paymentMethods = ['cash', 'card', 'transfer', 'revolut', 'mbway']

const parsePaymentLabel = (method) => {
  switch (method) {
    case 'cash':
      return 'Dinheiro'
    case 'card':
      return 'Cartão'
    case 'transfer':
      return 'Transferência'
    case 'revolut':
      return 'Revolut'
    case 'mbway':
      return 'MBWay'
    default:
      return ''
  }
}

const parsePaymentIcon = (method) => {
  switch (method) {
    case 'cash':
      return <MoneyEuroCircle size={30} />
    case 'card':
      return <CreditCardFill size={30} />
    case 'transfer':
      return <Bank size={30} />
    case 'revolut':
      return <LogoIcon src={Revolut} />
    case 'mbway':
      return <LogoIcon src={MBWay} />
    default:
      return ''
  }
}

const statusMap = {
  pending: ['absent', 'canceled', 'confirmed'],
  confirmed: ['absent', 'canceled', 'paid'],
  paid: ['absent', 'canceled', 'finished'],
}

const EditStatus = ({
  currentStatus,
  status,
  setStatus,
  paymentMethod,
  setPaymentMethod,
  canceledReason,
  setCanceledReason,
  canceledSource,
  setCanceledSource,
}) => (
  <Grid container direction="column">
    <Grid container justify="center" style={{ marginBottom: 20 }}>
      <StatusGrid
        status={statusMap[currentStatus][0]}
        item
        style={{ marginRight: 5 }}
        active={(status === statusMap[currentStatus][0]).toString()}
        onClick={() => setStatus(statusMap[currentStatus][0])}
      >
        <Typography variant="subtitle2">
          {parseApptStatus(statusMap[currentStatus][0])}
        </Typography>
      </StatusGrid>
      <StatusGrid
        status={statusMap[currentStatus][1]}
        item
        style={{ marginRight: 5 }}
        active={(status === statusMap[currentStatus][1]).toString()}
        onClick={() => setStatus(statusMap[currentStatus][1])}
      >
        <Typography variant="subtitle2">
          {parseApptStatus(statusMap[currentStatus][1])}
        </Typography>
      </StatusGrid>
      <StatusGrid
        status={currentStatus}
        item
        style={{ marginRight: 5 }}
        active={(status === currentStatus).toString()}
        onClick={() => setStatus(currentStatus)}
      >
        <Typography variant="subtitle2">{parseApptStatus(currentStatus)}</Typography>
      </StatusGrid>
      <StatusGrid
        status={statusMap[currentStatus][2]}
        item
        active={(status === statusMap[currentStatus][2]).toString()}
        onClick={() => setStatus(statusMap[currentStatus][2])}
      >
        <Typography variant="subtitle2">
          {parseApptStatus(statusMap[currentStatus][2])}
        </Typography>
      </StatusGrid>
    </Grid>
    {status === 'paid' && (
      <>
        <Typography variant="subtitle1" style={{ fontWeight: 500, marginBottom: 10 }}>
          Método de pagamento
        </Typography>
        <Grid container spacing={2}>
          {paymentMethods.map((method) => (
            <Grid key={method} item xs={6} sm={4}>
              <MethodGrid
                active={(paymentMethod === method).toString()}
                item
                onClick={() => setPaymentMethod(method)}
              >
                {parsePaymentIcon(method)}
                <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
                  {parsePaymentLabel(method)}
                </Typography>
              </MethodGrid>
            </Grid>
          ))}
        </Grid>
      </>
    )}
    {status === 'canceled' && (
      <>
        <Typography variant="subtitle1" style={{ fontWeight: 500, marginBottom: 10 }}>
          Razão para o cancelamento
        </Typography>
        <StyledTextField
          variant="filled"
          multiline
          name="reason"
          label="Razão"
          value={canceledReason}
          autoFocus
          onChange={(e) => setCanceledReason(e.target.value)}
        />
        <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
          Via pela qual foi pedido o cancelamento
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: 15 }}>
          {['Chamada', 'Mensagem', 'Pessoalmente'].map((source) => (
            <Grid key={source} item xs={6} sm={4}>
              <MethodGrid
                active={(canceledSource === source).toString()}
                item
                style={{ justifyContent: 'center' }}
                onClick={() => setCanceledSource(source)}
              >
                <Typography variant="subtitle2">{source}</Typography>
              </MethodGrid>
            </Grid>
          ))}
          <Grid item xs={6}>
            <MethodGrid
              active={(canceledSource === 'Falta de confirmação').toString()}
              item
              style={{ justifyContent: 'center' }}
              onClick={() => setCanceledSource('Falta de confirmação')}
            >
              <Typography variant="subtitle2">Falta de confirmação</Typography>
            </MethodGrid>
          </Grid>
          <Grid item xs={6}>
            <MethodGrid
              active={(canceledSource === 'Rejeitado por Admin').toString()}
              item
              style={{ justifyContent: 'center' }}
              onClick={() => setCanceledSource('Rejeitado por Admin')}
            >
              <Typography variant="subtitle2">Rejeitado por Admin</Typography>
            </MethodGrid>
          </Grid>
        </Grid>
      </>
    )}
  </Grid>
)

EditStatus.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  canceledReason: PropTypes.string.isRequired,
  setCanceledReason: PropTypes.func.isRequired,
  canceledSource: PropTypes.string.isRequired,
  setCanceledSource: PropTypes.func.isRequired,
}

export default EditStatus
