import React, { useEffect, useContext } from 'react'

import 'moment/locale/pt'
import MomentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import Main from 'pages'
import { StateContext } from 'state'
import { ThemeProvider } from 'styled-components'
import dark from 'theme/dark'
import light from 'theme/light'

const App = () => {
  const { state, actions } = useContext(StateContext)

  useEffect(() => {
    actions.global.getTheme()
    actions.global.getInboxCount()
    actions.global.getTestimonialsCount()
    actions.global.getVouchersCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const themeConfig = responsiveFontSizes(
    createMuiTheme(state.global.theme === 'light' ? light : dark)
  )

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={themeConfig}>
        <ThemeProvider theme={themeConfig}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pt">
            <CssBaseline />
            <Main />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default App
