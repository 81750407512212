import ReactCodeInput from 'react-code-input'

import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 8px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

export const StyledReactCodeInput = styled(ReactCodeInput)`
  width: 100%;
  text-align: center;
  margin: 10px 0;
  & input {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.palette.primary.shadow};
    margin: 4px 6px;
    text-align: center;
    width: 42px;
    height: 46px;
    font-size: 28px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.default};
    font-weight: 500;

    &:focus {
      outline: none;
    }

    &[data-valid='false'] {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }
`
