import React from 'react'
import { Link } from 'react-router-dom'

import { Grid } from '@material-ui/core'
import { Plus } from '@styled-icons/boxicons-regular/Plus'

import { Container, StyledButton } from './styles'

const MakeAppointment = () => {
  return (
    <Container container justify="center">
      <Grid item xs={12}>
        <Link to="/appointments/new" style={{ textDecoration: 'none' }}>
          <StyledButton
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            startIcon={<Plus size={25} />}
          >
            Nova marcação
          </StyledButton>
        </Link>
      </Grid>
    </Container>
  )
}

export default MakeAppointment
