import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  height: 400px;

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      height: 320px;
    }
  `};
`

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;

  & h3,
  & h6 {
    color: white;
  }

  ${({ type, theme }) =>
    type === 'first'
      ? `
    background-color: #3D9F48;
  `
      : type === 'second'
      ? `
    background-color: #008C68;
  `
      : `
    background-color: #007777;
  `}
`
