import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Container, Fade, Grid } from '@material-ui/core'
import { ArrowBack } from '@styled-icons/ionicons-solid/ArrowBack'
import { ArrowForward } from '@styled-icons/ionicons-solid/ArrowForward'
import { api, publicApi } from 'api'
import Confirm from 'components/Appointments/New/Confirm'
import Date from 'components/Appointments/New/Date'
import Service from 'components/Appointments/New/Service'
import User from 'components/Appointments/New/User'
import LoadingPage from 'components/shared/LoadingPage'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import {
  ButtonsGrid,
  ContentGrid,
  StepsWrapper,
  StepWrapper,
  StyledLinearProgress,
  StyledTab,
  StyledTabs,
} from './styles'

const StepIcon = ({ step }) => <StepWrapper>{step}</StepWrapper>

StepIcon.propTypes = {
  step: PropTypes.number.isRequired,
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const NewAppointment = () => {
  const query = useQuery()
  const voucherCode = query.get('voucher')
  const { actions } = useContext(StateContext)
  const [activeTab, setActiveTab] = useState(0)
  const [users, setUsers] = useState([])
  const [services, setServices] = useState([])
  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const [repeat, setRepeat] = useState('')
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (!voucherCode) return

    setActiveTab(2)
    publicApi
      .get(`/vouchers/${voucherCode}`)
      .then(({ data }) => {
        setVoucher(data)
        setSelectedService(data.service)
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherCode])

  useEffect(() => {
    publicApi
      .get('/services')
      .then(({ data }) => {
        setServices(data)
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    api
      .get('/users?filter=all')
      .then(({ data }) => {
        setUsers(data)
        setLoading(false)
        if (query.get('user_id')) {
          const userId = +query.get('user_id')
          const user = data.find((user) => user.id === userId)
          if (user) {
            setSelectedUser(user)
            handleNextTab()
          }
        }
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePreviousTab = () => {
    setActiveTab((prev) => prev - 1)
    window.scrollTo(0, 0)
  }

  const handleNextTab = () => {
    setActiveTab((prev) => prev + 1)
    window.scrollTo(0, 0)
  }

  if (loading || (voucherCode && !selectedService)) {
    return <LoadingPage />
  }

  return (
    <>
      <Container maxWidth="lg" style={{ position: 'relative' }}>
        <ButtonsGrid container alignItems="center" justify="space-between">
          <Grid item xs="auto">
            {((!voucherCode && activeTab > 0) || (voucherCode && activeTab > 1)) && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBack size={20} />}
                onClick={handlePreviousTab}
                style={{ height: 45 }}
              >
                Anterior
              </Button>
            )}
          </Grid>
          <Grid item xs="auto">
            {activeTab < 3 && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForward size={20} />}
                onClick={handleNextTab}
                style={{ height: 45 }}
                disabled={
                  (!voucherCode && activeTab === 0 && !selectedUser) ||
                  (!voucherCode && activeTab === 1 && !selectedService) ||
                  (activeTab === 2 && !selectedTime) ||
                  (!!repeat.length && !selectedTime)
                }
              >
                Continuar
              </Button>
            )}
          </Grid>
        </ButtonsGrid>
        <StepsWrapper item sm={12} md={12} lg={9}>
          <StyledTabs
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={(e, newVal) => setActiveTab(newVal)}
            aria-label="Passos da marcação"
          >
            <StyledTab
              disabled={!!voucherCode}
              disableRipple
              label="Cliente"
              icon={<StepIcon step={1} />}
            />
            <StyledTab
              disabled={!voucherCode && !selectedUser}
              disableRipple
              label="Serviço"
              icon={<StepIcon step={2} />}
            />
            <StyledTab
              disabled={!selectedService}
              disableRipple
              label="Data"
              icon={<StepIcon step={3} />}
            />
            <StyledTab
              disabled={!selectedService || !selectedTime}
              disableRipple
              label="Confirmar"
              icon={<StepIcon step={4} />}
            />
          </StyledTabs>
          <StyledLinearProgress
            variant="determinate"
            value={100 / 4 + (100 / 4) * activeTab}
          />
        </StepsWrapper>
      </Container>
      <Container maxWidth="lg">
        <ContentGrid container direction="column">
          {activeTab === 0 && (
            <Fade in={activeTab === 0}>
              <div>
                <User
                  users={users}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              </div>
            </Fade>
          )}
          {activeTab === 1 && (
            <Fade in={activeTab === 1}>
              <div>
                <Service
                  services={services}
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                />
              </div>
            </Fade>
          )}
          {activeTab === 2 && (
            <Fade in={activeTab === 2}>
              <div>
                <Date
                  service={selectedService}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                  repeat={repeat}
                  setRepeat={setRepeat}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </div>
            </Fade>
          )}
          {activeTab === 3 && (
            <Fade in={activeTab === 3}>
              <div>
                <Confirm
                  user={selectedUser}
                  service={selectedService}
                  date={selectedTime}
                  duration={selectedService?.duration}
                  voucher={voucher}
                  selectedDays={selectedDays}
                  repeat={repeat}
                  endDate={endDate}
                />
              </div>
            </Fade>
          )}
        </ContentGrid>
      </Container>
    </>
  )
}

export default NewAppointment
