import { Button, Card, CardContent, CardMedia, Chip, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCardMedia = styled(CardMedia)`
  height: 0px;
  padding-top: 56.25%;
`

export const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error.main};
`

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);

  &:hover {
    box-shadow: none;
  }

  & .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }
`

export const StyledCardContent = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  padding-bottom: 15px !important;
`

export const PlusIconWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${
      status === 'true'
        ? theme.palette.success.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.success.main : theme.palette.warning.main};
  `}
  .MuiChip-label {
    font-weight: 500;
    padding: 0 24px;
  }
`

export const CategoryCard = styled(Card)`
  ${({ color }) => `
    border: 2px solid rgb(${color});
  `}
`
