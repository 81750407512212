import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const ListGrid = styled(Grid)`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-right: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  padding: 25px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  ${({ active, theme }) =>
    active === 'true' &&
    `
    background-color: ${theme.palette.background.paper};
    border-right: none;
  `};
`

export const Subject = styled(Typography)`
  font-size: 1.1rem;
  font-weight: 500;

  ${({ read, theme }) =>
    read === 'false' &&
    `
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: ${theme.palette.primary.main};
    }
  `};
`

export const SenderName = styled(Typography)`
  font-weight: 400;
`
