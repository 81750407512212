import React, { useContext, useState, useEffect } from 'react'

import { Snackbar, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { CloseCircleOutline } from '@styled-icons/evaicons-outline/CloseCircleOutline'
import { Warning } from '@styled-icons/ionicons-outline/Warning'
import { StateContext } from 'state'

const getTitle = (status) => {
  switch (status) {
    case 'success':
      return 'Sucesso!'
    case 'info':
      return 'Informação'
    case 'warning':
      return 'Atenção!'
    case 'error':
      return 'Erro!'
    default:
      return ''
  }
}

const iconMapping = {
  success: <CheckCircle size={25} />,
  info: <InfoCircle size={25} />,
  warning: <Warning size={25} />,
  error: <CloseCircleOutline size={25} />,
}

const Notification = () => {
  const {
    state: { notification },
    actions,
  } = useContext(StateContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!notification)
  }, [notification])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      actions.notification.removeNotification()
    }, 300)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert
        iconMapping={iconMapping}
        onClose={handleClose}
        variant="filled"
        severity={notification?.status}
      >
        <AlertTitle>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
            {getTitle(notification?.status)}
          </Typography>
        </AlertTitle>
        <Typography variant="body2">{notification?.message}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default Notification
