import { TextField } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import styled from 'styled-components'

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    height: 54px;
    & fieldset {
      border: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }

  & .MuiFilledInput-input {
    padding: 10px 12px 10px 0px;
  }

  & .MuiInputAdornment-positionStart {
    margin-top: 5px !important;
  }

  & .Mui-focused {
    background-color: ${({ theme }) => theme.palette.background.paper};
    & fieldset {
      border: 1px solid black;
    }
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & svg {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & button {
    border-width: 0px;
    border-radius: 3px !important;
    padding: 12px 15px;

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.background};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
      }
    }
  }
`
