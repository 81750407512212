import React from 'react'
import { Link } from 'react-router-dom'

import {
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
  Drawer,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const HR = styled.div`
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: ${({ theme }) => theme.palette.menu.divider};
`

const MenuItemIconWrapper = styled(ListItemIcon)`
  position: relative;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: ${({ theme }) => theme.palette.menu.icon};
`

const MenuItemText = styled(ListItemText)`
  color: ${({ theme }) => theme.palette.menu.text};

  & .MuiTypography-body1 {
    font-size: 0.85rem;
    font-weight: 500;
    font-family: 'Poppins', 'Roboto', Helvetica, Arial, sans-serif;
  }
`

const MenuItemWrapper = styled(ListItem)`
  padding: 15px 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover,
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palette.menu.activeBg};

    ${MenuItemText}, ${MenuItemIconWrapper} {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const Pill = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`

const PillText = styled(Typography)`
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
`

export const MenuItem = ({ to, icon, text, active, notifications, onClick }) => (
  <MenuItemWrapper onClick={onClick} component={Link} to={to} selected={active} button>
    <MenuItemIconWrapper>
      {icon}
      {notifications > 0 && (
        <Pill>
          <PillText variant="subtitle2">{notifications}</PillText>
        </Pill>
      )}
    </MenuItemIconWrapper>
    <MenuItemText primary={text} />
  </MenuItemWrapper>
)
MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  notifications: PropTypes.number,
  onClick: PropTypes.func,
}
MenuItem.defaultProps = {
  notifications: 0,
  onClick: null,
}

export const StyledDrawer = styled(Drawer)`
  width: 50%;
  padding: 20px 0;
`
