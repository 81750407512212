/* eslint-disable consistent-return */
export default (time) => {
  const split = time.split(':')
  if (split.length !== 3) return

  let duration = ''

  if (+split[0] > 0) {
    // duration has an hour digit
    const word = +split[0] > 1 ? 'horas' : 'hora'
    duration = `${+split[0]} ${word} `
  }

  if (+split[1] > 0) {
    // duration has a minute digit
    const word = +split[1] > 1 ? 'minutos' : 'minuto'
    duration = `${duration}${+split[1]} ${word}`
  }

  return duration
}
