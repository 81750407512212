import React, { useEffect, useState } from 'react'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { api } from 'api'

import { StyledCard, UserCard } from './styles'

const UsersRanking = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get('/users/best-clients').then(({ data }) => {
      setLoading(false)
      setUsers(data)
    })
  }, [])

  return (
    <StyledCard container direction="column">
      <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
        Melhores clientes
      </Typography>
      {loading && <CircularProgress style={{ marginTop: 20 }} />}
      {!loading && (
        <Grid container spacing={2}>
          {users.map((user, i) => (
            <Grid key={i} item xs={12} sm={6}>
              <UserCard user={user} />
            </Grid>
          ))}
        </Grid>
      )}
    </StyledCard>
  )
}

export default UsersRanking
