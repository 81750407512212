import React from 'react'
import { useHistory } from 'react-router-dom'

import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { publicApi } from 'api'
import PropTypes from 'prop-types'

import { StyledIconButton, StyledReactCodeInput } from './styles'

const InsertVoucherCode = ({ voucherId, open, setOpen }) => {
  const history = useHistory()
  const [code, setCode] = React.useState('')
  const [codeValidation, setCodeValidation] = React.useState('')

  const handleCodeChange = async (val) => {
    setCodeValidation('')
    setCode(val)
    if (val.length === 5) {
      setCodeValidation('loading')

      publicApi
        .get(`/check-voucher?code=${val}&vid=${voucherId}`)
        .then(({ data }) => {
          if (data.exists) {
            history.push(`/appointments/new?voucher=${val}`)
          } else {
            setCodeValidation('O código inserido não corresponde a nenhum voucher ativo')
          }
        })
        .catch((e) => {
          setCodeValidation(e.response.data[0].message)
        })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      // disableScrollLock
    >
      <DialogTitle>
        Resgatar Voucher
        <StyledIconButton aria-label="close" onClick={() => setOpen(false)}>
          <CloseOutline size={25} />
        </StyledIconButton>
      </DialogTitle>
      <DialogContent style={{ marginBottom: 15 }}>
        <StyledReactCodeInput
          type="text"
          inputMode="text"
          disabled={codeValidation === 'loading'}
          isValid={codeValidation === '' || codeValidation === 'success'}
          value={code}
          onChange={handleCodeChange}
          name="code"
          fields={5}
        />
        <Typography style={{ textAlign: 'center' }} variant="body2" color="error">
          {codeValidation !== '' &&
            codeValidation !== 'success' &&
            codeValidation !== 'loading' &&
            codeValidation}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

InsertVoucherCode.propTypes = {
  voucherId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

InsertVoucherCode.defaultProps = {
  voucherId: -1,
  open: false,
}

export default InsertVoucherCode
