import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'

import {
  Grid,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Button,
  FormHelperText,
  CircularProgress,
  InputLabel,
} from '@material-ui/core'
import { Visibility } from '@styled-icons/material/Visibility'
import { VisibilityOff } from '@styled-icons/material/VisibilityOff'
import { api } from 'api'
import { ContainerCard } from 'pages/styles'
import { StateContext } from 'state'

const validations = {
  password: {
    required: 'Campo obrigatório',
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
      message:
        'A senha não tem todos os elementos pedidos. Por favor verifica se contém no mínimo 8 caracteres, um número e uma letra minúscula e uma maiúscula.',
    },
    minLength: {
      value: 8,
      message: 'Deve conter pelo menos 8 caracteres',
    },
  },
}

const Settings = () => {
  const { actions } = useContext(StateContext)
  const [showPassword, setShowPassword] = useState(false)
  const { register, watch, handleSubmit, errors, reset } = useForm()
  const password = watch('password')
  const confirmPassword = watch('password_confirmation')
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)
    api
      .patch('/change-password/', data)
      .then(() => {
        reset()
        setLoading(false)
        actions.notification.setNotification('success', 'Senha alterada com sucesso')
      })
      .catch((e) => {
        setLoading(false)
        actions.notification.setNotification('error', e.response?.data[0].message)
      })
  }

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <Grid container justify="center" style={{ padding: 20 }}>
      <Grid item xs={12} sm={8} md={5}>
        <Typography variant="h4" color="primary">
          Alterar senha
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerCard container style={{ marginTop: 30, padding: 30 }}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              error={!!errors.password}
            >
              <InputLabel htmlFor="new-password">Nova senha</InputLabel>
              <OutlinedInput
                id="new-password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                name="password"
                inputRef={register(validations.password)}
                helpertext={errors.password && errors.password.message}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <Visibility size={25} />
                      ) : (
                        <VisibilityOff size={25} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
              />
              {errors.password ? (
                <FormHelperText>{errors.password.message}</FormHelperText>
              ) : (
                <FormHelperText>
                  Tem de ter no mínimo 8 caracteres, um número e uma letra minúscula e uma
                  maiúscula
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!errors.password_confirmation}
            >
              <InputLabel htmlFor="confirm-new-password">Confirmar nova senha</InputLabel>
              <OutlinedInput
                id="confirm-new-password"
                type="password"
                variant="outlined"
                name="password_confirmation"
                inputRef={register({
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 8,
                    message: 'Deve conter pelo menos 8 caracteres',
                  },
                  validate: (value) => value === watch('password'),
                })}
                helpertext={
                  errors.password_confirmation && errors.password_confirmation.message
                }
                labelWidth={157}
              />
              {errors.password_confirmation &&
                errors.password_confirmation.type === 'validate' && (
                  <FormHelperText>
                    Deve corresponder à senha escolhida acima
                  </FormHelperText>
                )}
            </FormControl>
          </ContainerCard>
          <Button
            style={{ marginTop: 20, padding: '10px 22px', textTransform: 'none' }}
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={
              !password ||
              password.trim().length === 0 ||
              !confirmPassword ||
              confirmPassword.trim().length === 0
            }
          >
            {loading ? <CircularProgress /> : 'Alterar'}
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default Settings
