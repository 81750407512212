import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.menu.text};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const Time = styled(Typography)`
  color: ${({ theme }) => theme.palette.menu.text};
  opacity: 0.7;
`

export const Dot = styled.span`
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  margin: 0 10px;
`
