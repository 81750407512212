import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Spa } from '@styled-icons/material-twotone/Spa'
import styled from 'styled-components'

export const RadioButton = styled(Grid)`
  background-color: ${({ theme }) => fade(theme.palette.background.paper, 0.7)};
  border: ${({ theme }) => `1.5px solid ${theme.palette.background.paper}`};
  font-size: 1rem;
  padding: 15px 0;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  transition: background-color 200ms ease-in-out, color 200ms ease;

  &.checked {
    border-color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.background};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const DatesWrapper = styled(Grid)`
  margin-top: 10px;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      & .MuiGrid-item {
        padding: 10px;
      }
    }
  `};
`

export const ServiceContainer = styled(Grid)`
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 5px;
  padding: 15px 20px;
  box-shadow: ${({ theme }) => `0px 10px 20px ${theme.palette.secondary.shadow}`};

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding: 20px 5px;
      text-align: center;

      & > div:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  `};
`

export const SelectedServiceLabel = styled(Typography)`
  opacity: 0.9;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const SelectedServiceText = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  color: ${({ theme }) => theme.palette.secondary.main};
`

const StyledIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-right: 40px;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      margin-right: auto;
    }
  `};
`

export const StyledIcon = () => (
  <StyledIconWrapper>
    <Spa size={35} />
  </StyledIconWrapper>
)

const HasHoursWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`

const HasHoursCircle = styled.div`
  width: 8px;
  height: 8px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const HasHours = () => (
  <HasHoursWrapper>
    <HasHoursCircle />
  </HasHoursWrapper>
)
