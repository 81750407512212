import React, { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core'
import { api } from 'api'
import parseApptStatus from 'helpers/parse-appt-status'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Tooltip,
  ResponsiveContainer,
  YAxis,
  LineChart,
  XAxis,
  Legend,
  Line,
} from 'recharts'

import { StyledCard } from './styles'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload.filter((pi) => pi.value > 0)
    const columnName = data[0]?.payload?.name
    return (
      <StyledCard
        style={{ height: 'auto', flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <Typography
          variant="subtitle2"
          style={{
            textTransform: 'capitalize',
            borderBottom: '1px solid',
            marginBottom: 5,
          }}
        >
          {!!columnName && moment(columnName, 'MMM').format('MMMM')}
        </Typography>
        {data.map((item, index) => (
          <Box
            key={index}
            className="label"
            width="100%"
            display="flex"
            justifyContent="space-between"
            gridGap={4}
            style={{
              color: item.color,
              padding: '5px 0',
              borderTop: index > 0 ? '1px solid #3a3a3a' : '',
            }}
          >
            <span>{item.name}:</span>
            <span>{item.value}</span>
          </Box>
        ))}
      </StyledCard>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const Dot = ({ cx, cy, stroke, r, strokeWidth }) => {
  return (
    <svg>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={stroke}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

Dot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  r: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
}

const statuses = (theme) => {
  return {
    'confirm-guest': parseApptStatusColor('confirm-guest', theme),
    pending: parseApptStatusColor('pending', theme),
    confirmed: parseApptStatusColor('confirmed', theme),
    paid: parseApptStatusColor('paid', theme),
    finished: parseApptStatusColor('finished', theme),
    canceled: parseApptStatusColor('canceled', theme),
    expired: parseApptStatusColor('expired', theme),
    absent: parseApptStatusColor('absent', theme),
  }
}

const AppointmentsByStatus = ({ year }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const theme = useTheme()

  useEffect(() => {
    setLoading(true)
    api.get(`/appointments/status?year=${year}`).then(({ data: resp }) => {
      setLoading(false)
      setData(resp)
    })
  }, [year])

  return (
    <StyledCard container direction="row">
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography variant="subtitle1" gutterBottom>
          Marcações por estado
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '100%', paddingBottom: 10 }}>
        {loading && <CircularProgress style={{ marginTop: 20 }} />}
        {!loading && (
          <ResponsiveContainer width="100%" height={450}>
            <LineChart data={data}>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} width={25} />
              <Tooltip content={<CustomTooltip />} cursor={{ opacity: 0.1 }} />
              <Legend />
              {Object.entries(statuses(theme)).map(([statusKey, statusColor]) => (
                <Line
                  key={statusKey}
                  dot={<Dot />}
                  type="monotone"
                  dataKey={statusKey}
                  name={parseApptStatus(statusKey)}
                  stroke={statusColor}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </StyledCard>
  )
}

AppointmentsByStatus.propTypes = {
  year: PropTypes.number,
}

export default AppointmentsByStatus
