import { LOGIN_OK, LOGOUT } from 'state/constants'

export default (state, action) => {
  switch (action.type) {
    case LOGIN_OK:
      return {
        ...state,
        token: action.token,
      }

    case LOGOUT:
      return {
        ...state,
        token: null,
      }

    default:
      return state
  }
}
