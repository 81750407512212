import { Grid, IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const GoBackDesktop = styled(IconButton)`
  align-self: flex-start;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`

export const GoBackMobile = styled(IconButton)`
  position: absolute;
  top: 10px;
  left: 20px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `};
`

export const ListingsGrid = styled(Grid)`
  order: 1;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 2;
    }
  `};
`

export const UserInfoGrid = styled(Grid)`
  order: 2;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 1;
    }
  `};
`

export const UserContainer = styled(Grid)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 40px 20px;
  border-radius: 3px;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
`
