import styled from 'styled-components'

export const TimelineWrapper = styled.ul`
  padding: 0 12px;
  margin: 0;
  list-style: none;
  position: relative;
  height: 100%;

  &::before {
    content: ' ';
    height: 100%;
    width: 1px;
    background-color: #d9d9d9;
    position: absolute;
    top: 0;
    left: calc(11.47px + 0.5em);
  }

  li {
    cursor: pointer;

    &:hover > .bullet.warning {
      background: ${({ theme }) => theme.palette.warning.main};
    }
    &:hover > .bullet.yellow {
      background: ${({ theme }) => theme.palette.yellow.main};
    }
    &:hover > .bullet.info {
      background: ${({ theme }) => theme.palette.info.main};
    }
    &:hover > .bullet.success {
      background: ${({ theme }) => theme.palette.success.main};
    }
    &:hover > .bullet.error {
      background: ${({ theme }) => theme.palette.error.main};
    }
    &:hover > .bullet.pink {
      background: ${({ theme }) => theme.palette.pink.light};
    }

    div {
      display: inline-block;
      margin: 1em 0;
      vertical-align: top;
    }
  }

  .bullet {
    position: relative;
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.background.paper};
    z-index: 1;
    margin-right: 1em;

    &.warning {
      border: 2px solid ${({ theme }) => theme.palette.warning.main};
    }

    &.yellow {
      border: 2px solid ${({ theme }) => theme.palette.yellow.main};
    }

    &.info {
      border: 2px solid ${({ theme }) => theme.palette.info.main};
    }

    &.success {
      border: 2px solid ${({ theme }) => theme.palette.success.main};
    }

    &.error {
      border: 2px solid ${({ theme }) => theme.palette.error.main};
    }

    &.pink {
      border: 2px solid ${({ theme }) => theme.palette.pink.light};
    }
  }

  .time {
    width: 22%;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.8em;

    p {
      margin: 0;
    }

    span {
      margin: 0;
      font-size: 1.1em;
      font-weight: 400;
      color: #afafaf;
    }
  }

  .desc {
    width: 50%;
    line-height: 1em;
    margin: 0.8em 0;

    span {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      color: #afafaf;
    }
  }
`
