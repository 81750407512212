import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { LabelGrid, Title } from './styles'

const CanceledInfo = ({ appointment }) => {
  return (
    <>
      <Title variant="h5" color="secondary">
        Informações de cancelamento
      </Title>
      <Grid container justify="space-between">
        <LabelGrid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ fontWeight: 500 }}
          >
            Data do cancelamento:
          </Typography>
          <Typography variant="body1">{appointment.canceled_at}</Typography>
        </LabelGrid>
        <LabelGrid item xs={12} sm={5}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ fontWeight: 500 }}
          >
            Via do cancelamento:
          </Typography>
          <Typography variant="body1">
            {appointment.cancel_source || 'Indefinido'}
          </Typography>
        </LabelGrid>
        <LabelGrid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ fontWeight: 500 }}
          >
            Razão para o cancelamento:
          </Typography>
          <Typography variant="body1">
            {appointment.cancel_reason || 'Sem razão'}
          </Typography>
        </LabelGrid>
      </Grid>
    </>
  )
}

CanceledInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
}

export default CanceledInfo
