import React, { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { api } from 'api'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Tooltip,
  ResponsiveContainer,
  YAxis,
  LineChart,
  XAxis,
  Legend,
  Line,
} from 'recharts'

import { StyledCard } from './styles'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload.filter((pi) => pi.value > 0)
    const columnName = data[0]?.payload?.name
    return (
      <StyledCard
        style={{ height: 'auto', flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <Typography
          variant="subtitle2"
          style={{
            textTransform: 'capitalize',
            borderBottom: '1px solid',
            marginBottom: 5,
          }}
        >
          {!!columnName && moment(columnName, 'MMM').format('MMMM')}
        </Typography>
        {data.map((item, index) => (
          <Box
            key={index}
            className="label"
            width="100%"
            display="flex"
            gridGap={2}
            color={item.color}
          >
            <span>{item.name}:</span>
            <span>{item.value}</span>
          </Box>
        ))}
      </StyledCard>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const Dot = ({ cx, cy, stroke, r, strokeWidth }) => {
  return (
    <svg>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={stroke}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

Dot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  r: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
}

const NewUsersGuestsByYear = () => {
  const [year, setYear] = useState(new Date().getFullYear())
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get(`/users/new-per-month?year=${year}`).then(({ data: resp }) => {
      setLoading(false)
      setData(resp)
    })
  }, [year])

  return (
    <StyledCard container>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="subtitle1" gutterBottom>
            Registados e Convidados por mês
          </Typography>
          <Box
            flex={1}
            display="flex"
            gridGap={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton onClick={() => setYear(year - 1)}>
              <ArrowLeft size={20} />
            </IconButton>
            <Typography variant="h6" color="primary">
              {year}
            </Typography>
            <IconButton onClick={() => setYear(year + 1)}>
              <ArrowRight size={20} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: '100%', paddingBottom: 10 }}>
        {loading && <CircularProgress style={{ marginTop: 20 }} />}
        {!loading && (
          <ResponsiveContainer width="100%" height={320}>
            <LineChart data={data}>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} width={25} />
              <Tooltip content={<CustomTooltip />} cursor={{ opacity: 0.1 }} />
              <Legend />
              <Line
                key="registered"
                dot={<Dot />}
                type="monotone"
                dataKey="registered"
                name="Registados"
                stroke="#89AB15"
              />
              <Line
                key="guests"
                dot={<Dot />}
                type="monotone"
                dataKey="guests"
                name="Convidados"
                stroke="#FFFFFF"
              />
              {/* {data.map((entry) => (
                <>
                  
                </>
              ))} */}
            </LineChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </StyledCard>
  )
}

export default NewUsersGuestsByYear
