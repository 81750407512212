import React from 'react'

import { Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import { UserAvatar, StyledIcon, LabelWithWarning } from './styles'

const User = ({ user }) => {
  const initials =
    user.name === 'Anapconvidado'
      ? 'C'
      : `${user.name[0]}${
          user.name.split(' ').length > 1 ? user.name.split(' ')[1][0] : ''
        }`

  return (
    <>
      <UserAvatar alt={user.name} src={user.photo_url}>
        <Typography variant="h3">{initials}</Typography>
      </UserAvatar>
      <Typography variant="h5">
        {user.name === 'Anapconvidado' ? 'Convidado' : user.name}
      </Typography>
      <Typography style={{ marginTop: 10 }} variant="body1" color="textSecondary">
        Inscrito(a) a: {user.created_at}
      </Typography>
      <Grid container style={{ margin: '30px 0' }}>
        <Grid item xs={6}>
          <Grid container direction="column" alignItems="center">
            <StyledIcon type="visits" />
            <Typography variant="subtitle1">{`${user.visit_count} visita${
              user.visit_count !== 1 ? 's' : ''
            }`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" alignItems="center">
            <StyledIcon type="earnings" />
            <Typography variant="subtitle1">{`${user.earnings} €`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Registo feito por
          </Typography>
          <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
            {user.all_info.source === 'form' ? 'formulário' : user.all_info.source}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LabelWithWarning
            label="Email"
            warning={user.all_info.email_verified ? 'false' : 'true'}
          />
          <Typography variant="body1">{user.all_info.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <LabelWithWarning
            label="Telemóvel"
            warning={
              user.all_info.phone && !user.all_info.phone_verified ? 'true' : 'false'
            }
          />
          <Typography variant="body1">{user.all_info.phone}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Data de nascimento
          </Typography>
          <Typography variant="body1">{user.all_info.birth}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Género
          </Typography>
          <Typography variant="body1">
            {!user.all_info.gender
              ? ''
              : user.all_info.gender === 'F'
              ? 'Feminino'
              : 'Masculino'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Morada
          </Typography>
          <Typography variant="body1">{user.all_info.address}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Localidade
          </Typography>
          <Typography variant="body1">{user.all_info.locality}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            Código postal
          </Typography>
          <Typography variant="body1">{user.all_info.zip_code}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            NIF
          </Typography>
          <Typography variant="body1">{user.all_info.vat}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

User.propTypes = {
  user: PropTypes.object,
}

User.defaultProps = {
  user: null,
}

export default User
