import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Typography } from '@material-ui/core'
import { DoorLock } from '@styled-icons/remix-line/DoorLock'
import parseName from 'helpers/parse-name'
import parseVoucherStatus from 'helpers/parse-voucher-status'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import InsertVoucherCode from '../InsertCode'
import { StyledChip } from './styles'

const VouchersTable = ({ vouchers, search }) => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [voucherId, setVoucherId] = useState(-1)

  const handleInsertCode = (e, voucher) => {
    e.stopPropagation()
    setVoucherId(voucher)
    setDialogOpen(true)
  }

  const columns = [
    {
      name: 'created_at',
      label: 'Criado a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1">
              {moment(vouchers[dataIndex].created_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'DD/MM/YYYY'
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(vouchers[dataIndex].created_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'HH:mm'
              )}
            </Typography>
          </>
        ),
      },
    },
    {
      name: 'used_at',
      label: 'Usado a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          vouchers[dataIndex].used_at ? (
            <>
              <Typography variant="body1">
                {moment(vouchers[dataIndex].used_at, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD/MM/YYYY'
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {moment(vouchers[dataIndex].used_at, 'YYYY-MM-DDTHH:mm:ss').format(
                  'HH:mm'
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">Não usado</Typography>
          ),
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <StyledChip
            status={vouchers[dataIndex].status}
            label={parseVoucherStatus(vouchers[dataIndex].status)}
          />
        ),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return obj1.data > obj2.data
              ? order === 'asc'
                ? 1
                : -1
              : order === 'asc'
              ? -1
              : 1
          }
        },
      },
    },
    {
      name: 'buyer_name',
      label: 'De',
      options: {
        sort: false,
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          parseName(vouchers[dataIndex].buyer_name),
      },
    },
    {
      name: 'receiver_name',
      label: 'Para',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          parseName(vouchers[dataIndex].receiver_name),
      },
    },
    {
      name: 'expires_at',
      label: 'Expira a',
      options: {
        searchable: false,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1">
              {moment(vouchers[dataIndex].expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'DD/MM/YYYY'
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(vouchers[dataIndex].expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                'HH:mm'
              )}
            </Typography>
          </>
        ),
      },
    },
    {
      name: 'insertcode',
      label: ' ',
      options: {
        searchable: false,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          vouchers[dataIndex].status === 'paid' && (
            <Button
              fullWidth
              size="large"
              variant="text"
              color="primary"
              onClick={(e) => handleInsertCode(e, vouchers[dataIndex].id)}
              startIcon={<DoorLock size={20} />}
            >
              <Typography variant="subtitle2">Código</Typography>
            </Button>
          ),
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    searchText: search,
    customSearchRender: () => null,
    viewColumns: false,
    onRowClick: (rowData, rowMeta) =>
      history.push(`/vouchers/${vouchers[rowMeta.dataIndex].id}/info`),
    sortOrder: { name: 'created_at', direction: 'desc' },
    rowsPerPageOptions: [10, 20, 50],
    selectableRows: 'none',
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: 'Não foram encontrados resultados',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Descarregar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Ver Colunas',
        titleAria: 'Ver/Esconder Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar linhas selecionadas',
      },
    },
  }

  return (
    <>
      <MUIDataTable data={vouchers} columns={columns} options={options} />
      <InsertVoucherCode
        voucherId={voucherId}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  )
}

VouchersTable.propTypes = {
  vouchers: PropTypes.array,
  search: PropTypes.string,
}
VouchersTable.defaultProps = {
  vouchers: [],
  search: '',
}

export default VouchersTable
