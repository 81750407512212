import { Grid, Select } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import { Filter } from '@styled-icons/boxicons-regular/Filter'
import styled from 'styled-components'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-right: 15px;
  & button {
    border-width: 0px;
    border-radius: 3px !important;
    padding: 12px 15px;

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.background};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
      }
    }
  }
`

export const ViewSearchGrid = styled(Grid)`
  padding: 20px 25px;
  border-right: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`

export const InboxListGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const FilterGrid = styled(Grid)`
  padding: 0 25px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-right: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`

export const FilterIcon = styled(Filter)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-right: 10px;
`

export const StyledSelect = styled(Select)`
  flex: 1;

  & .MuiSelect-root {
    display: flex;
    align-items: center;
    height: 56px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.text.secondary};

    &:focus {
      background-color: transparent;
    }
  }

  &:before,
  &:after {
    display: none;
  }

  & .MuiSelect-icon {
    display: none;
  }
`
