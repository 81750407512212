import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { CircularProgress, Grid } from '@material-ui/core'
import Menu from 'components/Menu'
import Notification from 'components/shared/Notification'
import Dashboard from 'pages/Dashboard'
import Users from 'pages/Users'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import Appointments from './Appointments'
import Blog from './Blog'
import Contacts from './Contacts'
import Login from './Login'
import NoMatch from './NoMatch'
import Schedule from './Schedule'
import ServicePacks from './ServicePacks'
import Services from './Services'
import Settings from './Settings'
import { MenuGrid } from './styles'
import Testimonials from './Testimonials'
import Vouchers from './Vouchers'
import VoucherDocument from './Vouchers/Document'

const PrivateRoute = ({ children, ...rest }) => {
  const { state, actions } = useContext(StateContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    actions.auth
      .init()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Grid style={{ height: '100vh' }} container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.auth?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = () => (
  <Grid container>
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <PrivateRoute exact path="/">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Dashboard />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/users">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Users />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/contacts">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Contacts />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/testimonials">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Testimonials />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/appointments">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Appointments />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/vouchers">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Vouchers />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/schedule">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Schedule />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/blog">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Blog />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/service-packs">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <ServicePacks />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/services">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Services />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <MenuGrid item xs={12} lg={2}>
            <Menu />
          </MenuGrid>
          <Grid item xs={12} lg={10}>
            <Settings />
          </Grid>
        </PrivateRoute>
        <PrivateRoute path="/voucher/:id/document">
          <VoucherDocument />
        </PrivateRoute>
        <Route path="/404">
          <NoMatch />
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Router>
    <Notification />
  </Grid>
)

export default Main
