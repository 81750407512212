import {
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  TextField,
  Avatar,
  ListItem,
} from '@material-ui/core'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);

  &:hover {
    box-shadow: none;
  }

  & .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }
`

export const NewTestimonyContent = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  padding-bottom: 15px !important;
`

export const NewTestimonyIcon = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 10px 0;
`

export const TextArea = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-bottom: 10px;

  & .MuiInputBase-root {
    line-height: 1.7;
  }
`

export const UserAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  margin-right: 10px;
`

export const StyledListItem = styled(ListItem)`
  border-radius: 3px;
  margin-bottom: 10px;

  &.Mui-selected {
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.background};
  }
`
