import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, InputAdornment, IconButton, Typography } from '@material-ui/core'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { PlusOutline } from '@styled-icons/evaicons-outline/PlusOutline'
import parseApptStatus from 'helpers/parse-appt-status'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { StyledTextField } from 'pages/Users/List/styles'
import PropTypes from 'prop-types'

import { NewApptButton, StyledChip, SearchGrid, NewApptGrid } from './styles'

const Appointments = ({ userId, appointments }) => {
  const history = useHistory()
  const [search, setSearch] = useState('')

  const columns = [
    {
      name: 'service',
      label: 'Serviço',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
            {appointments[dataIndex].service.name}
          </Typography>
        ),
        sortCompare: (order) => {
          return ({ data: service1 }, { data: service2 }) => {
            return (service1.name < service2.name ? 1 : -1) * (order === 'asc' ? 1 : -1)
          }
        },
      },
    },
    {
      name: 'price',
      label: 'Valor (€)',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
            {appointments[dataIndex].price}
          </Typography>
        ),
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <StyledChip
            status={appointments[dataIndex].status}
            label={parseApptStatus(appointments[dataIndex].status)}
          />
        ),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const status1 = parseApptStatus(obj1.data)
            const status2 = parseApptStatus(obj2.data)
            return (status1 < status2 ? 1 : -1) * (order === 'asc' ? 1 : -1)
          }
        },
      },
    },
    {
      name: 'date',
      label: 'Data de realização',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
              {moment(appointments[dataIndex].date).format('DD MMM YYYY')}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: 'capitalize' }}
            >
              {moment(appointments[dataIndex].start_time, 'HH:mm:ss').format('HH:mm')}
            </Typography>
          </>
        ),
      },
    },
    {
      name: 'created_at',
      label: 'Data de criação',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
              {moment(appointments[dataIndex].created_at, 'DD/MM/YYYY HH:mm').format(
                'DD MMM YYYY'
              )}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: 'capitalize' }}
            >
              {moment(appointments[dataIndex].created_at, 'DD/MM/YYYY HH:mm').format(
                'HH:mm'
              )}
            </Typography>
          </>
        ),
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    searchText: search,
    customSearchRender: () => null,
    customSearch: (searchQuery, currentRow, columns) =>
      currentRow[0].name.toLowerCase().includes(searchQuery.toLowerCase()),
    viewColumns: false,
    onRowClick: (rowData, rowMeta) =>
      history.push(`/appointments/${appointments[rowMeta.dataIndex].id}/info`),
    sortOrder: { name: 'created_at', direction: 'desc' },
    rowsPerPageOptions: [10, 20, 50],
    selectableRows: 'none',
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: 'Não foram encontrados resultados',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Descarregar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Ver Colunas',
        titleAria: 'Ver/Esconder Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar linhas selecionadas',
      },
    },
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <SearchGrid item xs={12} md={6}>
          <StyledTextField
            placeholder="Procura pelo serviço"
            variant="filled"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={27} />
                </InputAdornment>
              ),
              endAdornment: search.trim().length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch('')} label="clear-search">
                    <CloseOutline size={27} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </SearchGrid>
        <NewApptGrid item xs={12} md={4} container justify="flex-end" alignItems="center">
          <NewApptButton
            size="large"
            variant="outlined"
            fullWidth
            color="primary"
            startIcon={<PlusOutline size={20} />}
            onClick={() => history.push(`/appointments/new?user_id=${userId}`)}
            style={{ textTransform: 'inherit' }}
          >
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              Nova Marcação
            </Typography>
          </NewApptButton>
        </NewApptGrid>
      </Grid>
      <MUIDataTable data={appointments} columns={columns} options={options} />
    </div>
  )
}

Appointments.propTypes = {
  userId: PropTypes.number.isRequired,
  appointments: PropTypes.array,
}

Appointments.defaultProps = {
  appointments: [],
}

export default Appointments
