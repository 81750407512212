import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.menu.text};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`

export const Tab = styled(Typography)`
  cursor: pointer;
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  opacity: 0.6;
  letter-spacing: 0.03rem;
  color: ${({ theme }) => theme.palette.menu.text};
  transition: opacity 400ms ease, color 400ms ease;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ active, theme }) =>
    active === 'true' &&
    `
    opacity: 1;
    color: ${theme.palette.primary.main};

    &:after {
      content: "";
      display: block;
      margin: 0 auto;
      border-radius: 2.5px;
      width: 25px;
      margin-top: 5px;
      border-bottom: 5px solid ${theme.palette.primary.main};
  }
  `}
`
