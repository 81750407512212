import { Button, Grid, IconButton } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import styled from 'styled-components'

export const LabelGrid = styled(Grid)`
  order: 1;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 2;
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }
  `};
`

export const TogglesGrid = styled(Grid)`
  order: 2;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 1;
    }
  `};
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & button {
    border-width: 0px;
    border-radius: 3px !important;
    padding: 10px 15px;

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.background};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
      }
    }
  }
`

export const StyledIconButton = styled(IconButton)`
  width: 60px;
  height: 60px;
  margin-right: 10px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      width: 50px;
      height: 50px;
      margin-right: 5px;
    }
  `};
`

export const TodayButton = styled(Button)`
  & h6 {
    font-weight: 600;
  }
  &:hover {
    border-width: 2px;
  }
  &:hover h6 {
    color: white;
  }
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      border: none;
      &:hover {
        border: none;
      }
    }
  `};
`
