import { CardContent } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`
