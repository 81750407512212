import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import RadioCard from 'components/shared/RadioCard'
import parseErrorMessage from 'helpers/parse-error-message'
import { useServices, useVoucherTemplates } from 'hooks'
import { StateContext } from 'state'

import { SubmitButtonWrapper } from './styles'

const NewVoucher = () => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const { templates, loading: loadingTemplates } = useVoucherTemplates()
  const { services, loading: loadingServices } = useServices()
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [buyerName, setBuyerName] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [loadingSubmission, setLoadingSubmission] = useState(false)
  const loadingData = loadingTemplates || loadingServices

  if (loadingData) {
    return <LoadingPage />
  }

  const submit = async () => {
    setLoadingSubmission(true)

    if (
      !selectedTemplate?.id ||
      !selectedService?.id ||
      !buyerName.trim() ||
      !receiverName.trim()
    ) {
      setLoadingSubmission(false)
      actions.notification.setNotification('error', 'Por favor preenche todos os campos')
      return
    }

    const data = {
      voucher_template_id: selectedTemplate.id,
      service_id: selectedService.id,
      buyer_name: buyerName.trim(),
      receiver_name: receiverName.trim(),
    }

    api
      .post('/vouchers/admin', data)
      .then(({ data }) => {
        setLoadingSubmission(false)
        actions.notification.setNotification('success', 'Voucher criado com sucesso')
        history.push(`/vouchers/${data.id}/info`)
      })
      .catch((e) => {
        setLoadingSubmission(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20, position: 'relative' }}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ marginBottom: 15, gap: 10 }}
      >
        <Grid container alignItems="center" justify="space-between">
          <Grid container alignItems="center" style={{ flex: 1 }}>
            <IconButton onClick={() => history.goBack()} aria-label="go-back">
              <LeftArrowAlt size={30} />
            </IconButton>
            <Typography
              variant="h5"
              color="secondary"
              style={{ fontWeight: 600, marginLeft: 10, flex: 1 }}
            >
              Novo Voucher
            </Typography>
          </Grid>
          <SubmitButtonWrapper container alignItems="center" justify="flex-end">
            <Button
              variant="contained"
              disabled={
                loadingSubmission ||
                loadingData ||
                !selectedTemplate ||
                !selectedService ||
                !buyerName ||
                !receiverName
              }
              color="primary"
              size="large"
              style={{ padding: '8px 26px' }}
              onClick={submit}
            >
              Submeter
            </Button>
          </SubmitButtonWrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs="auto" style={{ marginRight: 10 }}>
              <Typography variant="subtitle1">De:</Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <TextField
                variant="filled"
                name="buyer_name"
                fullWidth
                placeholder="Nome de quem oferece"
                value={typeof buyerName === 'string' ? buyerName : ''}
                onChange={(e) => setBuyerName(e.target.value)}
                inputProps={{
                  style: {
                    padding: '16px',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs="auto" style={{ marginRight: 10 }}>
              <Typography variant="subtitle1">Para:</Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <TextField
                variant="filled"
                name="receiver_name"
                fullWidth
                placeholder="Nome de quem recebe"
                value={typeof receiverName === 'string' ? receiverName : ''}
                onChange={(e) => setReceiverName(e.target.value)}
                inputProps={{
                  style: {
                    padding: '16px',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" style={{ margin: '15px 0' }} />
      <Typography variant="h6" gutterBottom>
        Tema
      </Typography>
      <Grid container spacing={2}>
        {templates?.map((template) => (
          <Grid item xs={6} sm={3} md={2} key={template.id}>
            <RadioCard
              selected={selectedTemplate && selectedTemplate.id === template.id}
              onClick={() => setSelectedTemplate(template)}
              custom={{ height: 140 }}
              actionArea={
                <CardMedia
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  component="img"
                  alt={template.title}
                  image={template.image_url}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
      <Divider variant="middle" style={{ margin: '15px 0' }} />
      <Typography variant="h6" gutterBottom>
        Serviço
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: '90px' }}>
        {services?.map((service) => (
          <Grid item xs={12} sm={6} md={3} key={service.id}>
            <RadioCard
              selected={selectedService && selectedService.id === service.id}
              onClick={() => setSelectedService(service)}
              actionArea={
                <>
                  <CardMedia
                    style={{ width: '100%', height: 140, objectFit: 'cover' }}
                    component="img"
                    alt={service.name}
                    image={service.image_url}
                  />
                  <CardContent>
                    <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                      {service.name}
                    </Typography>
                  </CardContent>
                </>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default NewVoucher
