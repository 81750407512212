import React, { createContext, useReducer, useContext } from 'react'

import PropTypes from 'prop-types'

import useActions from './actions'
import InitialState from './initial'
import reducer from './reducers'

export const StateContext = createContext(InitialState)

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitialState)
  const actions = useActions(state, dispatch)

  return (
    <StateContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </StateContext.Provider>
  )
}

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useStore = () => useContext(StateContext)
