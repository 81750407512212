import { Fab, FormControlLabel, Grid } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { DatePicker } from '@material-ui/pickers'
import styled from 'styled-components'

export const ContainerCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 20px 30px;
  min-height: 80px;
  align-items: center;
`

export const MenuGrid = styled(Grid)`
  max-width: 300px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      max-width: 100%;
    }
  `};
`

export const FloatingBtn = styled(Fab)`
  position: fixed;
  display: none;
  z-index: 2;
  width: 60px;
  height: 60px;
  bottom: 60px;
  right: 20px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      display: block;
    }
  `};

  & svg {
    color: white;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
`

export const Day = styled(Grid)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => fade(theme.palette.background.paper, 0.7)};
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    opacity: 0.8;
  }

  ${({ theme, active }) =>
    active === 'true' &&
    `
      background-color: ${theme.palette.primary.main};
  `}
`

export const StyledDatePicker = styled(DatePicker)`
  & .MuiFilledInput-input {
    padding: 12px;
  }
`
