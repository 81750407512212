import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AppointmentInfo from './Info'
import AppointmentsList from './List'
import NewAppointment from './New'

const Appointments = () => (
  <Switch>
    <Route path="/appointments/:id/info">
      <AppointmentInfo />
    </Route>
    <Route path="/appointments/new">
      <NewAppointment />
    </Route>
    <Route exact path="/appointments">
      <AppointmentsList />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
)

export default Appointments
