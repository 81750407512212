import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Grid,
  IconButton,
  Typography,
  Link as MuiLink,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  TextField,
} from '@material-ui/core'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Clipboard } from '@styled-icons/ionicons-outline/Clipboard'
import { DocumentText } from '@styled-icons/ionicons-outline/DocumentText'
import { Edit2 } from '@styled-icons/remix-line/Edit2'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import { StyledChip } from 'components/Vouchers/Table/styles'
import parseErrorMessage from 'helpers/parse-error-message'
import parseVoucherStatus from 'helpers/parse-voucher-status'
import moment from 'moment'
import { StateContext } from 'state'

import {
  Theme,
  Hr,
  ServiceName,
  InfosGrid,
  BottomGrid,
  Title,
  MiddleGrid,
  CodeNumber,
  UserGrid,
  StyledLink,
  StyledSelect,
} from './styles'

const statusMap = {
  pending: ['canceled', 'pending', 'paid'],
  paid: ['canceled', 'paid'],
}

const VoucherInfo = () => {
  const { id } = useParams()
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [voucher, setVoucher] = useState(null)
  const [status, setStatus] = useState(null)
  const [names, setNames] = useState({
    buyer_name: '',
    receiver_name: '',
  })
  const [editStatus, setEditStatus] = useState(false)
  const [editBuyerName, setEditBuyerName] = useState(false)
  const [editReceiverName, setEditReceiverName] = useState(false)

  useEffect(() => {
    if (id) {
      api.get(`/vouchers/${id}/show-admin`).then(({ data }) => {
        setVoucher(data)
        setNames({
          buyer_name: data.buyer_name,
          receiver_name: data.receiver_name,
        })
        setStatus(data.status)
      })
    }
  }, [id])

  if (!voucher) {
    return <LoadingPage />
  }

  const handleSubmitStatus = () => {
    setEditStatus(false)
    api
      .patch(`/vouchers/${id}`, { status })
      .then(({ data }) => {
        setVoucher(data)
        actions.global.getVouchersCount()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const handleSubmitBuyerName = () => {
    setEditBuyerName(false)
    api
      .patch(`/vouchers/${id}`, { buyer_name: names.buyer_name })
      .then(({ data }) => {
        setVoucher(data)
        actions.global.getVouchersCount()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const handleSubmitReceiverName = () => {
    setEditReceiverName(false)
    api
      .patch(`/vouchers/${id}`, { receiver_name: names.receiver_name })
      .then(({ data }) => {
        setVoucher(data)
        actions.global.getVouchersCount()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const renderBuyerElement = () => {
    if (voucher.buyer?.id) {
      return (
        <MuiLink component={Link} to={`/users/${voucher.buyer?.id}/info`}>
          <Typography variant="h6" color="primary">
            {voucher.buyer_name}
          </Typography>
        </MuiLink>
      )
    }

    return (
      <Typography variant="h6" color="secondary">
        {voucher.buyer_name}
      </Typography>
    )
  }

  const renderReceiverElement = () => {
    if (voucher.receiver?.id) {
      return (
        <MuiLink component={Link} to={`/users/${voucher.receiver?.id}/info`}>
          <Typography variant="h6" color="primary">
            {voucher.receiver_name}
          </Typography>
        </MuiLink>
      )
    }

    return (
      <Typography variant="h6" color="secondary">
        {voucher.receiver_name}
      </Typography>
    )
  }

  const handleCopyCodeToClipboard = () => {
    navigator.clipboard.writeText(voucher.code)
    actions.notification.setNotification(
      'success',
      'Código copiado para a área de transferência'
    )
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container>
        <IconButton onClick={() => history.goBack()} aria-label="go-back">
          <LeftArrowAlt size={30} />
        </IconButton>
        <Theme
          style={{
            backgroundImage: voucher.template.image_url
              ? `url(${voucher.template.image_url})`
              : 'lightgray',
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Title />
          </Grid>
        </Theme>
        <MiddleGrid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: 5 }}
              >
                <Typography variant="body1" color="textSecondary">
                  De:
                </Typography>
                <IconButton size="small" onClick={() => setEditBuyerName((p) => !p)}>
                  {editBuyerName ? <CloseOutline size={20} /> : <Edit2 size={20} />}
                </IconButton>
              </Grid>
              {editBuyerName ? (
                <div style={{ display: 'flex', gap: '3px' }}>
                  <TextField
                    placeholder="Nome de quem oferece"
                    variant="filled"
                    fullWidth
                    value={names.buyer_name}
                    onChange={(e) => setNames({ ...names, buyer_name: e.target.value })}
                    inputProps={{
                      style: {
                        padding: '10px 12px 10px',
                      },
                    }}
                  />
                  <Button
                    size="small"
                    onClick={handleSubmitBuyerName}
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                </div>
              ) : (
                renderBuyerElement()
              )}
              {!!voucher.buyer_email && (
                <Typography variant="subtitle1" color="textSecondary">
                  {voucher.buyer_email}
                </Typography>
              )}
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: 5 }}
              >
                <Typography variant="body1" color="textSecondary">
                  Para:
                </Typography>
                <IconButton size="small" onClick={() => setEditReceiverName((p) => !p)}>
                  {editReceiverName ? <CloseOutline size={20} /> : <Edit2 size={20} />}
                </IconButton>
              </Grid>
              {editReceiverName ? (
                <div style={{ display: 'flex', gap: '3px' }}>
                  <TextField
                    placeholder="Nome de quem recebe"
                    variant="filled"
                    fullWidth
                    value={names.receiver_name}
                    onChange={(e) =>
                      setNames({ ...names, receiver_name: e.target.value })
                    }
                    inputProps={{
                      style: {
                        padding: '10px 12px 10px',
                      },
                    }}
                  />
                  <Button
                    size="small"
                    onClick={handleSubmitReceiverName}
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                </div>
              ) : (
                renderReceiverElement()
              )}
              {!!voucher.receiver_email && (
                <Typography variant="subtitle1" color="textSecondary">
                  {voucher.receiver_email}
                </Typography>
              )}
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                Criado a:
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{ fontWeight: 600, textTransform: 'capitalize' }}
              >
                {moment(voucher.created_at, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY')}
              </Typography>
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                Usado a:
              </Typography>
              {voucher.used_at && (
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ fontWeight: 600, textTransform: 'capitalize' }}
                >
                  {moment(voucher.used_at, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY')}
                </Typography>
              )}
            </UserGrid>
          </Grid>
        </MiddleGrid>
        <BottomGrid container direction="column" alignItems="center">
          <ServiceName variant="h5">{voucher.service.name}</ServiceName>
          <Hr />
          <InfosGrid container justify="center" alignItems="center">
            {editStatus && statusMap[voucher.status] ? (
              <FormControl variant="filled">
                <InputLabel id="status">Estado</InputLabel>
                <StyledSelect
                  labelId="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusMap[voucher.status].map((stat) => (
                    <MenuItem key={stat} selected={stat === status} value={stat}>
                      {parseVoucherStatus(stat)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            ) : (
              <StyledChip
                status={voucher.status}
                label={parseVoucherStatus(voucher.status)}
              />
            )}
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {editStatus && (
                <>
                  <Button
                    onClick={handleSubmitStatus}
                    variant="contained"
                    color="primary"
                  >
                    Submeter
                  </Button>
                  <StyledLink
                    style={{ marginLeft: 10 }}
                    color="secondary"
                    onClick={() => setEditStatus(false)}
                  >
                    <Typography variant="subtitle2">Cancelar</Typography>
                  </StyledLink>
                </>
              )}
              {!editStatus && statusMap[voucher.status] && (
                <StyledLink onClick={() => setEditStatus(true)}>
                  <Typography variant="subtitle1">Alterar</Typography>
                </StyledLink>
              )}
            </Grid>
          </InfosGrid>
          {(voucher.status === 'pending' ||
            voucher.status === 'paid' ||
            voucher.status === 'scheduled') && (
            <Button
              variant="outlined"
              size="large"
              style={{ marginTop: 15 }}
              startIcon={<DocumentText size={23} />}
              color="secondary"
              component={Link}
              to={`/voucher/${id}/document`}
              target="_blank"
            >
              Gerar PDF
            </Button>
          )}
          {(voucher.status === 'pending' || voucher.status === 'paid') && (
            <>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Código:
                </Typography>
                {voucher.code.split('').map((number, i) => (
                  <CodeNumber key={i}>
                    <Typography variant="subtitle1">{number}</Typography>
                  </CodeNumber>
                ))}
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Clipboard size={16} />}
                  color="secondary"
                  onClick={handleCopyCodeToClipboard}
                >
                  <span style={{ lineHeight: 'normal' }}>Copiar</span>
                </Button>
              </InfosGrid>
              <InfosGrid container justify="center" alignItems="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  component={Link}
                  to={`/appointments/new?voucher=${voucher.code}`}
                >
                  Agendar <ArrowRight style={{ marginLeft: 5 }} size={23} />
                </Button>
              </InfosGrid>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Válido até:
                </Typography>
                <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                  {moment(voucher.expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD MMMM YYYY'
                  )}
                </Typography>
              </InfosGrid>
            </>
          )}
          {status === 'scheduled' && voucher.appointment && (
            <>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Data da marcação:
                </Typography>
                <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                  {`${moment(voucher.appointment.date, 'YYYY-MM-DD').format(
                    'DD MMM YYYY'
                  )} ${moment(voucher.appointment.start_time, 'HH:mm:ss').format(
                    'HH:mm'
                  )}`}
                </Typography>
              </InfosGrid>

              <InfosGrid container justify="center" alignItems="center">
                <Button
                  variant="text"
                  color="primary"
                  component={Link}
                  to={`/appointments/${voucher.appointment.id}/info`}
                >
                  Ver Marcação <ArrowRight style={{ marginLeft: 5 }} size={23} />
                </Button>
              </InfosGrid>
            </>
          )}
        </BottomGrid>
      </Grid>
    </Container>
  )
}

export default VoucherInfo
