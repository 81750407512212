import React from 'react'
import { useHistory } from 'react-router-dom'

import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import { AvatarName, StyledAvatar, StyledChip } from './styles'

const UserTable = ({ users, page, setPage, sortBy, setSortBy, loading, totalRows }) => {
  const history = useHistory()
  const columns = [
    {
      name: 'photo_url',
      label: ' ',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <StyledAvatar alt={users[dataIndex].name} src={users[dataIndex].photo_url}>
              <AvatarName variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                {`${users[dataIndex].name[0]}${
                  users[dataIndex].name.split(' ').length > 1
                    ? users[dataIndex].name.split(' ')[1][0]
                    : ''
                }`}
              </AvatarName>
            </StyledAvatar>
          )
        },
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {},
    },
    {
      name: 'email_verified_at',
      label: 'Estado',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <StyledChip
            status={users[dataIndex].status ? 'true' : 'false'}
            label={users[dataIndex].status ? 'Ativo' : 'Pendente'}
          />
        ),
      },
    },
    {
      name: 'visit_count',
      label: 'Nº Visitas',
      options: {
        sort: false,
      },
    },
    {
      name: 'earnings',
      label: 'Ganhos (€)',
      options: {
        sort: false,
      },
    },
    {
      name: 'last_visit',
      label: 'Última Visita',
      options: {
        sort: false,
      },
    },
    {
      name: 'created_at',
      label: 'Inscrito desde',
      options: {},
    },
  ]

  const options = {
    filter: false,
    download: false,
    serverSide: true,
    print: false,
    search: false,
    customSearchRender: () => null,
    viewColumns: false,
    onRowClick: (rowData, rowMeta) =>
      history.push(`/users/${users[rowMeta.dataIndex].id}/info`),
    onColumnSortChange: (column, direction) => {
      setSortBy({ name: column, direction })
    },
    sortOrder: sortBy,
    rowsPerPageOptions: [10],
    count: totalRows,
    page,
    onChangePage: (currentPage) => {
      setPage(currentPage)
    },
    selectableRows: 'none',
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: loading ? 'A carregar os dados...' : 'Não foram encontrados resultados',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Descarregar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Ver Colunas',
        titleAria: 'Ver/Esconder Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar linhas selecionadas',
      },
    },
  }

  return <MUIDataTable data={users} columns={columns} options={options} />
}

UserTable.propTypes = {
  users: PropTypes.array,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sortBy: PropTypes.object.isRequired,
  setSortBy: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  totalRows: PropTypes.number.isRequired,
}
UserTable.defaultProps = {
  users: [],
}

export default UserTable
