import React from 'react'

import { Typography, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { Archive } from '@styled-icons/material/Archive'
import { Unarchive } from '@styled-icons/material/Unarchive'
import moment from 'moment'
import PropTypes from 'prop-types'

import { ListGrid, Subject, SenderName } from './styles'

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    padding: 10,
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))

const List = ({ contacts, selected, handleClick, handleAction }) => {
  const classes = useTooltipStyles()

  return (
    contacts &&
    contacts.map((contact, index) => (
      <ListGrid
        key={contact.id}
        active={(selected === contact.id).toString()}
        item
        xs={12}
        onClick={() => handleClick(contact, index)}
      >
        <Grid container alignItems="flex-end">
          <Grid item xs="auto" style={{ overflow: 'hidden', flex: 1 }}>
            <SenderName gutterBottom variant="subtitle2" color="textSecondary">
              {contact.name}
            </SenderName>
            <Subject read={contact.read.toString()} variant="subtitle1">
              {contact.subject}
            </Subject>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              style={{ textOverflow: 'ellipsis' }}
            >
              {contact.message}
            </Typography>
          </Grid>
          <Grid item xs="auto" style={{ marginLeft: 20 }}>
            <Grid container direction="column" alignItems="flex-end">
              <Tooltip
                classes={classes}
                arrow
                title={contact.archived_at ? 'Desarquivar' : 'Arquivar'}
              >
                <IconButton
                  style={{ opacity: 0.5, top: -10 }}
                  onClick={(e) => handleAction(contact.id, e)}
                  aria-label="archive-contact"
                >
                  {contact.archived_at ? <Unarchive size={25} /> : <Archive size={25} />}
                </IconButton>
              </Tooltip>
              <Typography
                style={{ fontSize: '0.8rem' }}
                variant="subtitle2"
                color="textSecondary"
              >
                {moment(contact.created_at).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ListGrid>
    ))
  )
}

List.propTypes = {
  contacts: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
}

export default List
