import React, { useContext, useEffect, useState } from 'react'

import { Grid, Grow, Typography } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { DateTimePicker } from '@material-ui/pickers'
import { Update } from '@styled-icons/material/Update'
import { publicApi } from 'api'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import { HasHours, LoadingTimesGrid, RadioButton } from './styles'

const EditDate = ({ selectedTime, setSelectedTime, serviceSlug }) => {
  const { actions } = useContext(StateContext)
  const [date, setDate] = useState(moment())
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'))
  const [hours, setHours] = useState([])
  const [availables, setAvailables] = React.useState([])
  const [showHours, setShowHours] = useState(false)

  const getSchedule = async (formDate) => {
    publicApi
      .get(`/schedule?service=${serviceSlug}&date=${formDate}`)
      .then(({ data }) => {
        setHours(data)
        setShowHours(true)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const getMaxdate = async () => {
    publicApi
      .get(`/schedule/max-date?service=${serviceSlug}`)
      .then(({ data }) => {
        setMaxDate(data.date)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const getMonthAvailability = async (formService, date) => {
    const current = moment()
    let start = date
    const end = date.clone().endOf('month')

    if (
      current.format('MM') === start.format('MM') &&
      current.format('YYYY') === start.format('YYYY')
    ) {
      start = current
    }

    publicApi
      .get(
        `/schedule/month-slots?service=${formService}&start_date=${start.format(
          'YYYY-MM-DD'
        )}&end_date=${end.format('YYYY-MM-DD')}`
      )
      .then(({ data }) => {
        if (data?.length) {
          setAvailables(data)
          handleDateChange(moment(data[0], 'YYYY-MM-DD'))
        }
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  useEffect(() => {
    getMaxdate()
    getSchedule(moment().format('YYYY-MM-DD'))
    getMonthAvailability(serviceSlug, moment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDateChange = async (d) => {
    if (!moment(d).isSame(date)) {
      setDate(moment(d))
      setHours([])
      setSelectedTime('')
      setShowHours(false)
      getSchedule(moment(d).format('YYYY-MM-DD'))
    }
  }

  const handleMonthChange = async (d) => {
    getMonthAvailability(serviceSlug, d)
  }

  return (
    <Grid container direction="column">
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <DateTimePicker
          autoOk
          hideTabs
          okLabel=""
          disablePast
          label="Data manual"
          ampm={false}
          cancelLabel=""
          name="publish_at"
          inputVariant="outlined"
          format="DD MMM YYYY HH:mm"
          fullWidth
          value={selectedTime || new Date()}
          onChange={(date) => setSelectedTime(moment(date).format('YYYY-MM-DD HH:mm'))}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          autoOk
          variant="static"
          ToolbarComponent={() => <div />}
          orientation="landscape"
          disablePast
          maxDate={maxDate}
          shouldDisableDate={(d) => !availables.includes(d.format('YYYY-MM-DD'))}
          openTo="date"
          value={date}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
            const hasHoursAvailable =
              isInCurrentMonth && availables.includes(day.format('YYYY-MM-DD'))

            return (
              <div style={{ position: 'relative' }}>
                {dayComponent}
                {hasHoursAvailable && <HasHours />}
              </div>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
        <Grid container justify="center" spacing={2}>
          {!showHours && (
            <LoadingTimesGrid item xs={12} container justify="center" alignItems="center">
              <Update size={30} />
              <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                A carregar horários disponíveis para o dia
              </Typography>
            </LoadingTimesGrid>
          )}
          {hours.map((h, i) => (
            <Grid key={i} item xs={3}>
              <Grow in={showHours} {...(showHours ? { timeout: i * 100 + 300 } : {})}>
                <div>
                  <RadioButton
                    onClick={() =>
                      setSelectedTime(`${moment(date).format('YYYY-MM-DD')} ${h}`)
                    }
                    className={`${
                      `${moment(date).format('YYYY-MM-DD')} ${h}` === selectedTime
                        ? 'checked'
                        : ''
                    }`}
                  >
                    {h}
                  </RadioButton>
                </div>
              </Grow>
            </Grid>
          ))}
          {showHours && hours.length === 0 && (
            <LoadingTimesGrid item xs={12} container justify="center" alignItems="center">
              <Update size={30} />
              <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                Sem horários disponíveis para o dia selecionado
              </Typography>
            </LoadingTimesGrid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

EditDate.propTypes = {
  selectedTime: PropTypes.string.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
  serviceSlug: PropTypes.string.isRequired,
}

export default EditDate
