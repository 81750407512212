import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Card, CardContent, Chip, Grid } from '@material-ui/core'
import { PhoneFill } from '@styled-icons/bootstrap/PhoneFill'
import { CalendarDay } from '@styled-icons/fa-solid/CalendarDay'
import { Email } from '@styled-icons/material/Email'
import parseApptStatusBgColor from 'helpers/parse-appt-status-bg-color'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const RightColumn = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`

export const UserAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(12)}px`};
  height: ${({ theme }) => `${theme.spacing(12)}px`};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: ${({ theme }) => `4px solid ${theme.palette.primary.main}`};
  margin-bottom: 15px;
`

export const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 40px;
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`

export const StyledChip = styled(Chip)`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 45px;
  border-radius: 22.5px;

  & .MuiChip-label {
    padding: 0 24px;
  }

  ${({ status, theme }) => `
    background-color: ${parseApptStatusBgColor(status, theme)};
    color: ${parseApptStatusColor(status, theme)};
  `}
`

export const StyledLink = styled(Link)`
  margin: 15px 0;
  text-decoration: none;
  transition: opacity 400ms ease;

  &:hover {
    opacity: 0.7;
  }
`

export const IconWrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.background.default};

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledIcon = ({ type }) => (
  <IconWrapper>
    {type === 'phone' && <PhoneFill size={27} />}
    {type === 'email' && <Email size={27} />}
  </IconWrapper>
)
StyledIcon.propTypes = {
  type: PropTypes.string.isRequired,
}

const CalendarWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 65px;
  height: 65px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
`

const Calendar = styled(CalendarDay)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const CalendarIcon = () => (
  <CalendarWrapper container justify="center" alignItems="center">
    <Calendar size={32} />
  </CalendarWrapper>
)

export const LabelWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const LabelGrid = styled(Grid)`
  border-radius: 3px;
  padding: 14px;
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-bottom: 15px;
`
