import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { List as MaterialList, Drawer } from '@material-ui/core'
import { CalendarWeekFill } from '@styled-icons/bootstrap/CalendarWeekFill'
import { LogOut } from '@styled-icons/boxicons-regular/LogOut'
import { Gift } from '@styled-icons/evaicons-solid/Gift'
import { ClipboardList } from '@styled-icons/fa-solid/ClipboardList'
import { Inbox } from '@styled-icons/fa-solid/Inbox'
import { PenSquare } from '@styled-icons/fa-solid/PenSquare'
import { Users } from '@styled-icons/fa-solid/Users'
import { Settings } from '@styled-icons/ionicons-sharp/Settings'
import { HomeRepairService } from '@styled-icons/material-rounded/HomeRepairService'
import { Assessment } from '@styled-icons/material/Assessment'
import { RateReview } from '@styled-icons/material/RateReview'
import { Service } from '@styled-icons/remix-line/Service'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import MakeAppointment from '../MakeAppointment'
import { HR, MenuItem } from './styles'

const List = ({ location, open, setOpen, mobile }) => {
  const { state, actions } = useContext(StateContext)

  const list = (
    <MaterialList
      component="nav"
      aria-label="menu navigation"
      style={{ paddingTop: 0, paddingBottom: 20 }}
    >
      <MenuItem
        to="/"
        icon={<Assessment size={23} />}
        text="Dashboard"
        active={location.pathname === '/'}
      />
      <HR />
      <MenuItem
        to="/users"
        icon={<Users size={23} />}
        text="Clientes"
        active={location.pathname.includes('/users')}
      />
      <MenuItem
        to="/contacts"
        icon={<Inbox size={23} />}
        text="Contactos"
        active={location.pathname.includes('/contacts')}
        notifications={state.global.inbox_count}
      />
      <MenuItem
        to="/testimonials"
        icon={<RateReview size={23} />}
        text="Testemunhos"
        active={location.pathname.includes('/testimonials')}
        notifications={state.global.testimonials_count}
      />
      <HR />
      <MenuItem
        to="/appointments"
        icon={<ClipboardList size={23} />}
        text="Marcações"
        active={location.pathname.includes('/appointments')}
      />
      <MenuItem
        to="/vouchers"
        icon={<Gift size={28} />}
        text="Vouchers"
        active={location.pathname.includes('/vouchers')}
        notifications={state.global.vouchers_count}
      />
      <MenuItem
        to="/schedule"
        icon={<CalendarWeekFill size={23} />}
        text="Horário"
        active={location.pathname.includes('/schedule')}
      />
      <HR />
      <MenuItem
        to="/blog"
        icon={<PenSquare size={23} />}
        text="Blogue"
        active={location.pathname.includes('/blog')}
      />
      <HR />
      <MenuItem
        to="/service-packs"
        icon={<HomeRepairService size={23} />}
        text="Pacotes de Serviços"
        active={location.pathname.includes('/service-packs')}
      />
      <MenuItem
        to="/services"
        icon={<Service size={23} />}
        text="Serviços"
        active={location.pathname.includes('/services')}
      />
      <HR />
      <MenuItem
        to="/settings"
        icon={<Settings size={23} />}
        text="Definições"
        active={location.pathname === '/settings'}
      />
      <MenuItem
        onClick={() => actions.auth.logout()}
        to="/login"
        icon={<LogOut size={25} />}
        text="Sair"
        active={false}
      />
    </MaterialList>
  )

  return (
    <>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <MakeAppointment />
        {list}
      </Drawer>
      {!mobile && list}
    </>
  )
}
List.propTypes = {
  location: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
}

export default withRouter(List)
