import React, { useContext } from 'react'

import {
  FormControl,
  FormLabel,
  Grid,
  Grow,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Update } from '@styled-icons/material/Update'
import { publicApi } from 'api'
import parseDuration from 'helpers/parse-duration'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import { ListGrid } from 'pages/Appointments/New/styles'
import { Day, StyledDatePicker, StyledFormControlLabel } from 'pages/styles'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import {
  RadioButton,
  ServiceContainer,
  SelectedServiceLabel,
  SelectedServiceText,
  DatesWrapper,
  StyledIcon,
  HasHours,
} from './styles'

const daysOfWeek = [
  ['S', 'Monday'],
  ['T', 'Tuesday'],
  ['Q', 'Wednesday'],
  ['Q', 'Thursday'],
  ['S', 'Friday'],
  ['S', 'Saturday'],
  ['D', 'Sunday'],
]

const AppointmentDate = ({
  service,
  selectedTime,
  setSelectedTime,
  selectedDays,
  setSelectedDays,
  repeat,
  setRepeat,
  endDate,
  setEndDate,
}) => {
  const { actions } = useContext(StateContext)
  const [date, setDate] = React.useState(moment())
  const [maxDate, setMaxDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [hours, setHours] = React.useState([])
  const [availables, setAvailables] = React.useState([])
  const [showHours, setShowHours] = React.useState(true)

  const getSchedule = async (formService, formDate) => {
    publicApi
      .get(`/schedule?service=${formService}&date=${formDate}`)
      .then(({ data }) => {
        setHours(data)
        setShowHours(true)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const getMaxdate = async (formService) => {
    publicApi
      .get(`/schedule/max-date?service=${formService}`)
      .then(({ data }) => {
        setMaxDate(data.date)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const getMonthAvailability = async (formService, date) => {
    const current = moment()
    let start = date
    const end = date.clone().endOf('month')

    if (
      current.format('MM') === start.format('MM') &&
      current.format('YYYY') === start.format('YYYY')
    ) {
      start = current
    }

    publicApi
      .get(
        `/schedule/month-slots?service=${formService}&start_date=${start.format(
          'YYYY-MM-DD'
        )}&end_date=${end.format('YYYY-MM-DD')}`
      )
      .then(({ data }) => {
        if (data?.length) {
          setAvailables(data)
          handleDateChange(moment(data[0], 'YYYY-MM-DD'))
        }
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  React.useEffect(() => {
    getMaxdate(service.slug)
    getMonthAvailability(service.slug, moment())
    if (selectedTime) {
      const d = moment(selectedTime, 'YYYY-MM-DD HH:mm')
      setDate(d)
      getSchedule(service.slug, d.format('YYYY-MM-DD'))
    } else {
      setDate(moment())
      getSchedule(service.slug, moment().format('YYYY-MM-DD'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDateChange = async (d) => {
    if (!moment(d).isSame(date)) {
      setDate(d)
      setHours([])
      setSelectedTime(null)
      setShowHours(false)
      getSchedule(service.slug, moment(d).format('YYYY-MM-DD'))
    }
  }

  const handleMonthChange = async (d) => {
    getMonthAvailability(service.slug, d)
  }

  const handleSelectDays = (day) => {
    if (selectedDays.includes(day)) {
      const newArray = selectedDays.filter((d) => d !== day)
      setSelectedDays(newArray)
    } else {
      setSelectedDays([...selectedDays, day])
    }
  }

  return (
    <ListGrid container justify="center">
      <Grid container justify="center">
        {service && (
          <ServiceContainer item xs={12} md={8} container alignItems="center">
            <Grid item xs={12} sm="auto">
              <StyledIcon />
            </Grid>
            <Grid item xs={12} sm={5}>
              <SelectedServiceLabel variant="subtitle1">
                Serviço escolhido:
              </SelectedServiceLabel>
              <SelectedServiceText variant="h6">{service.name}</SelectedServiceText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectedServiceLabel variant="subtitle1">Duração:</SelectedServiceLabel>
              <SelectedServiceText variant="h6">
                {parseDuration(service.duration)}
              </SelectedServiceText>
            </Grid>
          </ServiceContainer>
        )}
      </Grid>
      <DatesWrapper container justify="center" spacing={4}>
        <Grid item xs={12} sm={6} md={5}>
          <DatePicker
            autoOk
            variant="static"
            ToolbarComponent={() => <div />}
            orientation="landscape"
            disablePast
            maxDate={maxDate}
            openTo="date"
            value={date}
            shouldDisableDate={(d) => !availables.includes(d.format('YYYY-MM-DD'))}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const hasHoursAvailable =
                isInCurrentMonth && availables.includes(day.format('YYYY-MM-DD'))

              return (
                <div style={{ position: 'relative' }}>
                  {dayComponent}
                  {hasHoursAvailable && <HasHours />}
                </div>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Grid container justify="center" spacing={3}>
            {!showHours && (
              <Grid item xs={12} container justify="center" alignItems="center">
                <Update size={30} style={{ opacity: 0.2, marginRight: 10 }} />
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  A carregar horários disponíveis para o dia
                </Typography>
              </Grid>
            )}
            {hours.map((h, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={i} item xs={4}>
                <Grow in={showHours} {...(showHours ? { timeout: i * 100 + 300 } : {})}>
                  <div>
                    <RadioButton
                      onClick={() =>
                        setSelectedTime(`${moment(date).format('YYYY-MM-DD')} ${h}`)
                      }
                      className={`${
                        `${moment(date).format('YYYY-MM-DD')} ${h}` === selectedTime
                          ? 'checked'
                          : ''
                      }`}
                    >
                      {h}
                    </RadioButton>
                  </div>
                </Grow>
              </Grid>
            ))}
            {showHours && hours.length === 0 && (
              <Grid item xs={12} container justify="center" alignItems="center">
                <Update size={30} style={{ opacity: 0.2, marginRight: 10 }} />
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  Sem horários disponíveis para o dia selecionado
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DatesWrapper>
      <hr style={{ width: '100%', margin: '20px 0' }} />
      <Grid container direction="column" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: 600 }} color="primary">
          Marcação recorrente
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="repeat"
            value={repeat}
            onChange={(e) => setRepeat(e.target.value)}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <StyledFormControlLabel
              value=""
              control={<Radio color="primary" />}
              label="Não repete"
            />
            <StyledFormControlLabel
              value="weekly"
              control={<Radio color="primary" />}
              label="Semanalmente"
            />
            <StyledFormControlLabel
              value="monthly"
              control={<Radio color="primary" />}
              label="Mensalmente neste dia"
            />
          </RadioGroup>
        </FormControl>

        {repeat === 'weekly' && (
          <>
            <FormLabel>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600, margin: '10px 0' }}
              >
                Repete a
              </Typography>
            </FormLabel>
            <Grid container justify="center" spacing={2}>
              {daysOfWeek.map((day, i) => (
                <Grid item key={i}>
                  <Day
                    container
                    justify="center"
                    alignItems="center"
                    active={selectedDays.includes(day[1]).toString()}
                    onClick={() => handleSelectDays(day[1])}
                  >
                    <Typography variant="subtitle2">{day[0]}</Typography>
                  </Day>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {(repeat === 'weekly' || repeat === 'monthly') && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ margin: '15px 0' }}
          >
            <FormLabel>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600, marginRight: 10 }}
              >
                Termina a
              </Typography>
            </FormLabel>
            <StyledDatePicker
              autoOk
              okLabel=""
              cancelLabel=""
              disableToolbar
              value={endDate}
              minDate={moment()}
              format="DD/MM/YYYY"
              inputVariant="filled"
              onChange={setEndDate}
              orientation="landscape"
              DialogProps={{ fullWidth: true }}
            />
          </Grid>
        )}
      </Grid>
    </ListGrid>
  )
}

AppointmentDate.propTypes = {
  service: PropTypes.object.isRequired,
  selectedTime: PropTypes.string,
  setSelectedTime: PropTypes.func.isRequired,
  selectedDays: PropTypes.array.isRequired,
  setSelectedDays: PropTypes.func.isRequired,
  repeat: PropTypes.string,
  setRepeat: PropTypes.func.isRequired,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func.isRequired,
}

AppointmentDate.defaultProps = {
  selectedTime: null,
  repeat: '',
  endDate: null,
}

export default AppointmentDate
