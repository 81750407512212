import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CircularProgress, Grid, Typography, Link } from '@material-ui/core'
import { SendPlane } from '@styled-icons/remix-fill/SendPlane'
import { publicApi } from 'api'
import parseDuration from 'helpers/parse-duration'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import momentRecur from 'moment-recur'
import { ListGrid } from 'pages/Appointments/New/styles'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import { StyledIcon, Well, InfoText, StyledButton, RecurrDate } from './styles'

const parseRecurringDate = (date, repeat, selectedDays, endDate) => {
  const dates = []
  const period = repeat === 'weekly' ? 'week' : 'month'
  const firstDate = moment(date)
  const endTime = endDate.clone()

  if (period === 'week') {
    const recurr = momentRecur(date).recur(endTime).every(selectedDays).daysOfWeek().all()

    recurr.forEach((d) => {
      dates.push(d.clone().set({ hour: firstDate.hours(), minute: firstDate.minutes() }))
    })
  } else {
    dates.push(date)
    while (firstDate.add(1, period).diff(endDate) < 0) {
      endTime.add(1, period)
      dates.push(firstDate.clone())
    }
  }

  return dates
}

const Confirm = ({
  service,
  date,
  duration,
  user,
  voucher,
  selectedDays,
  repeat,
  endDate,
}) => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [submitLoading, setSubmitLoading] = useState(false)

  const submit = async () => {
    setSubmitLoading(true)

    if (repeat.length > 0 && !endDate) {
      actions.notification.setNotification('error', 'Escolhe uma data de término')
      return
    }
    if (repeat === 'weekly' && selectedDays.length === 0) {
      actions.notification.setNotification(
        'error',
        'Escolhe os dias da semana onde repetir'
      )
      return
    }

    let dates = []
    if (repeat.length) {
      dates = parseRecurringDate(date, repeat, selectedDays, endDate)
    }

    const formData = {
      date: moment(date).format('YYYY-MM-DD'),
      start_time: moment(date).format('HH:mm:00'),
      service_id: service.id,
      user_id: typeof user === 'object' ? user?.id : null,
      user_name: voucher ? voucher.receiver_name : typeof user === 'string' ? user : null,
      voucher: voucher?.code,
      no_user_confirmation: !!voucher,
      email: voucher?.receiver_email,
      name: 'AnaPConvidado', // required to store the guest
      login_type: 'form', // required to store the guest
      dates: dates.map((d) => moment(d).format('YYYY-MM-DD HH:mm:00')),
    }

    publicApi
      .post('/appointments', formData)
      .then(() => {
        setSubmitLoading(false)
        actions.notification.setNotification(
          'success',
          repeat.length > 0
            ? 'Marcações agendadas com sucesso. O cliente vai receber notificação da próxima marcação.'
            : 'Marcação agendada com sucesso. O cliente foi notificado via email'
        )
        history.push('/')
      })
      .catch((e) => {
        setSubmitLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <ListGrid container justify="center">
      <Grid item xs={12} sm={10} md={9}>
        <Grid container direction="column" alignItems="center">
          <StyledIcon />
          <Typography variant="h4" color="secondary">
            {service?.name}
          </Typography>
          {typeof user === 'object' && user?.name && (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginTop: 20 }}
              >
                Cliente
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 500 }}>
                {user?.name}
              </Typography>
            </>
          )}
          {voucher && (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginTop: 20 }}
              >
                Convidado
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 500 }}>
                {voucher?.receiver_name}
              </Typography>
              <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                {voucher?.receiver_email}
              </Typography>
            </>
          )}
          {typeof user === 'string' && (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginTop: 20 }}
              >
                Anónimo
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 500 }}>
                {user}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} md={9}>
        <Well container spacing={3} direction="row">
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Local
            </Typography>
            <InfoText variant="subtitle2">
              Rua central 1290
              <br />
              4485-932 Mosteiró
            </InfoText>
            <Link href="https://g.page/anapintoterapeuta" target="_blank">
              Ver no mapa
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Data
            </Typography>
            <InfoText variant="h6" style={{ textTransform: 'capitalize' }}>
              {moment(date, 'YYYY-MM-DD HH:mm').format('DD MMMM')}
            </InfoText>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Hora
            </Typography>
            <InfoText variant="h6">
              {moment(date, 'YYYY-MM-DD HH:mm').format('HH : mm')}
            </InfoText>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Duração
            </Typography>
            <InfoText variant="h6">{parseDuration(duration)}</InfoText>
          </Grid>
        </Well>
      </Grid>
      {repeat.length ? (
        <Grid
          item
          xs={12}
          sm={10}
          md={9}
          style={{ marginBottom: 30, textAlign: 'center' }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600, marginBottom: 10 }}
            color="textSecondary"
          >
            Marcação recorrente nas seguintes datas:
          </Typography>
          <Grid container justify="center" spacing={3}>
            {parseRecurringDate(date, repeat, selectedDays, endDate)
              .filter(
                (d) =>
                  moment(d).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')
              )
              .map((day, i) => (
                <Grid item key={i}>
                  <RecurrDate>
                    <Typography variant="body2" color="textSecondary">
                      {moment(day.toDate()).format('dddd')}
                    </Typography>
                    <Typography variant="body1">
                      {moment(day.toDate()).format('DD MMM YYYY')}
                    </Typography>
                  </RecurrDate>
                </Grid>
              ))}
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={10} md={9}>
        <Grid container justify="center">
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            onClick={submit}
            disabled={submitLoading}
            endIcon={<SendPlane size={20} />}
          >
            {submitLoading ? <CircularProgress size={25} /> : 'Agendar'}
          </StyledButton>
        </Grid>
      </Grid>
    </ListGrid>
  )
}

Confirm.propTypes = {
  service: PropTypes.object,
  date: PropTypes.string,
  duration: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  voucher: PropTypes.object,
  selectedDays: PropTypes.array.isRequired,
  repeat: PropTypes.string,
  endDate: PropTypes.object,
}

Confirm.defaultProps = {
  service: null,
  date: '',
  duration: '',
  user: null,
  voucher: null,
}

export default Confirm
