import { Typography, Avatar, Chip } from '@material-ui/core'
import styled from 'styled-components'

export const StyledAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.palette.background.default};
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const AvatarName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${
      status === 'true'
        ? theme.palette.primary.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.primary.main : theme.palette.warning.main};
  `}
`
