export default (error) => {
  let message = error.message
  if (error.response) {
    message = Array.isArray(error.response?.data)
      ? error.response.data[0]?.message
      : error.response.data?.message
  }

  return message
}
