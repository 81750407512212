export default (status, theme) => {
  switch (status) {
    case 'confirm-guest':
      return theme.palette.yellow.background
    case 'pending':
      return theme.palette.warning.background
    case 'confirmed':
      return theme.palette.info.background
    case 'paid':
      return theme.palette.primary.background
    case 'finished':
      return theme.palette.success.background
    case 'canceled':
      return theme.palette.error.background
    case 'expired':
      return theme.palette.error.background
    case 'absent':
      return theme.palette.pink.background
    default:
      return 'rgba(0, 0, 0, 0.06)'
  }
}
