import React, { useContext, useState } from 'react'

import {
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { api } from 'api'
import Dialog from 'components/shared/Dialog'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import momentRecur from 'moment-recur'
import { DateWrapper } from 'pages/Schedule/styles'
import { Day, StyledDatePicker, StyledFormControlLabel } from 'pages/styles'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

const daysOfWeek = [
  ['S', 'Monday'],
  ['T', 'Tuesday'],
  ['Q', 'Wednesday'],
  ['Q', 'Thursday'],
  ['S', 'Friday'],
  ['S', 'Saturday'],
  ['D', 'Sunday'],
]

const NewEventDialog = ({ open, setOpen, date, setDate, refreshEvents }) => {
  const { actions } = useContext(StateContext)
  const [selectedDays, setSelectedDays] = useState([])
  const [repeat, setRepeat] = useState('')
  const [endDate, setEndDate] = useState(null)

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setDate({ start: null, end: null })
    }, 200)
  }

  const handleSelectDays = (day) => {
    if (selectedDays.includes(day)) {
      const newArray = selectedDays.filter((d) => d !== day)
      setSelectedDays(newArray)
    } else {
      setSelectedDays([...selectedDays, day])
    }
  }

  const onSave = () => {
    if (repeat.length > 0 && !endDate) {
      actions.notification.setNotification('error', 'Escolhe uma data de término')
      return
    }
    if (repeat === 'weekly' && selectedDays.length === 0) {
      actions.notification.setNotification(
        'error',
        'Escolhe os dias da semana onde repetir'
      )
      return
    }

    const dates = []
    if (repeat.length > 0) {
      // generate all days from repetition
      const period = repeat === 'weekly' ? 'week' : 'month'
      const dateStart = moment(date.start)
      const dateEnd = moment(date.end)
      const lastDate = endDate.clone()

      if (period === 'week') {
        const recurr = momentRecur(date.start)
          .recur(lastDate)
          .every(selectedDays)
          .daysOfWeek()
          .all()

        recurr.forEach((d) => {
          dates.push({
            start: d
              .clone()
              .set({ hour: dateStart.hours(), minute: dateStart.minutes() })
              .toDate(),
            end: d
              .clone()
              .set({ hour: dateEnd.hours(), minute: dateEnd.minutes() })
              .toDate(),
          })
        })
      } else {
        dates.push(date)
        while (dateStart.add(1, period).diff(lastDate) < 0) {
          dateEnd.add(1, period)
          dates.push({
            start: dateStart.clone().toDate(),
            end: dateEnd.clone().toDate(),
          })
        }
      }
    } else {
      dates.push(date)
    }
    const data = {
      dates: dates,
    }
    setSelectedDays([...[]])
    setRepeat('')
    setEndDate(null)

    api
      .post('/schedule', data)
      .then(({ data }) => {
        if (data.rowCount !== dates.length) {
          actions.notification.setNotification(
            'warning',
            'Não foi possível inserir todos os horários pedidos. Atualiza a página e verifica quais faltam'
          )
        } else {
          actions.notification.setNotification('success', 'Horários criados com sucesso')
          refreshEvents()
        }
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })

    handleClose()
  }

  if (!date.start || !date.end) return null

  return (
    <Dialog open={open} handleClose={handleClose} title="Novo Horário" onSave={onSave}>
      <DialogContent style={{ padding: '15px 24px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateWrapper container direction="column">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: 500, textTransform: 'capitalize' }}
              >
                {moment(date.start).format('dddd, DD MMM YYYY')}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ letterSpacing: '0.1rem', lineHeight: '1.8rem' }}
              >
                {moment(date.start).format('HH:mm')}
              </Typography>
            </DateWrapper>
          </Grid>
          <Grid item xs={6}>
            <DateWrapper container direction="column">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: 500, textTransform: 'capitalize' }}
              >
                {moment(date.end).format('dddd, DD MMM YYYY')}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ letterSpacing: '0.1rem', lineHeight: '1.8rem' }}
              >
                {moment(date.end).format('HH:mm')}
              </Typography>
            </DateWrapper>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <FormControl component="fieldset">
            <FormLabel>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600, margin: '25px 0 10px 0' }}
              >
                Repetir Horário
              </Typography>
            </FormLabel>
            <RadioGroup
              name="repeat"
              value={repeat}
              onChange={(e) => setRepeat(e.target.value)}
            >
              <StyledFormControlLabel
                value=""
                control={<Radio color="primary" />}
                label="Não repetir"
              />
              <StyledFormControlLabel
                value="weekly"
                control={<Radio color="primary" />}
                label="Semanalmente"
              />
              <StyledFormControlLabel
                value="monthly"
                control={<Radio color="primary" />}
                label="Mensalmente neste dia"
              />
            </RadioGroup>
          </FormControl>

          {repeat === 'weekly' && (
            <>
              <FormLabel>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600, margin: '25px 0 10px 0' }}
                >
                  Repete a
                </Typography>
              </FormLabel>
              <Grid container spacing={2}>
                {daysOfWeek.map((day, i) => (
                  <Grid item key={i}>
                    <Day
                      container
                      justify="center"
                      alignItems="center"
                      active={selectedDays.includes(day[1]).toString()}
                      onClick={() => handleSelectDays(day[1])}
                    >
                      <Typography variant="subtitle2">{day[0]}</Typography>
                    </Day>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {(repeat === 'weekly' || repeat === 'monthly') && (
            <Grid container alignItems="center" style={{ margin: '25px 0 10px 0' }}>
              <FormLabel>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600, marginRight: 10 }}
                >
                  Termina a
                </Typography>
              </FormLabel>
              <StyledDatePicker
                autoOk
                okLabel=""
                cancelLabel=""
                disableToolbar
                value={endDate}
                minDate={moment()}
                format="DD/MM/YYYY"
                inputVariant="filled"
                onChange={setEndDate}
                orientation="landscape"
                DialogProps={{ fullWidth: true }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

NewEventDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  date: PropTypes.object,
  setDate: PropTypes.func.isRequired,
  refreshEvents: PropTypes.func.isRequired,
}

NewEventDialog.defaultProps = {
  open: false,
  date: { start: null, end: null },
}

export default NewEventDialog
