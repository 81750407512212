import React, { useState, useEffect, useContext } from 'react'

import {
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
  InputAdornment,
  TextField,
  MenuItem,
  useMediaQuery,
  useTheme,
  Container,
} from '@material-ui/core'
import { Pagination, ToggleButton } from '@material-ui/lab'
import { InboxFill } from '@styled-icons/bootstrap/InboxFill'
import { DownArrowAlt } from '@styled-icons/boxicons-regular/DownArrowAlt'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { UpArrowAlt } from '@styled-icons/boxicons-regular/UpArrowAlt'
import { Archive } from '@styled-icons/entypo/Archive'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api } from 'api'
import Details from 'components/Contacts/Details'
import List from 'components/Contacts/List'
import LoadingPage from 'components/shared/LoadingPage'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

import {
  StyledToggleButtonGroup,
  InboxListGrid,
  FilterIcon,
  StyledSelect,
  FilterGrid,
  ViewSearchGrid,
} from './styles'

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    padding: 10,
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))

const Contacts = () => {
  const { actions } = useContext(StateContext)
  const theme = useTheme()
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useTooltipStyles()
  const [selected, setSelected] = useState(-1)
  const [filter, setFilter] = useState('recent') // recent | older | notanswered
  const [view, setView] = useState('inbox') // inbox | archive
  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [inbox, setInbox] = useState(null)
  const [loading, setLoading] = useState(true)
  const [archive, setArchive] = useState(null)
  const [listOpen, setListOpen] = useState(false)
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 5,
    page: 1,
    lastPage: 1,
  })
  const [archivePagination, setArchivePagination] = useState({
    total: 0,
    perPage: 5,
    page: 1,
    lastPage: 1,
  })

  useEffect(() => {
    api.get(`/inboxes?filter=${filter}`).then(({ data }) => {
      setInbox(data.data)
      setPagination(data.pagination)
      setLoading(false)
      if (data.data.length > 0) setSelected(data.data[0].id)
    })
  }, [filter])

  useEffect(() => {
    setSearch('')
    setSelected(-1)

    if (view === 'inbox' && inbox?.length > 0) {
      setSelected(inbox[0].id)
    }

    if (view === 'archive') {
      if (archive) {
        setSelected(archive[0].id)
      } else {
        api.get('/inboxes/archived').then(({ data }) => {
          setArchive(data.data)
          setArchivePagination(data.pagination)
          if (data.data.length > 0) setSelected(data.data[0].id)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (search.trim().length > 1) {
      setListOpen((prev) => isNotDesktop)
      if (view === 'inbox') {
        const finds = inbox.filter(
          (contact) =>
            contact.name.toLowerCase().includes(search.toLowerCase()) ||
            contact.subject.toLowerCase().includes(search.toLowerCase())
        )
        setSearchResults(finds)
      } else {
        const finds = archive.filter(
          (contact) =>
            contact.name.toLowerCase().includes(search.toLowerCase()) ||
            contact.subject.toLowerCase().includes(search.toLowerCase())
        )
        setSearchResults(finds)
      }
    } else {
      setListOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleToggleSearch = () => {
    setShowSearch((prev) => !prev)
  }

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  const handleClick = (contact, index) => {
    // set contact as the selected one
    setSelected(contact.id)
    setListOpen((prev) => isNotDesktop && !prev)

    // if the contact has "read" = false than update
    if (!contact.read) {
      api
        .get(`/inboxes/${contact.id}/read`)
        .then(() => {
          // update the object in state to reflect the contact as read
          const updated = view === 'inbox' ? [...inbox] : [...archive]
          updated[index].read = true
          if (view === 'inbox') {
            setInbox(updated)
          } else {
            setArchive(updated)
          }
          // update global state to show on the menu list the new inbox count
          actions.global.getInboxCount()
        })
        .catch((e) => {
          const message = parseErrorMessage(e)
          actions.notification.setNotification('error', message)
        })
    }
  }

  const handleAction = (contactId, e) => {
    e.stopPropagation()
    const type = view === 'inbox' ? 'archive' : 'unarchive'
    api
      .patch(`/inboxes/${contactId}/${type}`)
      .then(({ data }) => {
        setInbox(data.inbox.data)
        setPagination(data.inbox.pagination)
        if (view === 'inbox') {
          setSelected(data.inbox.data[0].id)
        } else {
          setSelected(data.archived.data[0].id)
        }
        if (archive) {
          setArchive(data.archived.data)
          setArchivePagination(data.archived.pagination)
        }
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  const handleReplyUpdate = (contact) => {
    const updated = view === 'inbox' ? [...inbox] : [...archive]
    const index = updated.findIndex((x) => x.id === contact.id)
    updated[index] = contact
    if (view === 'inbox') {
      setInbox(updated)
    } else {
      setArchive(updated)
    }
  }

  const handlePagination = (event, value) => {
    api
      .get(
        `/inboxes${view === 'archive' ? '/archived' : ''}?filter=${filter}&page=${value}`
      )
      .then(({ data }) => {
        if (view === 'archive') {
          setArchive(data.data)
          setArchivePagination(data.pagination)
        } else {
          setInbox(data.data)
          setPagination(data.pagination)
        }
        if (data.data.length > 0) setSelected(data.data[0].id)
      })
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <Grid container style={{ height: '100%' }}>
        <InboxListGrid item xs={12} md={4}>
          <ViewSearchGrid container alignItems="center">
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="change-view"
            >
              <ToggleButton value="inbox" aria-label="inbox">
                <Tooltip classes={classes} arrow title="Caixa de entrada">
                  <InboxFill size={24} />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="archive" aria-label="archive">
                <Tooltip classes={classes} arrow title="Arquivo">
                  <Archive size={24} />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
            {showSearch && (
              <TextField
                autoFocus
                style={{ flex: 1 }}
                placeholder="Procura pelo nome ou assunto"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: search.trim().length > 0 && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')} label="clear-search">
                        <CloseOutline size={22} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={handleToggleSearch}
              aria-label="search-inbox"
            >
              <Search size={25} />
            </IconButton>
            {isNotDesktop && (
              <IconButton onClick={() => setListOpen((prev) => !prev)}>
                {listOpen ? <UpArrowAlt size={28} /> : <DownArrowAlt size={28} />}
              </IconButton>
            )}
          </ViewSearchGrid>
          {(!isNotDesktop || listOpen) && (
            <>
              <FilterGrid container alignItems="center">
                {view === 'inbox' && (
                  <>
                    <FilterIcon size={26} />
                    <StyledSelect
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="recent">Mais recentes</MenuItem>
                      <MenuItem value="older">Mais antigos</MenuItem>
                      <MenuItem value="notanswered">Por responder</MenuItem>
                    </StyledSelect>
                  </>
                )}
              </FilterGrid>
              <Grid container>
                <List
                  contacts={
                    search.trim().length > 1
                      ? searchResults
                      : view === 'inbox'
                      ? inbox || []
                      : archive || []
                  }
                  selected={selected}
                  handleClick={handleClick}
                  handleAction={handleAction}
                />
                <Grid container justify="center" style={{ margin: '20px 0' }}>
                  {view === 'inbox' ? (
                    <Pagination
                      count={pagination.lastPage}
                      page={pagination.page}
                      onChange={handlePagination}
                      shape="rounded"
                      showFirstButton
                      showLastButton
                      size="large"
                      color="primary"
                    />
                  ) : (
                    <Pagination
                      count={archivePagination.lastPage}
                      page={archivePagination.page}
                      onChange={handlePagination}
                      shape="rounded"
                      showFirstButton
                      showLastButton
                      size="large"
                      color="primary"
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </InboxListGrid>
        <Grid item xs={12} md={8}>
          <Details
            contact={
              view === 'inbox'
                ? inbox && inbox.find((contact) => contact.id === selected)
                : archive && archive.find((contact) => contact.id === selected)
            }
            handleReplyUpdate={handleReplyUpdate}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
export default Contacts
