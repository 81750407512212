import { Link, TextField, Typography } from '@material-ui/core'
import { ArrowDownShort } from '@styled-icons/bootstrap/ArrowDownShort'
import { ArrowUpShort } from '@styled-icons/bootstrap/ArrowUpShort'
import { ContainerCard } from 'pages/styles'
import styled from 'styled-components'

const parsePercentageColor = (theme, invert = 'false', percentage) => {
  if (percentage < 100) {
    return invert === 'true' ? theme.palette.success.main : theme.palette.error.main
  }
  if (percentage === 100) {
    return theme.palette.info.main
  }
  if (percentage > 100) {
    return invert === 'true' ? theme.palette.error.main : theme.palette.success.main
  }
  return theme.palette.text.primary
}

const parseStatusColor = (theme, status) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.main
    case 'paid':
      return theme.palette.info.main
    case 'scheduled':
      return theme.palette.success.main
    case 'canceled':
      return theme.palette.error.main
    default:
      return theme.palette.grey[100]
  }
}

export const StyledContainerCard = styled(ContainerCard)`
  border-radius: 3px;
  height: 130px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 20px;
    }
  `};
`

export const DownStat = styled(ArrowDownShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.success.main : theme.palette.error.main};
  margin-left: 5px;
`

export const UpStat = styled(ArrowUpShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.error.main : theme.palette.success.main};
  margin-left: 5px;
`

export const Percentage = styled(Typography)`
  color: ${({ percentage, invert, theme }) =>
    parsePercentageColor(theme, invert, percentage)};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StatusDot = styled.div`
  background-color: ${({ status, theme }) => parseStatusColor(theme, status)};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    height: 54px;
    & fieldset {
      border: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }

  & .MuiFilledInput-input {
    padding: 10px 12px 10px 0px;
  }

  & .MuiInputAdornment-positionStart {
    margin-top: 5px !important;
  }

  & .Mui-focused {
    background-color: ${({ theme }) => theme.palette.background.paper};
    & fieldset {
      border: 1px solid black;
    }
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & svg {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`

export const TemplateLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
