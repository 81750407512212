import { SliderPicker } from 'react-color'

import { Button, TextField } from '@material-ui/core'
import styled from 'styled-components'

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`

export const CoverWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1.5px dashed ${({ theme }) => theme.palette.text.secondary};

  & img {
    width: 100%;
  }
`

export const UploadButton = styled(Button)`
  height: 50px;
`

export const StyledSliderPicker = styled(SliderPicker)`
  &.slider-picker {
    flex: 1;
  }
`
