import { api } from 'api'
import Cookies from 'js-cookie'
import {
  SET_THEME,
  STORAGE_THEME,
  SET_INBOX_COUNT,
  AUTH_TOKEN,
  SET_NOTIFICATION,
  SET_TESTIMONIALS_COUNT,
  SET_VOUCHERS_COUNT,
} from 'state/constants'

export default (props) => {
  return {
    getTheme: () => {
      const theme = localStorage.getItem(STORAGE_THEME)
      if (theme) {
        props.dispatch({ type: SET_THEME, theme: theme })
      }
    },
    setTheme: (theme) => {
      localStorage.setItem(STORAGE_THEME, theme)
      props.dispatch({ type: SET_THEME, theme: theme })
    },
    getInboxCount: () => {
      const token = Cookies.get(AUTH_TOKEN)
      if (token) {
        api
          .get('/inboxes/new')
          .then(({ data }) => {
            props.dispatch({
              type: SET_INBOX_COUNT,
              count: data.count,
            })
          })
          .catch((err) =>
            props.dispatch({
              type: SET_NOTIFICATION,
              notification: { status: 'error', message: err.message },
            })
          )
      }
    },
    getTestimonialsCount: () => {
      const token = Cookies.get(AUTH_TOKEN)
      if (token) {
        api
          .get('/testimonies/pending')
          .then(({ data }) => {
            props.dispatch({
              type: SET_TESTIMONIALS_COUNT,
              count: data.count,
            })
          })
          .catch((err) =>
            props.dispatch({
              type: SET_NOTIFICATION,
              notification: { status: 'error', message: err.message },
            })
          )
      }
    },
    getVouchersCount: () => {
      const token = Cookies.get(AUTH_TOKEN)
      if (token) {
        api
          .get('/vouchers/asd/pending')
          .then(({ data }) => {
            props.dispatch({
              type: SET_VOUCHERS_COUNT,
              count: data.count,
            })
          })
          .catch((err) =>
            props.dispatch({
              type: SET_NOTIFICATION,
              notification: { status: 'error', message: err.message },
            })
          )
      }
    },
  }
}
