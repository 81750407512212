import { CardContent, Avatar, Typography, Grid, Chip } from '@material-ui/core'
import { Edit2 } from '@styled-icons/remix-line/Edit2'
import styled from 'styled-components'

export const Content = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 25px;
  padding-bottom: 15px !important;
`

export const UserWrapper = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const UserAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.background};
`

export const UserName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  margin-left: 15px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${
      status === 'true'
        ? theme.palette.primary.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.primary.main : theme.palette.warning.main};
  `}
`

export const EditButton = styled(Edit2)`
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  transition: opacity 400ms ease;

  &:hover {
    opacity: 0.7;
  }
`
