import { Button } from '@material-ui/core'
import styled from 'styled-components'

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 20px;
`

export const StyledButton = styled(Button)`
  height: auto;
  padding: 0;
  text-transform: none;
  transition: opacity 400ms ease;

  &:hover {
    background-color: transparent;
    opacity: 0.7;
  }
`
