import { Button, Chip, Grid } from '@material-ui/core'
import parseApptStatusBgColor from 'helpers/parse-appt-status-bg-color'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import styled from 'styled-components'

export const NewApptButton = styled(Button)`
  height: 54px;

  & .MuiButton-startIcon svg {
    color: ${({ theme }) => theme.palette.primary.main};
    transition: color 400ms ease;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    & .MuiButton-startIcon svg {
      color: white;
    }
  }
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${parseApptStatusBgColor(status, theme)};
    color: ${parseApptStatusColor(status, theme)};
  `}
  .MuiChip-label {
    font-weight: 600;
  }
`

export const SearchGrid = styled(Grid)`
  order: 1;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 2;
    }
  `};
`

export const NewApptGrid = styled(Grid)`
  order: 2;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      order: 1;
    }
  `};
`
