import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const LabelGrid = styled(Grid)`
  border-radius: 3px;
  padding: 14px;
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-bottom: 15px;
`

export const Title = styled(Typography)`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.default};
  margin: 20px 0;
`
