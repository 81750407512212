import React, { useEffect, useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { api } from 'api'

import { Container, StyledCard } from './styles'

const AppointmentsQuickStats = () => {
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({ week: 0, month: 0, earnings: 0 })

  useEffect(() => {
    setLoading(true)
    api.get('/appointments/quick-stats').then(({ data }) => {
      setStats(data)
      setLoading(false)
    })
  }, [])

  return (
    <Container container direction="column" justify="space-between">
      <StyledCard type="first">
        <Grid container direction="column">
          <Typography variant="h3" color="secondary" gutterBottom>
            {loading ? '...' : stats.week}
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            Marcações esta semana
          </Typography>
        </Grid>
      </StyledCard>
      <StyledCard type="second">
        <Grid container direction="column">
          <Typography variant="h3" color="secondary" gutterBottom>
            {loading ? '...' : stats.month}
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            Marcações este mês
          </Typography>
        </Grid>
      </StyledCard>
      <StyledCard type="third">
        <Grid container direction="column">
          <Typography variant="h3" color="secondary" gutterBottom>
            {loading ? '...' : `${(+stats.earnings.toFixed(2)).toLocaleString()} €`}
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            Ganhos este mês
          </Typography>
        </Grid>
      </StyledCard>
    </Container>
  )
}

export default AppointmentsQuickStats
