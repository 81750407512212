import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Typography, Grid } from '@material-ui/core'
import { Check2Circle } from '@styled-icons/bootstrap/Check2Circle'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { ErrorWarning } from '@styled-icons/remix-line/ErrorWarning'
import { api } from 'api'
import moment from 'moment'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import Editor from './Editor'
import {
  Container,
  Subject,
  StyledChip,
  ReplyForm,
  SubmitButton,
  ReplyContainer,
} from './styles'

const Details = ({ contact, handleReplyUpdate }) => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    register({ name: 'response' })
    setValue('response', '')
  }, [register, setValue])

  const onSubmit = (data) => {
    api
      .patch(`/inboxes/${contact.id}`, data)
      .then(({ data }) => {
        if (data.contact) {
          handleReplyUpdate(data.contact)
          actions.notification.setNotification('success', 'Resposta enviada com sucesso')
        } else {
          actions.notification.setNotification(
            'error',
            'Não foi possível retornar o contacto atualizado'
          )
        }
      })
      .catch((e) => {
        actions.notification.setNotification('error', `Ocorreu um erro: ${e}`)
      })
  }

  if (!contact) {
    return (
      <Container container direction="column" alignItems="center">
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ width: '50%', fontWeight: 400, textAlign: 'center' }}
        >
          Seleciona um contacto na lista à esquerda
        </Typography>
      </Container>
    )
  }

  return (
    <Container container direction="column">
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{ textTransform: 'capitalize' }}
        gutterBottom
      >
        {moment(contact.created_at).format('DD MMMM YYYY HH:mm')}
      </Typography>
      <Subject variant="h3">{contact.subject}</Subject>
      <Grid container alignItems="center" style={{ margin: '20px 0 30px 0' }}>
        <Typography variant="subtitle1" color="textSecondary">
          {contact.name} &bull; {contact.email}
        </Typography>
        <StyledChip
          icon={
            contact.registered ? <Check2Circle size={22} /> : <ErrorWarning size={22} />
          }
          label={contact.registered ? 'Registado' : 'Não registado'}
          clickable={!!contact.registered}
          onClick={() =>
            contact.registered ? history.push(`/users/${contact.registered.id}/info`) : {}
          }
          type={contact.registered ? 'success' : 'info'}
          onDelete={
            contact.registered
              ? () => history.push(`/users/${contact.registered.id}/info`)
              : null
          }
          deleteIcon={contact.registered ? <ChevronRight size={18} /> : null}
        />
      </Grid>
      <Typography variant="body1" style={{ lineHeight: 1.8 }}>
        {contact.message}
      </Typography>
      {contact.response ? (
        <div style={{ margin: '30px 0' }}>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Resposta enviada &bull;{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {moment(contact.updated_at).format('DD MMMM YYYY HH:mm')}
            </span>
          </Typography>
          <ReplyContainer container direction="column">
            <div dangerouslySetInnerHTML={{ __html: contact.response }} />
          </ReplyContainer>
        </div>
      ) : (
        <form
          style={{ width: '100%', textAlign: 'right' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ReplyForm container direction="column">
            <Editor name="response" setValue={(name, value) => setValue(name, value)} />
          </ReplyForm>
          <SubmitButton type="submit" variant="contained" size="large" color="primary">
            Enviar
          </SubmitButton>
        </form>
      )}
    </Container>
  )
}

Details.propTypes = {
  contact: PropTypes.object,
  handleReplyUpdate: PropTypes.func.isRequired,
}

Details.defaultProps = {
  contact: null,
}

export default Details
