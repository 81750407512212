import React, { useEffect, useState } from 'react'

import { CircularProgress, useTheme } from '@material-ui/core'
import { api } from 'api'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import styled from 'styled-components'

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 8px;
  width: 100%;
  height: 260px;
  border-radius: 10px;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props
  return (
    <g>
      <text x={cx} y={cy} dy={4} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={21} textAnchor="middle" fill={fill}>
        {(percent * 100).toFixed(2)} %
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  )
}

const UsersAndGuests = () => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    setLoading(true)
    api.get('/users/clients-vs-guests').then(({ data: d }) => {
      setLoading(false)
      setData(d)
    })
  }, [])

  return (
    <StyledCard>
      {loading && (
        <LoadingWrapper>
          <CircularProgress style={{ marginTop: 20 }} />
        </LoadingWrapper>
      )}
      {!loading && data && (
        <ResponsiveContainer>
          <PieChart width={200} height={200}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#ffffff"
              dataKey="value"
              onMouseEnter={onPieEnter}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  stroke={0}
                  fill={
                    index === 0
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    </StyledCard>
  )
}

export default UsersAndGuests
