import React from 'react'

import { CardContent, Chip, Grid, Link, TextField, Typography } from '@material-ui/core'
import { Heart } from '@styled-icons/bootstrap/Heart'
import { ContainerCard } from 'pages/styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    height: 54px;
    & fieldset {
      border: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }

  & .MuiFilledInput-input {
    padding: 10px 12px 10px 0px;
  }

  & .MuiInputAdornment-positionStart {
    margin-top: 5px !important;
  }

  & .Mui-focused {
    background-color: ${({ theme }) => theme.palette.background.paper};
    & fieldset {
      border: 1px solid black;
    }
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & svg {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`

export const StyledContainerCard = styled(ContainerCard)`
  border-radius: 3px;
  height: 130px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 20px;
    }
  `};
`

export const BlogCategoriesLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StatusDot = styled.div`
  background-color: ${({ status, theme }) =>
    status === 'pending' ? theme.palette.warning.main : theme.palette.success.main};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
`

export const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-right: 3px;

  .MuiChip-label {
    font-weight: 500;
    padding: 0 24px;
  }

  ${({ clickable, ccolor, theme }) =>
    clickable &&
    `
    height: 40px;
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: ${theme.palette.background.paper};
    margin-right: 5px;

    &:hover, &:focus {
      background-color: rgba(${ccolor}, 0.5);
    }
  `}

  ${({ active, ccolor }) =>
    active === 'true' &&
    `
    border: 1px solid rgb(${ccolor});
    background-color: rgba(${ccolor}, 0.3);
  `}

  ${({ clickable, ccolor }) =>
    !clickable &&
    ccolor &&
    `
    background-color: rgba(${ccolor}, 0.3);
  `}

  ${({ status, theme }) =>
    status &&
    `
    background-color: ${
      status === 'true'
        ? theme.palette.success.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.success.main : theme.palette.warning.main};
  `}
`

const LikeWrapper = styled(Grid)``

const LikeNumber = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`

const StyledHeart = styled(Heart)`
  color: ${({ theme }) => theme.palette.error.light};
  margin-left: 7px;
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  padding-bottom: 12px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Likes = ({ count }) => (
  <LikeWrapper container alignItems="center">
    <LikeNumber variant="subtitle1">{count}</LikeNumber>
    <StyledHeart size={20} />
  </LikeWrapper>
)
Likes.propTypes = {
  count: PropTypes.string,
}
Likes.defaultProps = {
  count: '0',
}

export const ButtonGrid = styled(Grid)`
  text-align: right;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`
