import React from 'react'
import { Link } from 'react-router-dom'

import { Grid, Typography, CardContent } from '@material-ui/core'
import { CheckDouble } from '@styled-icons/boxicons-regular/CheckDouble'
import { History } from '@styled-icons/boxicons-regular/History'
import { Time } from '@styled-icons/boxicons-regular/Time'
import { Wallet } from '@styled-icons/boxicons-regular/Wallet'
import PropTypes from 'prop-types'

import {
  StyledCard,
  AvatarGrid,
  CreatedAtLabel,
  CreatedAtDate,
  StatusAvatar,
  StyledChip,
  TimelineGrid,
  StyledCardActions,
  UserName,
  SeeMoreButton,
  StyledAvatar,
  AvatarName,
} from './styles'
import Timeline from './Timeline'

const UserCard = ({ user }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StyledCard variant="outlined">
      <CardContent>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <AvatarGrid item>
                <StyledAvatar alt={user.name} src={user.photo_url}>
                  <AvatarName variant="subtitle1">
                    {`${user.name[0]}${
                      user.name.split(' ').length > 1 ? user.name.split(' ')[1][0] : ''
                    }`}
                  </AvatarName>
                </StyledAvatar>
              </AvatarGrid>
              <Grid item>
                <UserName variant="subtitle1">{user.name}</UserName>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                >
                  <CreatedAtLabel variant="body2" color="textSecondary">
                    Inscrito desde:
                  </CreatedAtLabel>
                  <CreatedAtDate variant="subtitle2" color="textSecondary">
                    {user.created_at}
                  </CreatedAtDate>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <StatusAvatar className={user.status ? 'success' : 'pending'}>
              {user.status ? <CheckDouble size={26} /> : <Time size={24} />}
            </StatusAvatar>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <StyledChip
              label={`${user.visit_count} visita${user.visit_count !== 1 ? 's' : ''}`}
              icon={<History size={24} />}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <StyledChip label={`${user.earnings} €`} icon={<Wallet size={22} />} />
          </Grid>
        </Grid>
      </CardContent>
      <TimelineGrid>
        <Timeline appointments={user.last_appointments} />
      </TimelineGrid>
      <StyledCardActions>
        <SeeMoreButton
          component={Link}
          to={`/users/${user.id}/info`}
          color="primary"
          style={{ textTransform: 'inherit', height: 55 }}
        >
          <Typography variant="subtitle2" color="textSecondary">
            Ver Mais
          </Typography>
        </SeeMoreButton>
      </StyledCardActions>
    </StyledCard>
  </Grid>
)

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserCard
