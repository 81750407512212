import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import { ReactComponent as UserFemaleIcon } from 'assets/icons/female-user.svg'
import { ReactComponent as UserMaleIcon } from 'assets/icons/male-user.svg'
import moment from 'moment'
import { StyledCard } from 'pages/Testimonials/styles'
import PropTypes from 'prop-types'

import {
  Content,
  UserAvatar,
  UserName,
  UserWrapper,
  StyledChip,
  EditButton,
} from './styles'

const Card = ({ testimony, handleOpenEdit }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StyledCard variant="outlined">
      <Content>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 15 }}
        >
          <StyledChip
            status={testimony.status ? 'true' : 'false'}
            label={testimony.status ? 'Publicado' : 'Pendente'}
          />
          <EditButton onClick={() => handleOpenEdit(testimony)} size={25} />
        </Grid>
        <Typography variant="body1" style={{ lineHeight: 1.7 }}>
          {testimony.testimony}
        </Typography>
        <Grid container direction="row" alignItems="center" style={{ marginTop: 20 }}>
          <UserWrapper item>
            <UserAvatar
              alt={testimony.user?.name}
              src={
                testimony.show_photo && testimony.user?.photo
                  ? testimony.user?.photo_url
                  : ''
              }
            >
              {testimony.user && !testimony.user?.gender && (
                <PermIdentityIcon color="primary" style={{ fontSize: 32 }} />
              )}
              {testimony.user?.gender &&
                (testimony.user?.gender === 'M' ? <UserMaleIcon /> : <UserFemaleIcon />)}
              {!testimony.user && <CloseIcon color="primary" style={{ fontSize: 32 }} />}
            </UserAvatar>
            <UserName variant="subtitle2">
              {testimony.user ? testimony.user?.name : 'Cliente removido'}
            </UserName>
          </UserWrapper>
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: 'capitalize' }}
            >
              {moment(testimony.created_at).format('DD MMM YY HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Content>
    </StyledCard>
  </Grid>
)

Card.propTypes = {
  testimony: PropTypes.object.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
}

export default Card
