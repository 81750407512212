import { Grid, IconButton, Typography } from '@material-ui/core'
import { Moon } from '@styled-icons/boxicons-regular/Moon'
import { Sun } from '@styled-icons/octicons/Sun'
import styled from 'styled-components'

export const Container = styled(Grid)`
  padding: 20px 30px;
  min-height: 80px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.menu.divider}`};
`

export const LogoIcon = styled.img`
  width: 50px;
  margin-right: 10px;
`

export const CompanyTitle = styled(Typography)`
  font-weight: 600;
  line-height: 1.1;
`

export const CompanySubtitle = styled(Typography)`
  font-weight: 600;
  line-height: 1.1;
`

export const SwitchWrapper = styled(IconButton)`
  &:hover svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const SwitchLight = styled(Moon)`
  color: ${({ theme }) => theme.palette.menu.icon};
`

export const SwitchDark = styled(Sun)`
  color: ${({ theme }) => theme.palette.menu.icon};
`
