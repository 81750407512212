import React from 'react'

import { Avatar, Grid, Typography } from '@material-ui/core'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import { ReactComponent as UserFemaleIcon } from 'assets/icons/female-user.svg'
import { ReactComponent as UserMaleIcon } from 'assets/icons/male-user.svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
`

export const UserCardGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px;
  min-height: 80px;
  border-radius: 10px;
`

export const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ status, theme }) =>
    status === 'finished' &&
    `
    background-color: ${theme.palette.success.main};
  `}
  ${({ status, theme }) =>
    status === 'canceled' &&
    `
    background-color: ${theme.palette.error.main};
  `}
  ${({ status, theme }) =>
    status === 'absent' &&
    `
    background-color: ${theme.palette.pink.light};
  `}
`

export const StyledAvatar = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(7)}px`};
  height: ${({ theme }) => `${theme.spacing(7)}px`};
  background-color: ${({ theme }) => theme.palette.background.paper};
`

export const UserCard = ({ user }) => (
  <UserCardGrid container alignItems="center">
    <Grid item xs="auto" style={{ marginRight: 15 }}>
      <StyledAvatar alt={user.name} src={user.photo_url}>
        {user && !user.gender && (
          <PermIdentityIcon color="primary" style={{ fontSize: 32 }} />
        )}
        {user.gender && (user.gender === 'M' ? <UserMaleIcon /> : <UserFemaleIcon />)}
      </StyledAvatar>
    </Grid>
    <Grid item style={{ flex: 1 }}>
      <Grid container direction="column">
        <Typography variant="subtitle2" gutterBottom>
          {user.name}
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Dot status="finished" />
              <Typography variant="subtitle2">{user.finished}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Dot status="absent" />
              <Typography variant="subtitle2">{user.absent}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Dot status="canceled" />
              <Typography variant="subtitle2">{user.canceled}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </UserCardGrid>
)

UserCard.propTypes = {
  user: PropTypes.object,
}
