export default (status, theme) => {
  switch (status) {
    case 'confirm-guest':
      return theme.palette.yellow.main
    case 'pending':
      return theme.palette.warning.main
    case 'confirmed':
      return theme.palette.info.main
    case 'paid':
      return theme.palette.primary.main
    case 'finished':
      return theme.palette.success.main
    case 'canceled':
      return theme.palette.error.main
    case 'expired':
      return theme.palette.error.dark
    case 'absent':
      return theme.palette.pink.light
    default:
      return theme.palette.grey[100]
  }
}
