import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
`

const TimePassed = styled(Grid)`
  width: 50px;
  height: 50px;
  padding: 5px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 0.7rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.menu.text};
`

const NewUserContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.8;
  }
`

export const NewUser = ({ user }) => (
  <NewUserContainer container alignItems="center">
    <Grid item xs="auto">
      <TimePassed container justify="center" alignItems="center">
        {moment(user.all_info.created_at_ts, 'YYYY-MM-DDTHH:mm:ssZ').fromNow()}
      </TimePassed>
    </Grid>
    <Grid item xs="auto" style={{ flex: 1, textAlign: 'left' }}>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }} className="service">
        {user.name}
      </Typography>
      <Typography variant="body2" style={{ margin: '5px 0' }}>
        Via{' '}
        {user.all_info.source === 'form' ? (
          'formulário'
        ) : (
          <span style={{ textTransform: 'capitalize' }}>{user.all_info.source}</span>
        )}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ color: user.all_info.email_verified ? '#4caf50' : '#ff9800' }}
      >
        Conta {user.all_info.email_verified ? '' : 'não'} confirmada
      </Typography>
    </Grid>
  </NewUserContainer>
)

NewUser.propTypes = {
  user: PropTypes.object.isRequired,
}

export const NewUsersGrid = styled(Grid)`
  width: 100%;
  margin-top: 10px;
`
