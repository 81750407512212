import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import BlogCategories from './Categories'
import EditBlogPost from './Edit'
import BlogList from './List'
import NewBlogPost from './New'

const Blog = () => (
  <Switch>
    <Route path="/blog/:id/edit">
      <EditBlogPost />
    </Route>
    <Route path="/blog/new">
      <NewBlogPost />
    </Route>
    <Route path="/blog/categories">
      <BlogCategories />
    </Route>
    <Route exact path="/blog">
      <BlogList />
    </Route>
    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
)
export default Blog
