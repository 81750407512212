import {
  SET_THEME,
  SET_INBOX_COUNT,
  SET_TESTIMONIALS_COUNT,
  SET_VOUCHERS_COUNT,
} from 'state/constants'

export default (state, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
      }

    case SET_INBOX_COUNT:
      return {
        ...state,
        inbox_count: action.count,
      }

    case SET_TESTIMONIALS_COUNT:
      return {
        ...state,
        testimonials_count: action.count,
      }

    case SET_VOUCHERS_COUNT:
      return {
        ...state,
        vouchers_count: action.count,
      }

    default:
      return state
  }
}
