import React, { useState } from 'react'

import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'

import AppointmentsByStatus from '../AppointmentsByStatus'
import EarnedByMonth from '../EarnedByMonth'

const StatsByYear = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  return (
    <Grid item xs={12} container spacing={2}>
      <Box
        width="100%"
        display="flex"
        gridGap={6}
        alignItems="center"
        justifyContent="center"
        mt={1}
        mb={1}
      >
        <IconButton onClick={() => setSelectedYear(selectedYear - 1)}>
          <ArrowLeft size={20} />
        </IconButton>
        <Typography variant="h5" color="primary">
          {selectedYear}
        </Typography>
        <IconButton onClick={() => setSelectedYear(selectedYear + 1)}>
          <ArrowRight size={20} />
        </IconButton>
      </Box>
      <Grid item xs={12} sm={12} md={6}>
        <AppointmentsByStatus year={selectedYear} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <EarnedByMonth year={selectedYear} />
      </Grid>
    </Grid>
  )
}

export default StatsByYear
