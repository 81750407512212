import { Chip } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import styled from 'styled-components'

const parseStatusColor = (status, theme) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.main

    case 'paid':
      return theme.palette.info.main

    case 'scheduled':
      return theme.palette.success.main

    case 'canceled':
      return theme.palette.error.main

    default:
      return theme.palette.primary.main
  }
}

const parseStatusBgColor = (status, theme) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.background

    case 'paid':
      return theme.palette.info.background

    case 'scheduled':
      return theme.palette.success.background

    case 'canceled':
      return theme.palette.error.background

    default:
      return theme.palette.primary.background
  }
}

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${parseStatusBgColor(status, theme)};
    color: ${parseStatusColor(status, theme)};
  `}
  .MuiChip-label {
    font-weight: 600;
  }
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      & button {
        width: 100%;
      }
    }
  `};

  & button {
    border-width: 0px;
    border-radius: 3px !important;
    padding: 12px 15px;

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.background};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
      }
    }
  }
`
