import React, { useContext, useEffect, useState } from 'react'
import { momentLocalizer } from 'react-big-calendar'

import { Container } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { api } from 'api'
import EventDetailsDialog from 'components/Schedule/EventDetailsDialog'
import NewEventDialog from 'components/Schedule/NewEventDialog'
import CustomToolbar from 'components/Schedule/Toolbar'
import LoadingPage from 'components/shared/LoadingPage'
import parseErrorMessage from 'helpers/parse-error-message'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { StateContext } from 'state'

import { StyledCalendar } from './styles'

const localizer = momentLocalizer(moment)

const Schedule = () => {
  const { actions } = useContext(StateContext)
  const [events, setEvents] = useState([])
  const [view, setView] = useState('week')
  const [loading, setLoading] = useState(true)
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [currentDate, setCurrentDate] = useState(moment())
  const [date, setDate] = useState({ start: null, end: null })
  const [selectedEvent, setSelectedEvent] = useState(null)

  useEffect(() => {
    if (date.start && date.end) {
      setOpenNew(true)
    }
  }, [date])

  const getSchedule = (currDate, currView) => {
    const startDate = currDate.clone().startOf(currView)
    const endDate = currDate.clone().endOf(currView)

    api
      .get(
        `/schedule/list?start_date=${startDate.format(
          'YYYY-MM-DD HH:mm:ss'
        )}&end_date=${endDate.format('YYYY-MM-DD HH:mm:ss')}`
      )
      .then(({ data }) => {
        const parsedData = data.map((ev) => ({
          id: ev.id,
          start: moment(ev.start_date, 'YYYY-MM-DDTHH:mm:ssZ').toDate(),
          end: moment(ev.end_date, 'YYYY-MM-DDTHH:mm:ssZ').toDate(),
          color: ev.appointmentsCount ? '#ff9800' : '#89AB15',
        }))
        setEvents(parsedData)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  useEffect(() => {
    getSchedule(currentDate, view)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, view])

  const handleNavigate = (date) => {
    const start = moment(date).startOf('week')
    const end = moment(date).endOf('week')
    if (moment().isBetween(start, end)) {
      if (currentDate < moment() || currentDate > moment()) {
        setCurrentDate(moment())
      }
    } else {
      if (currentDate < start || currentDate > start) {
        setCurrentDate(start)
      }
    }
  }

  const handleSelectEmptySlot = ({ start, end }) => {
    if (view === 'month') return
    if (
      start < new Date() ||
      end < new Date() ||
      start.getDate() !== end.getDate() ||
      start.getMonth() !== end.getMonth() ||
      start.getFullYear() !== end.getFullYear()
    ) {
      return
    }
    setDate({ start, end })
  }

  const handleSelectEvent = (event) => {
    setSelectedEvent(event)
    setOpenEdit(true)
  }

  const handleViewChange = (v) => {
    if (v !== view) {
      setView(v)
    }
  }

  const refreshEvents = () => {
    getSchedule(currentDate, view)
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: event.color,
        borderColor: darken(event.color, 0.15),
      },
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <StyledCalendar
        selectable
        events={events}
        defaultView="week"
        localizer={localizer}
        defaultDate={moment().toDate()}
        onNavigate={handleNavigate}
        onView={handleViewChange}
        titleAccessor="id"
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectEmptySlot}
        eventPropGetter={eventStyleGetter}
        components={{ toolbar: CustomToolbar }}
        min={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            7,
            0,
            0
          )
        }
        max={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            22,
            0,
            0
          )
        }
        views={['month', 'week']}
        style={{ height: 'calc(100vh + 30px)' }}
        messages={{
          today: 'Hoje',
          previous: '<',
          next: '>',
          month: 'Mês',
          week: 'Semana',
        }}
      />
      <NewEventDialog
        open={openNew}
        setOpen={setOpenNew}
        date={date}
        setDate={setDate}
        refreshEvents={refreshEvents}
      />
      <EventDetailsDialog
        open={openEdit}
        setOpen={setOpenEdit}
        event={selectedEvent}
        setEvent={setSelectedEvent}
        refreshEvents={refreshEvents}
      />
    </Container>
  )
}

export default Schedule
