import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.menu.text};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  text-align: left;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
  border: 1.5px solid ${({ theme }) => theme.palette.primary.main};
  transition: background-color 200ms ease;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.background};
  }
`
