import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { api } from 'api'

import { StyledCard, NewApptsGrid, NewAppt } from './styles'

const LatestAppointments = () => {
  const [loading, setLoading] = useState(false)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get('/appointments/latest').then(({ data }) => {
      setLoading(false)
      setAppointments(data)
    })
  }, [])

  return (
    <StyledCard>
      <Grid container direction="column" alignItems="flex-start">
        <Typography variant="subtitle1">Últimos agendamentos</Typography>
        <NewApptsGrid>
          {loading && <CircularProgress style={{ marginTop: 20 }} />}
          {!loading &&
            appointments.map((appointment) => (
              <Link
                key={appointment.id}
                to={`/appointments/${appointment.id}/info`}
                style={{ textDecoration: 'none' }}
              >
                <NewAppt appointment={appointment} />
              </Link>
            ))}
        </NewApptsGrid>
      </Grid>
    </StyledCard>
  )
}

export default LatestAppointments
