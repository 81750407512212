import { Grid, Typography, Chip, Button } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  height: 100%;
  padding: 90px 20px 20px 40px;
  background-color: ${({ theme }) => theme.palette.background.paper};

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      padding: 20px;
    }
  `};
`

export const Subject = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`

export const StyledChip = styled(Chip)`
  margin-left: 10px;

  ${({ type, theme }) =>
    type &&
    `
    background-color: ${theme.palette[type].background};
    &, & svg {
      color: ${theme.palette[type].main};
    }

    &:hover {
      background-color: ${theme.palette[type].backgroundHover};
    }
  `}

  & .MuiChip-deleteIcon:hover {
    color: inherit;
  }
`

export const ReplyForm = styled(Grid)`
  padding: 10px;
  text-align: left;
  margin: 30px 0 20px 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 3px;
`

export const ReplyContainer = styled(Grid)`
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 3px;
  font-family: ${({ theme }) => theme.typography.fontFamily.split(',')[1]};
  font-size: 1rem;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.menu.text};

  & img {
    width: 100%;
  }

  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const SubmitButton = styled(Button)`
  text-transform: initial;
  padding: 10px 35px;
`
