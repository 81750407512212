import React from 'react'

import {
  Tabs,
  Tab,
  LinearProgress,
  Grid,
  CardContent,
  Typography,
} from '@material-ui/core'
import { Clock } from '@styled-icons/evil/Clock'
import { Gift } from '@styled-icons/octicons/Gift'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StepWrapper = styled.div`
  padding: 10px;
  background-color: rgba(137, 171, 21, 0.2);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  transition: background-color 500ms ease;
`

export const StyledTab = styled(Tab)`
  text-transform: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  letter-spacing: 0.03rem;

  & .MuiTab-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > *:first-child {
      margin-bottom: 0;
    }

    ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      font-size: 0.8rem;

      & > *:first-child {
        margin: 0 0 7px 0;
      }
    }
  `};
  }

  &.Mui-selected {
    opacity: 1;
    font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};

    & ${StepWrapper} {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;
    }
  }
`

export const StyledTabs = styled(Tabs)`
  margin-bottom: 10px;

  & .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  & .MuiTabs-indicator {
    display: none;
  }
`

export const StyledLinearProgress = styled(LinearProgress)`
  height: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]};

  & .MuiLinearProgress-bar {
    border-radius: 4px;
  }
`

export const StepsWrapper = styled(Grid)`
  margin: 20px auto 30px auto;
`

export const ButtonsGrid = styled(Grid)`
  position: absolute;
  height: 86px;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 24px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      background-color: ${theme.palette.background.paper};
      height: 95px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: fixed;
      bottom: 0;
      top: auto;
      z-index: 10;
      box-shadow: 0px -10px 20px rgba(137, 171, 21, 0.05);

      & button:hover {
        box-shadow: 0px 10px 20px rgba(137, 171, 21, 0.2);
      }

      & .MuiGrid-root:last-child button {
        box-shadow: inherit;
      }
    }
    ${theme.breakpoints.down('xs')} {
      & button {
        font-size: 0.8em;
      }
    }
  `};
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContentGrid = styled(Grid)`
  position: relative;
  width: 100%;
`

export const SearchWrapper = styled.div`
  width: 60%;
  margin: 0 auto 40px auto;
`

const StyledIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-right: 40px;

  ${({ theme }) => `
    ${theme.breakpoints.down('lg')} {
      margin-right: auto;
    }
  `};
`

export const GiftIcon = styled(Gift)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 32px;
`

export const StyledIcon = () => (
  <StyledIconWrapper>
    <GiftIcon />
  </StyledIconWrapper>
)

export const VoucherInfoContainer = styled(Grid)`
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  padding: 15px 20px;
  box-shadow: ${({ theme }) => `0px 10px 20px ${theme.palette.secondary.shadow}`};

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      padding: 20px 5px;
      text-align: center;

      & > div:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  `};
`

export const VoucherInfoLabel = styled(Typography)`
  opacity: 0.9;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const VoucherInfoText = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  color: ${({ theme }) => theme.palette.secondary.main};
`

const VoucherInfoDurationWrapper = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      justify-content: center;
    }
  `};
`

const VoucherInfoDurationText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
`

const StyledClock = styled(Clock)`
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.secondary.dark};
`

export const VoucherInfoDuration = ({ duration }) => (
  <VoucherInfoDurationWrapper container alignItems="center">
    <StyledClock size={20} />
    <VoucherInfoDurationText variant="subtitle1">{duration}</VoucherInfoDurationText>
  </VoucherInfoDurationWrapper>
)
VoucherInfoDuration.propTypes = {
  duration: PropTypes.string.isRequired,
}

export const ConfirmationBg = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  background: -moz-linear-gradient(
    top,
    rgba(136, 171, 21, 1) 0%,
    rgba(85, 116, 44, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(136, 171, 21, 1)),
    color-stop(100%, rgba(85, 116, 44, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(136, 171, 21, 1) 0%,
    rgba(85, 116, 44, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(136, 171, 21, 1) 0%,
    rgba(85, 116, 44, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(136, 171, 21, 1) 0%,
    rgba(85, 116, 44, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(136, 171, 21, 1) 0%,
    rgba(85, 116, 44, 1) 100%
  );
`

export const ConfirmationWrapper = styled.div`
  padding: 80px 0;
  text-align: center;
`

export const ConfirmationTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: white;
`

export const ConfirmationDescription = styled(Typography)`
  text-align: center;
  margin: 15px 0;
  letter-spacing: 0.03rem;
  opacity: 0.8;
  color: white;
`

export const Timer = styled(Typography)`
  text-align: center;
  margin: 20px 0 10px 0;
  letter-spacing: 0.03rem;
  color: white;
`

export const CardGrid = styled(Grid)`
  padding: 30px 50px;
`

export const Label = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  margin-bottom: 20px;
`

export const ListGrid = styled(Grid)`
  padding: 20px;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      margin-bottom: 100px;
    }
  `};
`
