import InitialState from 'state/initial'

import authReducer from './auth'
import globalReducer from './global'
import notificationReducer from './notification'

export default (state = InitialState, action) => {
  return {
    global: globalReducer(state.global, action),
    auth: authReducer(state.auth, action),
    notification: notificationReducer(state.notification, action),
  }
}
