import React, { useState } from 'react'

import {
  Grid,
  Typography,
  CardContent,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import RadioCard from 'components/shared/RadioCard'
import { ListGrid } from 'pages/Appointments/New/styles'
import { StyledDivider } from 'pages/Blog/New/styles'
import { StyledTextField } from 'pages/Users/List/styles'
import PropTypes from 'prop-types'

import {
  AnonymousTextField,
  AvatarName,
  StyledAvatar,
  StyledCardMedia,
  StyledChip,
} from './styles'

const User = ({ users, selectedUser, setSelectedUser }) => {
  const [search, setSearch] = useState('')

  const renderUserCard = (user) => (
    <Grid item xs={12} sm={6} lg={4} xl={3} key={user.id}>
      <RadioCard
        direction="row"
        selected={typeof selectedUser === 'object' && selectedUser?.id === user.id}
        onClick={() => setSelectedUser(user)}
        custom={{ height: '100%' }}
        actionArea={
          <>
            <StyledCardMedia component="div">
              <StyledAvatar alt={user.name} src={user.photo_url}>
                <AvatarName variant="subtitle1">
                  {`${user.name[0]}${
                    user.name.split(' ').length > 1 ? user.name.split(' ')[1][0] : ''
                  }`}
                </AvatarName>
              </StyledAvatar>
            </StyledCardMedia>
            <CardContent>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
                {user.name}
              </Typography>
              <StyledChip
                status={user.status.toString()}
                label={user.status ? 'Confirmado' : 'Pendente'}
              />
            </CardContent>
          </>
        }
      />
    </Grid>
  )

  return (
    <ListGrid container justify="center">
      <Grid container justify="center" alignItems="center">
        <Grid item xs="auto" style={{ marginRight: 10 }}>
          <Typography variant="subtitle1">Cliente sem conta?</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={5}>
          <AnonymousTextField
            variant="filled"
            name="user_name"
            fullWidth
            placeholder="Nome do cliente"
            value={typeof selectedUser === 'string' ? selectedUser : ''}
            onChange={(e) => setSelectedUser(e.target.value)}
          />
        </Grid>
      </Grid>
      <StyledDivider style={{ width: '80%' }} variant="middle" />
      <Grid item xs={12} sm={10} md={8} style={{ marginBottom: 30 }}>
        <StyledTextField
          placeholder="Procura pelo nome"
          variant="filled"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={27} />
              </InputAdornment>
            ),
            endAdornment: search.trim().length > 0 && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch('')} label="clear-search">
                  <CloseOutline size={27} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid container justify="center" spacing={3}>
        {users
          .filter(
            (usr) =>
              search.trim().length <= 0 ||
              usr.name.toLowerCase().includes(search.toLowerCase())
          )
          .map((user) => renderUserCard(user))}
      </Grid>
    </ListGrid>
  )
}

User.propTypes = {
  users: PropTypes.array,
  selectedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSelectedUser: PropTypes.func.isRequired,
}

User.defaultProps = {
  users: [],
  selectedUser: null,
}

export default User
