import React from 'react'

import { Grid, CardMedia, Typography } from '@material-ui/core'
import { Alarm } from '@styled-icons/boxicons-regular/Alarm'
import RadioCard from 'components/shared/RadioCard'
import parseDuration from 'helpers/parse-duration'
import { ListGrid } from 'pages/Appointments/New/styles'
import PropTypes from 'prop-types'

import { StyledCardContent } from './styles'

const Service = ({ services, selectedService, setSelectedService }) => {
  const renderServiceCard = (service) => (
    <Grid item xs={12} sm={6} key={service.id}>
      <RadioCard
        direction="row"
        selected={selectedService && selectedService.id === service.id}
        onClick={() => setSelectedService(service)}
        custom={{ height: '100%' }}
        actionArea={
          <>
            <CardMedia
              style={{ width: 130, minHeight: 110, height: '100%' }}
              component="img"
              alt={service.name}
              image={service.image_url}
            />
            <StyledCardContent>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {service.name}
              </Typography>
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  item
                  style={{ flex: 1, marginRight: 5 }}
                  container
                  alignItems="center"
                >
                  <Alarm size={20} style={{ opacity: 0.5, marginRight: 10 }} />
                  <Typography variant="subtitle2" color="textSecondary">
                    {parseDuration(service.duration)}
                  </Typography>
                </Grid>
                <Typography variant="h6" color="primary">
                  {service.price.toFixed(2)} €
                </Typography>
              </Grid>
            </StyledCardContent>
          </>
        }
      />
    </Grid>
  )

  return (
    <ListGrid container justify="center">
      <Grid container justify="center" spacing={3}>
        {services && services.map((service) => renderServiceCard(service))}
      </Grid>
    </ListGrid>
  )
}

Service.propTypes = {
  services: PropTypes.array,
  selectedService: PropTypes.object,
  setSelectedService: PropTypes.func.isRequired,
}

Service.defaultProps = {
  services: [],
  selectedService: null,
}

export default Service
