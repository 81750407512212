import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import { StyledCircularProgress } from './styles'

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <StyledCircularProgress />
      <Typography color="textSecondary" style={{ marginTop: 20 }} variant="h5">
        A carregar ...
      </Typography>
    </Grid>
  )
}

export default LoadingPage
