import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  max-width: 100%;

  ${({ error, theme }) =>
    error === 'true' &&
    `
    border: 1.5px solid ${theme.palette.error.main};
  `}

  & .tox-tinymce {
    border-color: ${({ theme }) => theme.palette.background.default};
  }

  & .tox-collection__group p {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  & .tox .tox-tbtn {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    height: 45px;

    &:not(.tox-tbtn--select) {
      width: 45px;
    }

    &:hover,
    &:focus,
    &:active,
    &.tox-tbtn--enabled {
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.paper};
      & svg {
        fill: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }

  & .tox .tox-split-button:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: none;
  }

  & .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
    border-right: ${({ theme }) => `1px solid ${theme.palette.background.paper}`};
  }

  & .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background: transparent;
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};

    & svg {
      fill: ${({ theme }) => theme.palette.text.primary};
    }

    & .tox-tbtn--disabled svg {
      opacity: 0.5;
    }
  }
`
