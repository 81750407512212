import React from 'react'

import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  withStyles,
  StepConnector,
} from '@material-ui/core'
import { Check } from '@styled-icons/boxicons-regular/Check'
import { RateReview } from '@styled-icons/material/RateReview'
import { User } from '@styled-icons/typicons/User'
import PropTypes from 'prop-types'

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
    left: '-35%',
    right: '65%',
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 1,
  },
}))(StepConnector)

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    border: `2px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.disabled,
    width: 45,
    height: 45,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: 'white',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  completed: {
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: theme.palette.primary.background,
  },
  label: {
    '& .MuiStepLabel-label': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.8rem',
    },
  },
}))

const ColorlibStepIcon = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles()

  const icons = {
    1: completed ? <Check size={30} /> : <User size={30} />,
    2: completed ? <Check size={30} /> : <RateReview size={24} />,
  }

  return (
    <div
      className={[
        classes.root,
        ...(active ? [classes.active] : []),
        ...(completed ? [classes.completed] : []),
      ].join(' ')}
    >
      {icons[icon]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

const Steps = ({ active, handleChange }) => {
  const classes = useColorlibStepIconStyles()

  return (
    <Stepper
      style={{ backgroundColor: 'transparent', padding: '0 24px', marginBottom: 15 }}
      alternativeLabel
      activeStep={active}
      connector={<ColorlibConnector />}
    >
      {['Cliente', 'Testemunho'].map((label) => (
        <Step key={label}>
          <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

Steps.propTypes = {
  active: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default Steps
