import React from 'react'

import { Card } from '@material-ui/core'
import { Check } from '@styled-icons/boxicons-regular/Check'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Direction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${({ direction }) =>
    direction &&
    `
    flex-direction: ${direction};
  `};
`

export const StyledCard = styled(Card)`
  height: 100%;
  border: ${({ theme }) => `2px solid ${theme.palette.primary.background}`};
  box-shadow: ${({ theme }) => `0px 10px 20px ${theme.palette.primary.shadow}`};

  &:hover {
    box-shadow: none;
  }

  ${({ selected, theme }) =>
    selected &&
    `
    border: 2px solid ${theme.palette.primary.main};
  `};

  & .MuiCardActionArea-root {
    border-radius: 3px;

    &:hover {
      & .MuiCardActionArea-focusHighlight {
        opacity: 0;
      }
    }
  }

  .checked-card {
    display: none;
    z-index: 1;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(-25px / 2.5);
    right: calc(-25px / 2.5);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const CheckedIconWrapper = styled.div`
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(-25px / 2.5);
  right: calc(-25px / 2.5);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};

  svg {
    color: white;
  }
`

export const CheckedIcon = () => (
  <CheckedIconWrapper>
    <Check size={18} />
  </CheckedIconWrapper>
)
