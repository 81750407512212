import { api } from 'api'
import Cookies from 'js-cookie'
import { AUTH_TOKEN, LOGIN_OK, LOGOUT } from 'state/constants'

export default (props) => {
  return {
    init: () => {
      return new Promise((fulfill, reject) => {
        const token = Cookies.get(AUTH_TOKEN)
        if (token) {
          fulfill()
          props.dispatch({
            type: LOGIN_OK,
            token,
          })
          return
        }
        reject()
      })
    },
    login: (password) => {
      const data = {
        password,
      }
      return new Promise((fulfill, reject) => {
        api
          .post('/login-admin', data)
          .then((response) => {
            const { data } = response
            if (data.token) {
              Cookies.set(AUTH_TOKEN, data.token, {
                expires: 7,
                path: '/',
                secure: true,
                sameSite: 'lax',
              })
              props.dispatch({
                type: LOGIN_OK,
                token: data.token,
              })
              fulfill()
            } else {
              reject(response)
            }
          })
          .catch((err) => reject(err))
      })
    },
    logout: (token) => {
      Cookies.remove(AUTH_TOKEN)
      props.dispatch({
        type: LOGOUT,
      })
    },
  }
}
