import React, { useContext, useEffect, useState } from 'react'

import { DialogContent, FormControlLabel } from '@material-ui/core'
import { Upload } from '@styled-icons/heroicons-outline/Upload'
import { api } from 'api'
import Dialog from 'components/shared/Dialog'
import IOSSwitch from 'components/shared/Switch'
import parseErrorMessage from 'helpers/parse-error-message'
import PropTypes from 'prop-types'
import { StateContext } from 'state'

import {
  StyledCardMedia,
  StyledTextField,
  UploadIconBackdrop,
  UploadLabel,
} from './styles'

const TemplatesEditDialog = ({ open, template, setTemplate }) => {
  const { actions } = useContext(StateContext)
  const [showUploadIcon, setShowUploadIcon] = useState(false)
  const [image, setImage] = useState({ file: null, preview: '' })
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState(true)

  const handleClose = () => {
    setTitle('')
    setStatus(true)
    setTemplate(null)
  }

  useEffect(() => {
    if (template) {
      setImage({ file: null, preview: template.image_url })
      setTitle(template.title)
      setStatus(template.status)
    }
  }, [template])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setImage({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const onSubmit = () => {
    if (title.trim().length <= 0) return

    const formData = new FormData()
    formData.append('title', title)
    formData.append('status', status)
    if (image.file) {
      formData.append('image', image.file)
    }

    api
      .patch(`/voucher-templates/${template.id}`, formData)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  if (!template) return null

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Editar template"
      onSave={title.trim().length > 0 ? onSubmit : null}
    >
      <UploadLabel
        htmlFor="edit-file"
        onMouseOver={() => setShowUploadIcon(true)}
        onMouseLeave={() => setShowUploadIcon(false)}
      >
        <input
          hidden
          id="edit-file"
          name="edit-file"
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={handleFileUpload}
        />
        <StyledCardMedia image={image.preview} title={title} />
        {showUploadIcon && (
          <UploadIconBackdrop>
            <Upload size={30} />
          </UploadIconBackdrop>
        )}
      </UploadLabel>
      <DialogContent style={{ padding: '15px 24px', textAlign: 'right' }}>
        <StyledTextField
          fullWidth
          variant="filled"
          autoFocus
          name="title"
          label="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <FormControlLabel
          style={{ marginTop: 15 }}
          control={
            <IOSSwitch
              checked={status}
              onChange={(e) => setStatus(e.target.checked)}
              name="status"
            />
          }
          labelPlacement="start"
          label="Ativar"
        />
      </DialogContent>
    </Dialog>
  )
}

TemplatesEditDialog.propTypes = {
  open: PropTypes.bool,
  setTemplate: PropTypes.func.isRequired,
  template: PropTypes.object,
}

TemplatesEditDialog.defaultProps = {
  open: false,
  template: null,
}

export default TemplatesEditDialog
