import { Calendar } from 'react-big-calendar'

import { Button, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCalendar = styled(Calendar)`
  &,
  & button {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .rbc-allday-cell {
    display: none;
  }
  & .rbc-time-view .rbc-header {
    border-bottom: none;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .rbc-current-time-indicator {
    height: 4px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  & .rbc-off-range-bg {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  & .rbc-today {
    background-color: ${({ theme }) => theme.palette.primary.background};
  }
  & .rbc-header {
    padding: 5px 3px;
  }
  & .rbc-time-content {
    overflow-y: hidden;
    border-top: 2px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-time-view {
    border-radius: 3px;
    border-top: 1px solid ${({ theme }) => theme.palette.menu.icon};
    border-left: 1px solid ${({ theme }) => theme.palette.menu.icon};
    border-right: 1px solid ${({ theme }) => theme.palette.menu.icon};
    border-bottom: none;
  }
  & .rbc-month-view {
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.palette.menu.icon};

    & .rbc-header {
      border-bottom: 1px solid ${({ theme }) => theme.palette.menu.icon};
    }
  }
  & .rbc-time-header-content {
    border-left: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-timeslot-group {
    border-bottom: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-time-content > * + * > * {
    border-left: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-header + .rbc-header {
    border-left: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  & .rbc-date-cell > a,
  & .rbc-header > a {
    pointer-events: none;
  }
  & .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
  & .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${({ theme }) => theme.palette.menu.icon};
  }
`

export const DateWrapper = styled(Grid)`
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 6px 10px;
`

export const DeleteBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.background};
  color: ${({ theme }) => theme.palette.error.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    color: white;
  }
`
