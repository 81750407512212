import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Card, CardActionArea, Grid, Typography, Container } from '@material-ui/core'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'

import {
  StyledCardMedia,
  StyledCardContent,
  StyledChip,
  PlusIconWrapper,
  InsertWrapper,
  StyledCardActionArea,
} from './styles'

const ServicePacksList = () => {
  const history = useHistory()
  const [servicePacks, setServicePacks] = useState(null)

  useEffect(() => {
    api.get('/service-packs/list').then(({ data }) => {
      setServicePacks(data)
    })
  }, [])

  if (!servicePacks) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card variant="outlined" style={{ minHeight: 120, height: '100%' }}>
            <CardActionArea
              onClick={() => history.push(`/service-packs/new`)}
              style={{ height: '100%', display: 'flex' }}
            >
              <InsertWrapper>
                <PlusIconWrapper container justify="center" alignItems="center">
                  <Plus size={35} />
                </PlusIconWrapper>
              </InsertWrapper>
              <StyledCardContent>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Novo Pacote de Serviços
                </Typography>
              </StyledCardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {servicePacks.map((pack) => (
          <Grid key={pack.id} item xs={12} md={6}>
            <Card variant="outlined">
              <StyledCardActionArea
                onClick={() => history.push(`/service-packs/${pack.id}/info`)}
              >
                <StyledCardMedia image={pack.image_url} title={pack.name} />
                <StyledCardContent>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {pack.name}
                  </Typography>
                  <Grid container alignItems="center" justify="space-between">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h6"
                        color="secondary"
                        style={{ marginRight: 5 }}
                      >
                        <b>{pack.services_count}</b>
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        serviços
                      </Typography>
                    </div>
                    <StyledChip
                      status={pack.status.toString()}
                      label={pack.status ? 'Ativo' : 'Inativo'}
                    />
                  </Grid>
                </StyledCardContent>
              </StyledCardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default ServicePacksList
