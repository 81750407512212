import { Grid, TextField } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import parseApptStatusBgColor from 'helpers/parse-appt-status-bg-color'
import parseApptStatusColor from 'helpers/parse-appt-status-color'
import styled from 'styled-components'

export const StatusGrid = styled(Grid)`
  flex: 1;
  height: 60px;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => fade(theme.palette.background.paper, 0.7)};
  border: ${({ theme }) => `1.5px solid ${theme.palette.background.paper}`};

  ${({ theme, status, active }) =>
    active === 'true' &&
    `
    background-color: ${parseApptStatusBgColor(status, theme)};
    border-color: ${parseApptStatusColor(status, theme)};
  `}
`

export const MethodGrid = styled(Grid)`
  height: 60px;
  display: flex;
  padding: 15px;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  border: ${({ theme }) => `1.5px solid ${theme.palette.background.paper}`};

  ${({ theme, active }) =>
    active === 'true' &&
    `
    border-color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.background};
  `}

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const LogoIcon = styled.img`
  width: 30px;
`

export const StyledTextField = styled(TextField)`
  margin: 10px 0 15px 0;

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.background.input};
  }
`
