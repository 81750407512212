import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
} from '@material-ui/core'
import styled from 'styled-components'

export const StyledCardMedia = styled(CardMedia)`
  min-width: 120px;
  height: 120px;
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px !important;
`

export const StyledCardActionArea = styled(CardActionArea)`
  display: flex;
  height: 100%;
  align-items: normal;
`

export const StyledChip = styled(Chip)`
  ${({ status, theme }) => `
    background-color: ${
      status === 'true'
        ? theme.palette.success.background
        : theme.palette.warning.background
    };
    color: ${status === 'true' ? theme.palette.success.main : theme.palette.warning.main};
  `}
  .MuiChip-label {
    font-weight: 500;
    padding: 0 24px;
  }
`

export const PlusIconWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 60px;
  height: 60px;
  border-radius: 50%;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const InsertWrapper = styled.div`
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DeleteBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.background};
  color: ${({ theme }) => theme.palette.error.main};
  height: 45px;
  padding: 10px 22px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    color: white;
  }
`
