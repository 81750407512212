import React, { useEffect, useState } from 'react'

import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { api } from 'api'
import moment from 'moment'

import NextAppointment from './NextAppointment'
import { StyledCard, DayName, NextApptsGrid, NoApptsImg } from './styles'

const AppointmentList = () => {
  const [currentDay, setCurrentDay] = useState(moment())
  const [loading, setLoading] = useState(true)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get(`/appointments/${currentDay.format('YYYY-MM-DD')}`).then(({ data }) => {
      setAppointments(data)
      setLoading(false)
    })
  }, [currentDay])

  const nextDay = () => {
    const newCurrent = currentDay.clone().add(1, 'days')
    setCurrentDay(newCurrent)
  }

  const previousDay = () => {
    const newCurrent = currentDay.clone().subtract(1, 'days')
    setCurrentDay(newCurrent)
  }

  return (
    <>
      <StyledCard>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Typography variant="h4" color="primary" style={{ marginRight: 10 }}>
                {currentDay.format('DD')}
              </Typography>
              <div style={{ textTransform: 'capitalize' }}>
                <DayName variant="body2" color="secondary">
                  {currentDay.format('dddd')}
                </DayName>
                <Typography variant="subtitle2" color="secondary">
                  {currentDay.format('MMMM')}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={previousDay} label="previous-day">
              <ArrowLeft size={30} />
            </IconButton>
            <IconButton onClick={nextDay} label="next-day">
              <ArrowRight size={30} />
            </IconButton>
          </Grid>
        </Grid>
      </StyledCard>
      {loading && (
        <div style={{ height: 220 }}>
          <CircularProgress style={{ marginTop: 20 }} />
        </div>
      )}
      {!loading && (
        <NextApptsGrid>
          {appointments.map((appointment) => (
            <NextAppointment key={appointment.id} appointment={appointment} />
          ))}
          {appointments.length === 0 && (
            <>
              <NoApptsImg />
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{ fontWeight: 500 }}
              >
                Sem marcações para este dia
              </Typography>
            </>
          )}
        </NextApptsGrid>
      )}
    </>
  )
}

export default AppointmentList
